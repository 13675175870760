import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  SxProps,
  styled,
} from '@mui/material';
import { ArrowUpward, ArrowDownward, ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { CircularLoadingSkeleton } from '~/components/LoadingSkeleton';

interface TickerWidgetProps {
  loading: boolean;
  title: string;
  gainCount?: number;
  attritionCount?: number;
  path?: string;
  sx?: SxProps;
}

interface LinkWrapperProps {
  children: React.ReactNode;
  path?: string;
  sx?: SxProps;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover .arrow-forward-icon {
    transform: translateX(8px);
  }
`;

const LinkWrapper: React.FC<LinkWrapperProps> = ({ children, path, sx }) => (
  <Box sx={sx}>{path ? <StyledLink to={path}>{children}</StyledLink> : <>{children}</>}</Box>
);

const FontStyle = { fontSize: '2rem', fontWeight: 700 };
const gainStyle = { color: 'success.main' };
const attritionStyle = { color: 'error.main' };

export const TickerWidget: React.FC<TickerWidgetProps> = ({
  loading,
  gainCount = 0,
  attritionCount = 0,
  title,
  path,
  sx,
}) => {
  return (
    <Card
      sx={{
        gridColumnEnd: {
          sm: 'span 6',
          md: 'span 2',
        },
        placeSelf: 'stretch',
        ...sx,
      }}
    >
      <LinkWrapper path={path}>
        <Box display='flex' justifyContent='space-between' width='100%' p={3}>
          <Box display='flex' flexDirection='column' width='100%'>
            <CardHeader title={title} sx={{ p: 0, m: 0 }} component='h2' />
            <CardContent
              sx={{
                p: '0 !important',
                m: 0,
                display: 'flex',
                justifyContent: loading ? 'center' : 'space-between',
              }}
            >
              <Box>
                {loading && <CircularLoadingSkeleton sx={{ height: 48 }} />}
                {!loading && (
                  <Box
                    alignItems='flex-end'
                    justifyContent='space-between'
                    width='100%'
                    display='flex'
                  >
                    <Box display='flex' alignItems='center' gap={2}>
                      <Box>
                        <ArrowUpward sx={gainStyle} />
                        <Typography sx={{ ...gainStyle, ...FontStyle }} component='span'>
                          {gainCount}
                        </Typography>
                      </Box>
                      <Box>
                        <ArrowDownward sx={attritionStyle} />
                        <Typography sx={{ ...attritionStyle, ...FontStyle }} component='span'>
                          {attritionCount}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Box>
          {path && (
            <Box display='flex' alignItems='center'>
              <ArrowForward
                className='arrow-forward-icon'
                sx={{
                  transition: 'transform 0.2s ease-in-out',
                  color: 'primary.main',
                  fontSize: 24,
                }}
              />
            </Box>
          )}
        </Box>
      </LinkWrapper>
    </Card>
  );
};
