import React, { useEffect, useMemo, useRef } from 'react';
import { createGraph } from './dygraphs';
import Dygraph, { dygraphs } from 'dygraphs';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Help } from '~/components/Help';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { axisLabelFormatter } from './chart-utils';
import { useExportDomElementToPng } from '~/common/useExportDomToPng';

type Axis = '' | 'y1' | 'y2' | undefined;
type ValueRange = [number | null, number | null];

export const CustomLegend: React.FC<{
  data: {
    label: string;
    color: string;
  }[];
  direction?: 'row' | 'column';
}> = ({ data, direction = 'row' }) => {
  return (
    <Stack direction={direction} gap={2}>
      {data.map(({ color, label }) => (
        <Stack direction='row' gap={1} key={label} alignItems='center'>
          <HorizontalRuleIcon sx={{ color }} />
          <Typography variant='body2'>{label}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export const ScatterDygraphChartCard: React.FC<{
  csv?: string | null;
  yAxisScale?: 'logarithmic' | 'linear';
  exportGraph?: boolean;
  chartHeight?: number;
  graphOptions?: dygraphs.Options;
}> = ({ csv, yAxisScale = 'linear', exportGraph = true, chartHeight = 400, graphOptions }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const scatterGraphRef = useRef<HTMLDivElement>(null);
  const exportDomContainerRef = useRef<HTMLDivElement>(null);

  const { exportEnabled, handleExport } = useExportDomElementToPng(
    exportDomContainerRef,
    'customer-asset-ir-and-voltage-graph.png'
  );

  const baseOptions = useMemo(
    () => ({
      animatedZooms: false,
      series: {
        Resistance: {
          axis: 'y' as Axis,
        },
        Voltage: {
          axis: 'y2' as Axis,
        },
      },
      axes: {
        y: {
          logscale: yAxisScale === 'logarithmic',
          valueRange: [1000, 1000000000] as ValueRange,
          color: theme.palette.primary.main,
        },
        y2: {
          logscale: false,
          valueRange: [0, 1000] as ValueRange,
          color: theme.palette.secondary.main,
        },
        x: {
          axisLabelFormatter,
        },
      },
    }),
    [yAxisScale, theme]
  );

  useEffect(() => {
    const dataLabels = [t('charts::date'), t('charts::resistance'), t('charts::voltage')];

    if (scatterGraphRef.current && csv) {
      const scatterGraphElement = scatterGraphRef.current;
      const localData = {
        assetName: 'data',
        data: csv,
      };
      const irGraph = createGraph(localData, scatterGraphElement, dataLabels, {
        ...baseOptions,
        ...graphOptions,
      });

      const ondblClick = () => {
        resetGraphZoomWithOptions(irGraph, {
          ...baseOptions,
          ...graphOptions,
        });
      };
      scatterGraphElement.addEventListener('dblclick', ondblClick);
      return () => {
        if (scatterGraphElement) {
          scatterGraphElement.removeEventListener('dblclick', ondblClick);
        }
      };
    }
  }, [baseOptions, csv, t, theme, yAxisScale, graphOptions]);

  return (
    <>
      <Box
        sx={{ height: chartHeight, position: 'relative', width: '100%', p: 2 }}
        ref={exportDomContainerRef}
      >
        <div
          data-testid='ir-graph'
          ref={scatterGraphRef}
          style={{
            position: 'absolute',
            inset: '0%',
            width: 'calc(100%-40px)',
            top: 16,
            bottom: 16,
            margin: '0 20px',
          }}
        />
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Help tooltipText={t('graph-helper-text')} color='info' />
        {/* custom legend */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.text.primary,
          }}
        >
          <CustomLegend
            data={[
              {
                label: t('legend/resistance'),
                color: theme.palette.primary.main,
              },
              {
                label: t('legend/voltage'),
                color: theme.palette.secondary.main,
              },
            ]}
          />
        </Box>
        <Stack direction='row' gap={2}>
          {exportGraph && (
            <Button
              variant='contained'
              size='small'
              color={'neutral'}
              onClick={handleExport}
              disabled={!exportEnabled}
            >
              {t('export')}
            </Button>
          )}
        </Stack>
      </Box>
    </>
  );
};

export function resetGraphZoomWithOptions(graph: Dygraph, graphOptions: dygraphs.Options) {
  graph.resetZoom();
  graph.updateOptions(graphOptions);
}
