import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import ICU from 'i18next-icu';
import resources from '!!../../plugins/resource-loader!../locales';
import { env } from '~/config/environmentVariables';

const instance = i18n.createInstance();

instance
  .use(
    new ICU({
      formats: {},
    })
  )
  .use(initReactI18next)
  .init({
    lng: 'en-gb',
    supportedLngs: ['en-gb'],
    fallbackLng: [],
    load: 'currentOnly',
    debug: false,
    nsSeparator: '::',
    keySeparator: '/',
    resources,
    ns: ['viper'],
    defaultNS: 'viper',
    initImmediate: false,
    lowerCaseLng: true,
    i18nFormat: {
      memoize: env.NODE_ENV !== 'development',
    },
  });
