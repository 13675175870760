import React from 'react';

import { Card as MuiCard, CardActions, CardContent, SxProps } from '@mui/material';

export interface CardProps {
  children?: React.ReactNode;
  footerContent?: React.ReactNode;
  sx?: SxProps;
}
export const Card: React.FC<CardProps> = ({ children, footerContent, sx }) => {
  return (
    <MuiCard data-testid='card'>
      <CardContent
        sx={{
          gap: 2,
          p: 4,
          ...sx,
        }}
      >
        {children}
      </CardContent>
      {footerContent && (
        <CardActions
          data-testid='card-footer'
          sx={{
            gap: 1,
            py: 2,
            px: 4,
          }}
        >
          {footerContent}
        </CardActions>
      )}
    </MuiCard>
  );
};
