import { sortBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RawData,
  mapRawDataToPieData,
  mapRawDataToBarData,
  mapRawDataToTableData,
  mapColorsToKeys,
} from '../../dashboardTools';
import { ChartType } from '../WidgetCard';
import { defaultColors } from '../../charts/defaults';
import { DefaultRawDatum } from '@nivo/pie';
import { BarDatum } from '@nivo/bar';

export interface UseWidgetCardProps {
  defaultChartType?: ChartType;
  data?: Record<string, unknown>;
  dataTypes?: [string, string];
  indexBy?: string;
  dataKey?: string;
  pieColors?: string[];
  barColors?: string[];
  limit?: number;
}

export function useWidgetCard({
  defaultChartType,
  data,
  dataTypes,
  indexBy,
  dataKey,
  limit = 100,
}: UseWidgetCardProps) {
  const { t } = useTranslation('dashboard');

  const [chartType, setChartType] = React.useState<ChartType>(
    defaultChartType ?? ChartType.PIE
  );
  const [tabId, setTabId] = React.useState(0);

  const handleTabChange = (_e: React.SyntheticEvent, value: number) => {
    setTabId(value);
  };
  const handleToggleChange = (type: ChartType) => {
    setChartType(type);
  };

  const presentationalData = useCallback(
    // picks the right part from the "reports" data object by the chosen dataType
    (tabId: number) => (data && dataTypes ? (data[dataTypes[tabId]] as RawData[]) : []),
    [data, dataTypes]
  );

  const pieData = useMemo(
    () => mapRawDataToPieData(presentationalData(tabId), indexBy, dataKey, limit) ?? [],
    [presentationalData, tabId, indexBy, dataKey, limit]
  );
  const barData = useMemo(
    () =>
      sortBy(
        mapRawDataToBarData(presentationalData(tabId), indexBy, dataKey, limit),
        dataKey ?? ''
      ),
    [presentationalData, tabId, indexBy, dataKey, limit]
  );
  const tableData = useMemo(
    () =>
      mapRawDataToTableData(
        t,
        indexBy,
        dataKey,
        sortBy(presentationalData(tabId), dataKey ?? '').reverse()
      ) ?? [],
    [dataKey, indexBy, presentationalData, tabId, t]
  );

  const { pieColors, barColors } = useChartColors({
    indexBy,
    dataKey,
    presentationalData,
    pieData,
    barData,
  });

  return {
    chartType,
    handleTabChange,
    handleToggleChange,
    pieData,
    barData,
    tableData,
    tabId,
    pieColors,
    barColors,
  };
}

export interface UseChartColorsProps {
  indexBy?: string;
  dataKey?: string;
  presentationalData: (tabId: number) => RawData[];
  pieData: DefaultRawDatum[];
  barData: BarDatum[];
}

export function useChartColors({
  indexBy,
  dataKey,
  presentationalData,
  pieData,
  barData,
}: UseChartColorsProps) {
  // find all the possible data keys in the larger data set
  // which is the active tab's data (1) using the pieData mapper (basically because it's simpler)
  // the data length should be the same for all chart variants
  const allDataKeys = useMemo(
    () =>
      mapRawDataToPieData(presentationalData(1), indexBy, dataKey)?.map((datum) => datum.id) ??
      [],
    [presentationalData, indexBy, dataKey]
  );

  // map all colours dynamically to all the possible data keys
  const mappedColors = mapColorsToKeys(defaultColors, allDataKeys as string[]);

  // pick pie and bar chart colors from the mapped colors and return an array of colors
  // that matches the order of the data respectively
  const pieColors = useMemo(
    () => pieData.map((datum) => mappedColors[datum.id]),
    [mappedColors, pieData]
  );
  const barColors = useMemo(
    () => barData.map((datum) => mappedColors[datum[indexBy ?? '']]),
    [barData, indexBy, mappedColors]
  );

  return { pieColors, barColors };
}
