import { get } from 'lodash';

type ValueMapper = (value: unknown) => number;

export const sortArrayByKey = <T>(
  array: T[],
  keyString: string,
  ascending: boolean,
  valueMapper: ValueMapper
): T[] => {
  // Copy array as typescript complains about array.toSorted(..)
  return [...array].sort((a, b) => {
    const aValue = valueMapper(get(a, keyString));
    const bValue = valueMapper(get(b, keyString));
    if (ascending) {
      return aValue - bValue;
    }
    return bValue - aValue;
  });
};
