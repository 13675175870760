import React from 'react';
import { Schema, useFormApi } from '@data-driven-forms/react-form-renderer';
import { Button, ButtonProps, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Card } from '~/components/Card';
import { DataList } from '~/components/DataList';
import { LoadingSkeleton } from './LoadingSkeleton';
import { EditPane } from './Form/EditPane';
import { Table } from './Table/Table';
import { TFunction } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { FormSpyWithBlocker } from './Form/FormSpyWithBlocker';
import { transientOptions } from '~/themes/utils';

interface CustomButtonProps extends ButtonProps {
  to: string;
  label: string;
  onCustomButtonClick: () => void;
}

const SectionGroup = styled(Stack, transientOptions)<{
  $highlighted?: string;
}>`
  ${({ theme, $highlighted }) => `
    background: ${$highlighted ? $highlighted : theme.palette.primary.contrastText};
    box-shadow: 0 0 0 10px ${$highlighted ?? 'transparent'};
    border-radius: 5px;
  `}
`;

export const FooterActions: React.FC<{
  onSaveClicked?: () => void;
  onCancelClicked?: () => void;
  onEditClicked?: () => void;
  editingThisSection: boolean;
  editingAnotherSection: boolean;
  saving: boolean;
  saveButtonLabel?: string;
  disableSave?: boolean;
  customButton?: CustomButtonProps;
}> = ({
  onSaveClicked,
  onCancelClicked,
  onEditClicked,
  editingThisSection,
  editingAnotherSection,
  saving,
  saveButtonLabel,
  disableSave,
  customButton,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      {customButton && (
        <Button
          key='custom-button'
          onClick={() => {
            if (customButton.to) {
              navigate(customButton.to);
            }
            customButton.onCustomButtonClick?.();
          }}
          {...customButton}
        >
          {customButton.label}
        </Button>
      )}
      {editingThisSection ? (
        <>
          <Button
            // add keys to these buttons, otherwise react tries to reuse the cancel button
            // for the edit button and vice versa, and you get an odd flash of colour as
            // the component is updated
            key='cancel'
            variant='contained'
            color='neutral'
            onClick={onCancelClicked}
            disabled={saving}
          >
            {t('viper::cancel')}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={onSaveClicked}
            disabled={saving || disableSave}
          >
            {saveButtonLabel || t('viper::save')}
          </Button>
        </>
      ) : (
        <>
          <Button
            key='edit'
            variant='contained'
            color='primary'
            onClick={onEditClicked}
            disabled={editingAnotherSection}
          >
            {t('viper::edit')}
          </Button>
        </>
      )}
    </>
  );
};

const Pane: React.FC<{
  onSaveClicked?: () => void;
  onCancelClicked?: () => void;
  onEditClicked?: () => void;
  title?: React.ReactNode;
  editingThisSection: boolean;
  editingAnotherSection: boolean;
  children: React.ReactNode;
  saving: boolean;
  saveButtonLabel?: string;
  disableSave?: boolean;
  customButton?: CustomButtonProps;
}> = ({
  onSaveClicked,
  onCancelClicked,
  onEditClicked,
  editingThisSection,
  editingAnotherSection,
  title,
  children,
  saving,
  saveButtonLabel,
  disableSave,
  customButton,
}) => {
  return (
    <Card
      aria-label={title}
      footerContent={
        <FooterActions
          editingThisSection={editingThisSection}
          editingAnotherSection={editingAnotherSection}
          onCancelClicked={onCancelClicked}
          onEditClicked={onEditClicked}
          onSaveClicked={onSaveClicked}
          saving={saving}
          saveButtonLabel={saveButtonLabel}
          disableSave={disableSave}
          customButton={customButton}
        />
      }
    >
      <Typography variant='h3' component='h3' sx={{ ml: 0.75, mb: 1.25 }}>
        {title}
      </Typography>
      {children}
    </Card>
  );
};

const EditPaneContainer = ({
  title,
  children,
  saving,
  saveButtonLabel,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  saving: boolean;
  saveButtonLabel?: string;
}) => {
  const { submit, onCancel, getState } = useFormApi();

  return (
    <FormSpyWithBlocker onCancel={({ values }) => onCancel?.(values)} disableBlocker={saving}>
      {({ handleCancel }) => {
        return (
          <Pane
            editingThisSection={true}
            editingAnotherSection={false}
            onCancelClicked={handleCancel}
            onSaveClicked={submit}
            title={title}
            saving={saving}
            saveButtonLabel={saveButtonLabel}
            disableSave={getState().invalid}
          >
            {children}
          </Pane>
        );
      }}
    </FormSpyWithBlocker>
  );
};

export const DataCardStack: React.FC<{
  data: Schema | null | undefined;
  loading: boolean;
  saving?: boolean;
  translationKey?: string;
  setEditing?: (section: string) => void;
  editing?: string;
  validate?: (values: Record<string, unknown>) => Record<string, string> | undefined;
  onSave?: (section: Schema & { name: string }, values: Record<string, unknown>) => void;
  onCancel?: () => void;
  saveButtonLabel?: string;
}> = ({
  data,
  translationKey,
  loading,
  saving,
  setEditing,
  editing,
  validate,
  onSave,
  onCancel,
  saveButtonLabel,
}) => {
  const { t } = useTranslation(translationKey);

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (!data) {
    return <p>no data</p>;
  }

  const sections = data.fields[0].fields;
  return (
    <Stack spacing={3}>
      {sections.map(
        (
          section: Schema & {
            name: string;
            component: 'group' | 'panel';
            highlighted?: boolean;
            when?: (data?: Schema | null | undefined) => boolean;
            highlightColor?: string;
          }
        ) => {
          if (section.when && !section.when()) {
            return;
          }
          if (section.component === 'group') {
            return (
              <SectionGroup
                key={section.name}
                aria-label={section.name}
                $highlighted={section.highlightColor}
                spacing={2}
                data-testid='panel-group'
              >
                {section.fields.map((pane) => {
                  if (pane.component === 'panel') {
                    if (pane.when && !pane.when()) {
                      return;
                    }
                    return renderEditOrViewPanes(
                      editing,
                      pane as Schema & {
                        name: string;
                        title?: React.ReactNode;
                        component: 'group' | 'panel';
                      },
                      saving,
                      validate,
                      onSave,
                      onCancel,
                      setEditing,
                      t,
                      saveButtonLabel,
                      pane.customButton
                    );
                  }
                  if (pane.component === 'list') {
                    if (pane.when && !pane.when()) {
                      return;
                    }
                    let content;
                    if (pane.data.length === 0) {
                      if (!pane.emptyText) {
                        return;
                      }
                      content = <Typography sx={{ ml: 0.75 }}>{pane.emptyText}</Typography>;
                    } else {
                      content = (
                        <Table
                          key={pane.name}
                          data={pane.data}
                          nestedData={pane.nestedData}
                          translationKey={translationKey}
                          omittedKeys={pane.omittedKeys}
                        />
                      );
                    }
                    if (pane.showTitle) {
                      return (
                        <Card key={pane.name}>
                          <Typography variant='h3' component='h3' sx={{ ml: 0.75, mb: 1.25 }}>
                            {pane.title}
                          </Typography>
                          {content}
                        </Card>
                      );
                    } else {
                      return content;
                    }
                  }
                  if (pane.component === 'custom') {
                    if (pane.when && !pane.when()) {
                      return;
                    }
                    return (
                      <React.Fragment key={pane.name}>{pane.customComponent}</React.Fragment>
                    );
                  }
                })}
              </SectionGroup>
            );
          }
          return renderEditOrViewPanes(
            editing,
            section,
            saving,
            validate,
            onSave,
            onCancel,
            setEditing,
            t,
            saveButtonLabel
          );
        }
      )}
    </Stack>
  );
};

export const renderEditOrViewPanes = (
  editing: string | undefined,
  section: Schema & {
    name: string;
    component: 'group' | 'panel';
  },
  saving: boolean | undefined,
  validate:
    | ((values: Record<string, unknown>) => Record<string, string> | undefined)
    | undefined,
  onSave:
    | ((section: Schema & { name: string }, values: Record<string, unknown>) => void)
    | undefined,
  onCancel: (() => void) | undefined,
  setEditing: ((section: string) => void) | undefined,
  t: TFunction,
  saveButtonLabel: string | undefined,
  customButton?: CustomButtonProps
) => {
  return editing === section.name ? (
    <EditPane
      data-testid={section.name}
      key={section.name}
      disableFields={saving}
      fields={section.fields}
      validate={validate}
      onSubmit={(values) => {
        onSave?.(section, values);
      }}
      onCancel={onCancel}
      container={({ children }) => (
        // The submit button needs to be able to access the form api, so it needs
        // to be rendered inside the form. But we're outside the form here. So we
        // pass the container into the EditPane, so that it can get rendered as a
        // wrapper around the form template, but inside the form renderer.
        <EditPaneContainer
          title={typeof section.title === 'string' ? t(section.title) : section.title}
          saving={!!saving}
          saveButtonLabel={saveButtonLabel}
        >
          {children}
        </EditPaneContainer>
      )}
    />
  ) : (
    <Pane
      data-testid={section.name}
      key={section.name}
      editingThisSection={false}
      editingAnotherSection={!!editing}
      onEditClicked={() => setEditing?.(section.name)}
      title={typeof section.title === 'string' ? t(section.title) : section.title}
      saving={!!saving}
      saveButtonLabel={saveButtonLabel}
      customButton={customButton}
    >
      <DataList fields={section.fields} />
    </Pane>
  );
};
