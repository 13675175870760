import React from 'react';

import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';
import { Box, Stack } from '@mui/material';
import { FormLabel } from './FormLabel';

export const LabelledTextField: React.FC<
  TextFieldProps & {
    readOnly?: boolean;
    suffix?: React.ReactNode;
    component?: string;
    isRequired?: boolean;
    isRequiredForReporting?: boolean;
  }
> = (props) => {
  const {
    size,
    name,
    readOnly,
    inputProps,
    suffix,
    prefix,
    label,
    isRequired,
    sx,
    // `component` isn't used in this component, but we don't want it to
    // be included in `rest`
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    component,
    isRequiredForReporting = false,
    ...rest
  } = props;

  return (
    <Box sx={sx}>
      <Stack direction='row' justifyContent={'flex-start'}>
        <FormLabel
          sx={{ color: (theme) => (props.error ? theme.palette.error.main : 'inherit') }}
          htmlFor={name}
          isRequiredForReporting={isRequiredForReporting}
        >
          {props.required && (
            <sup style={{ position: 'absolute', top: -3, left: -6 }}>*&nbsp;</sup>
          )}
          {label}
        </FormLabel>
      </Stack>
      <MuiTextField
        id={name}
        fullWidth
        size={size ?? 'small'}
        InputProps={{
          endAdornment: suffix && <InputAdornment position='end'>{suffix}</InputAdornment>,
          startAdornment: prefix && <InputAdornment position='start'>{prefix}</InputAdornment>,
        }}
        hiddenLabel
        InputLabelProps={{ shrink: false }}
        label={null}
        inputProps={{
          readOnly,
          ...inputProps,
        }}
        FormHelperTextProps={{
          sx: {
            fontSize: 14,
            mx: 0,
          },
        }}
        required={isRequired}
        {...rest}
      />
    </Box>
  );
};
