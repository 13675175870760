import { DateRange } from '~/common/types';
import { useLicenceInsightsRows } from '~/pages/LicenceInsights/useLicenceInsightsRows';
import { useLicenceInsightsColumns } from '~/pages/LicenceInsights/useLicenceInsightsColumns';
import { ToolbarFilters } from '~/pages/LicenceInsights/types';
import { useFilterRows } from '~/pages/LicenceInsights/useFilterRows';

export const useLicenceInsightsTable = (
  evaluationPeriod: DateRange,
  filters: ToolbarFilters
) => {
  const { rows, loading } = useLicenceInsightsRows();
  const { columns } = useLicenceInsightsColumns();
  const { filteredRows } = useFilterRows({ rows, evaluationPeriod, filters });

  return {
    rows: filteredRows,
    columns,
    loading,
  };
};
