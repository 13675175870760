import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditEntityDialog } from '../CreateEditEntityDialog/CreateEditEntityDialog';
import { useGetDisableVLifeConfigOptions, useUpdateChannel } from '~/queries-asset/channel';
import { getVLifeConfigSchemaEditSchema } from '~/pages/schemas/edit/getVLifeConfig/getVLifeConfigEditSchema';
import { GetChannelQuery } from '~/__generated-asset__/graphql';

export const DisableVLifeConfigDialog: React.FC<{
  editId: string | undefined;
  onClose: () => void;
  channel: NonNullable<GetChannelQuery['channel']>;
  validate?: (values: Record<string, unknown>) => Record<string, string> | undefined;
}> = ({ editId, channel, onClose, validate }) => {
  const { t } = useTranslation('disable-v-life-config');

  return (
    <CreateEditEntityDialog
      open={!!editId}
      onClose={onClose}
      title={t('disable-v-life')}
      errorMessage={t('error-updating')}
      successMessage={(vLifeConfig) => t('disabled-successfully', { vLifeConfig })}
      editWarning={t('delete-future-v-life-configs')}
      useMutate={useUpdateChannel}
      useMutateOptions={{ id: channel.id }}
      useGetOptions={useGetDisableVLifeConfigOptions}
      entityId={editId}
      validate={validate}
      getSchema={({ t, options }) =>
        getVLifeConfigSchemaEditSchema({
          t,
          options,
        }).fields
      }
      handleMutate={async (update, values) => {
        await update({
          variables: {
            channelInput: {
              id: channel.id,
              mostRecentVLifeConfig: {
                deactivationReasonId: !values.__isSuspended
                  ? (values.deactivationReasonId as string)
                  : null,
                resumptionDate: values.__isSuspended
                  ? (values.resumptionDate as string)
                  : null,
                disableDate: values.disableDate as string,
                disabled: true,
                notes: values.notes
                  ? (channel.mostRecentVLifeConfig?.notes
                      ? channel.mostRecentVLifeConfig.notes + '\n\n'
                      : '') +
                    'DEACTIVATION NOTES: ' +
                    (values.notes as string)
                  : channel.mostRecentVLifeConfig?.notes,
              },
            },
          },
        });
        return '-';
      }}
    />
  );
};
