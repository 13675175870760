import {
  NegatableDateFilters,
  TypedGridFilterItem,
} from '~/components/DataGrid/filters/types';
import {
  GetApplyFilterFnLegacy,
  GridCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { formatDateYYYYMMDD } from '~/components/DataGrid/filters/utils';

export const getDateGreaterThanEqualsFilter: GetApplyFilterFnLegacy = ({
  value: filterValues,
}: TypedGridFilterItem<NegatableDateFilters>) => {
  if (filterValues === undefined) {
    return null;
  }

  const { comparatorDate, isNegated } = filterValues;

  if (comparatorDate === undefined) {
    return null;
  }

  return ({ value }: GridCellParams<GridValidRowModel, Date | string>) => {
    const valueDate = new Date(formatDateYYYYMMDD(value));
    const filterDate = new Date(formatDateYYYYMMDD(comparatorDate));
    const comparison = valueDate.getTime() >= filterDate.getTime();
    return isNegated ? !comparison : comparison;
  };
};
