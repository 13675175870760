import { VLifeTimelinePeriod } from '~/__generated-asset__/graphql';

export const filterStatusWindowsOutsideFilterDateRange = (
  statusWindows: VLifeTimelinePeriod[],
  startDate: Date,
  endDate: Date
) => {
  return statusWindows.filter((config) => {
    const { startDate: windowStartDate, endDate: windowEndDate } = config;
    // Licence falls outside of period, filter out

    const parsedStartDate = new Date(windowStartDate ?? '');
    const parsedEndDate = new Date(windowEndDate ?? '');

    return !(
      (windowEndDate && parsedEndDate < startDate) ||
      (windowStartDate && parsedStartDate > endDate)
    );
  });
};
