import React, { Fragment, memo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Accept, FileError, useDropzone } from 'react-dropzone';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { UploadIcon } from '~/components/upload/UploadIcon';

export interface UploadFieldProps {
  onUpload: (files: File[], formValues?: Record<string, unknown> | undefined) => void;
  onSecondaryAction?: () => void;
  accept?: Accept;
  extension?: string;
  validationError?: FileError | FileError[] | null;
  uploadText?: string;
  secondaryActionText?: string;
  secondaryActionDisabled?: boolean;
}

const _UploadField: React.FC<UploadFieldProps> = ({
  accept,
  extension = '.*',
  validationError = { message: '', code: '' },
  onUpload,
  onSecondaryAction,
  uploadText,
  secondaryActionText,
  secondaryActionDisabled,
}) => {
  const { t } = useTranslation('upload-field');
  const [fileList, setFileList] = useState<File[]>([]);
  const theme = useTheme();

  useEffect(() => {
    setFileList([]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    validator: (file) => {
      if (!file.name) {
        console.log('file name unknown');
        return null;
      }
      if (!file.name?.endsWith(extension)) {
        return validationError;
      }

      return null;
    },
    onDropAccepted(files) {
      const newFileList = fileList.slice();
      files.forEach((file) => {
        if (!fileList.find((existingFile) => existingFile.name === file.name)) {
          newFileList.push(file);
        }
      });
      setFileList(newFileList);
    },
  });

  return (
    <section>
      <Box
        {...getRootProps()}
        sx={{
          margin: '2px',
          backgroundImage: isDragActive
            ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='1' y='1' width='calc(100%25 - 3px)' height='98%25' fill='${encodeURIComponent(
                theme.palette.highlight.light
              )}' rx='4' ry='4' stroke='${encodeURIComponent(
                theme.palette.highlight.dark
              )}' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
            : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='1' y='1' width='calc(100%25 - 3px)' height='98%25' fill='none' rx='4' ry='4' stroke='${encodeURIComponent(
                theme.palette.grey[500]
              )}' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
          padding: '1.5rem 9rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          marginBottom: '1rem',
        }}
      >
        <input {...getInputProps()} />
        <UploadIcon />
        <Typography fontSize='16px' padding={1}>
          <Trans
            i18nKey='click-to-upload'
            t={t}
            components={{
              u: <u />,
            }}
          />
        </Typography>
        <Typography fontSize='14px' color={theme.palette.grey[700]}>
          {t('file-spec')}
        </Typography>
      </Box>
      <List>
        {fileList.map((file, index) => (
          <Fragment key={file.name}>
            {index > 0 ? <Divider /> : null}
            <ListItem key={file.name} sx={{ minHeight: '2.5rem' }}>
              <ListItemAvatar>
                <UploadIcon />
              </ListItemAvatar>
              <ListItemText>
                <Typography fontSize='14px' color={theme.palette.grey[700]}>
                  {file.name}
                </Typography>
              </ListItemText>
              <IconButton
                aria-label={t('remove-file')}
                onClick={() => {
                  setFileList([...fileList.slice(0, index), ...fileList.slice(index + 1)]);
                }}
              >
                <Close
                  sx={{
                    color: (theme) => theme.palette.neutral.contrastText,
                    fontSize: 18,
                  }}
                />
              </IconButton>
            </ListItem>
          </Fragment>
        ))}
        <Stack
          direction='row'
          display='flex'
          justifyContent={onSecondaryAction ? 'space-between' : 'flex-end'}
        >
          {onSecondaryAction && (
            <Button
              onClick={() => onSecondaryAction?.()}
              variant='text'
              color='primary'
              disabled={secondaryActionDisabled}
            >
              {secondaryActionText ?? t('viper::remove')}
            </Button>
          )}
          <Button
            onClick={() => onUpload(fileList)}
            variant='contained'
            color='primary'
            disabled={fileList.length !== 1}
          >
            {uploadText ?? t('viper::upload')}
          </Button>
        </Stack>
      </List>
    </section>
  );
};

const UploadField = memo(_UploadField);

export { UploadField };
