import { get } from 'lodash';

export const getSelectOption = (item: {
  id: string | number | boolean;
  name: string;
}): { value: string | number | boolean; label: string } => ({
  value: item.id,
  label: item.name,
});

export const ipAddress =
  /^(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/;

export const macAddress = /^[a-fA-F0-9]{2}(:[a-fA-F0-9]{2}){5}$/;
/**
 * Function to return a combined string from the different field values
 * within an object. Use dot notation to target a nested field.
 * Based on lodash's get function.
 * Usage:
 * ```
 * getDerivedValue({
 *   nested1: {
 *     key: 'value2'
 *     nested2: { key: 'value3'}
 *   }
 *   key: 'value1'
 * },
 * ['key', 'nested1.key', 'nested1.nested2.key3'],
 * ' -- ')
 * ```
 *
 * @param {Record<string, unknown>} object
 * An object that has fields with string, numeric or boolean values
 *
 * @param {string[]} paths
 * A list of paths that define a key in the object hierarchy. Can be a nested path using the dot notation.
 *
 * @param {string} [separator = ' – ']
 * A string to separate the returned string
 *
 * @returns {string}
 * A combined string value
 *
 * @throws Will throw an error when a targeted value isn't string, number or boolean
 */

export function getDerivedValue(
  object: Record<string, unknown>,
  paths: string[],
  separator = ' – '
): string {
  const values: Array<string | number | boolean> = [];
  for (const path of paths) {
    const value = get(object, path);
    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      values.push(value);
    } else {
      throw new Error(
        'Paths to build derived values should refer to string, numeric or boolean values'
      );
    }
  }
  return values.join(separator);
}
