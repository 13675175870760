import React, { ReactNode } from 'react';
import { Box, BoxProps, Link } from '@mui/material';
import { WarningAmberRounded } from '@mui/icons-material';
import { Tooltip } from './Tooltip';
import { Link as RouterLink } from 'react-router-dom';

const TooltipTitle: React.FC<{ to?: string; title: React.ReactNode }> = ({ to, title }) => {
  return (
    <Box>
      <Box display='inline'>{title}</Box>
      {to && (
        <Link component={RouterLink} to={to}>
          {' ' + to}
        </Link>
      )}
    </Box>
  );
};

interface WarningWithTooltipProps extends Omit<BoxProps, 'title'> {
  title: ReactNode;
  to?: string;
  color?:
    | 'disabled'
    | 'action'
    | 'inherit'
    | 'warning'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success';
}

export const WarningWithTooltip = ({
  to,
  title,
  color = 'warning',
  ...rest
}: WarningWithTooltipProps) => {
  return (
    <Tooltip title={<TooltipTitle to={to} title={title} />}>
      <Box position='relative' component='span' {...rest}>
        <WarningAmberRounded
          color={color}
          sx={{ position: 'absolute', bottom: 0 }}
          fontSize='small'
        />
      </Box>
    </Tooltip>
  );
};
