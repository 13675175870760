import React, { useEffect } from 'react';
import { Dialog } from './Dialog';
import { unstable_useBlocker as useBlocker, useBeforeUnload } from 'react-router-dom';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export type LeaveDialogLabels = {
  title?: string;
  content?: string;
  confirmText?: string;
  cancelText?: string;
};

const blockerState = {
  enabled: atom({
    key: 'blocker-enabled',
    default: false,
  }),
  labels: atom<LeaveDialogLabels | undefined>({
    key: 'labels',
    default: undefined,
  }),
};

export function useSetNavigationBlockerEnabled(enabled: boolean) {
  const setStateEnabled = useSetRecoilState(blockerState.enabled);
  useEffect(() => {
    setStateEnabled(enabled);
    return () => {
      setStateEnabled(false);
    };
  }, [enabled, setStateEnabled]);

  useBeforeUnload((event) => {
    if (enabled) {
      setStateEnabled(false);
      event.preventDefault();
      return (event.returnValue = '');
    }
  });
}

export function useSetNavigationBlockerLabels(labels?: LeaveDialogLabels) {
  const setLabels = useSetRecoilState(blockerState.labels);
  useEffect(() => {
    setLabels(labels);
    return () => {
      setLabels(undefined);
    };
  }, [labels, setLabels]);
}

/**
 * Renders an AbandonChangesDialog which can be displayed when the user tries
 * to leave a page while they're editing data.
 *
 * This must be near the top of the component tree, otherwise the useBlocker()
 * method from React Router doesn't work properly. There may only be a single
 * instance of useBlocker in the site, and therefore, only a single instance of
 * this component.
 */
export const Blocker: React.FC = () => {
  const enabled = useRecoilValue(blockerState.enabled);
  const labels = useRecoilValue(blockerState.labels);

  const blocker = useBlocker(enabled);
  const { t } = useTranslation('abandon-changes-dialog');

  return (
    <AbandonChangesDialog
      labels={{
        title: labels?.title || t('title/leave-page'),
        content: labels?.content,
        confirmText: labels?.confirmText || t('button/leave-page'),
        cancelText: labels?.cancelText,
      }}
      open={blocker.state === 'blocked'}
      onClose={() => {
        blocker.reset?.();
      }}
      onConfirm={() => {
        blocker.proceed?.();
      }}
    />
  );
};

/**
 * Enables the blocker and sets the labels used to display the dialog.
 *
 * If you have multiple uses of this component at the same time, the
 * behaviour is undefined.
 */
export const EnableBlocker: React.FC<{ enabled: boolean; labels?: LeaveDialogLabels }> = ({
  enabled,
  labels,
}) => {
  useSetNavigationBlockerLabels(labels);
  useSetNavigationBlockerEnabled(enabled);
  return <></>;
};

export const AbandonChangesDialog: React.FC<{
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  labels?: LeaveDialogLabels;
}> = ({ onClose, onConfirm, open, labels }) => {
  const { t } = useTranslation('abandon-changes-dialog');
  return (
    <Dialog
      title={labels?.title || t('title/cancel-changes')}
      content={labels?.content || t('content/changes-will-be-lost')}
      confirmText={labels?.confirmText || t('button/abandon-changes')}
      cancelText={labels?.cancelText || t('button/continue-editing')}
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};
