import React from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { Card } from './Card';
import { Logo } from './Logo';

export const MainLoadingSkeleton: React.FC = () => {
  const { palette } = useTheme();
  return (
    <Box
      data-testid='loading'
      component='main'
      sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
        color: (theme) => theme.palette.grey[500],
        flexGrow: 1,
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
      }}
    >
      <Box sx={{ width: '50vw', mx: 'auto' }}>
        <Typography component='h1' variant='h1'>
          <Logo colors={[palette.neutral.main, palette.neutral.main]} />
        </Typography>
        <Typography variant='h2' sx={{ color: (theme) => theme.palette.neutral.main }}>
          <Skeleton
            animation='wave'
            variant='text'
            sx={{ background: (theme) => theme.palette.neutral.main }}
          />
        </Typography>
        <Typography variant='h3' sx={{ color: (theme) => theme.palette.neutral.main }}>
          <Skeleton
            animation='wave'
            variant='text'
            sx={{ background: (theme) => theme.palette.neutral.main }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export const LoadingSkeleton: React.FC = () => {
  return (
    <Stack spacing={3} data-testid='loading'>
      <Card>
        <Stack spacing={2}>
          <Typography variant='h2'>
            <Skeleton
              animation='wave'
              variant='text'
              sx={{ background: (theme) => theme.palette.neutral.main }}
            />
          </Typography>
          {'0'
            .repeat(10)
            .split('')
            .map((_, index) => (
              <Skeleton
                key={index}
                animation='wave'
                variant='text'
                sx={{ fontSize: '1rem', background: (theme) => theme.palette.neutral.main }}
              />
            ))}
        </Stack>
      </Card>
    </Stack>
  );
};

export const WizardLoadingSkeleton: React.FC = () => {
  return (
    <Grid container spacing={4} data-testid='loading'>
      <Grid item xs={12} lg={4}>
        <Card>
          <Stack spacing={0}>
            {'0'
              .repeat(3)
              .split('')
              .map((_, index) => (
                <Typography variant='h1' key={index}>
                  <Skeleton
                    animation='wave'
                    variant='text'
                    sx={{ background: (theme) => theme.palette.neutral.main }}
                  />
                </Typography>
              ))}
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Card
          footerContent={
            <>
              {'0'
                .repeat(2)
                .split('')
                .map((_, index) => (
                  <Skeleton
                    key={index}
                    animation='wave'
                    variant='rounded'
                    width={140}
                    height={40}
                    sx={{ background: (theme) => theme.palette.neutral.main }}
                  />
                ))}
            </>
          }
        >
          <Stack spacing={2}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '0.5rem 1rem',
                placeContent: 'stretch',
              }}
            >
              {'0'
                .repeat(5)
                .split('')
                .map((_, index) => (
                  <Typography variant='h2' key={index}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                      sx={{ background: (theme) => theme.palette.neutral.main }}
                    />
                  </Typography>
                ))}
            </div>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};

export const WizardSubmittingSkeleton: React.FC = () => {
  return (
    <Grid container spacing={4} data-testid='loading'>
      <Grid item xs={12} lg={4}>
        <Card>
          <Stack spacing={0}>
            <Box
              height={200}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <CircularProgress color='neutral' thickness={4} />
            </Box>
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Card
          footerContent={
            <>
              {'0'
                .repeat(2)
                .split('')
                .map((_, index) => (
                  <Skeleton
                    key={index}
                    animation='wave'
                    variant='rounded'
                    width={140}
                    height={40}
                    sx={{ background: (theme) => theme.palette.neutral.main }}
                  />
                ))}
            </>
          }
        >
          <Box
            height={200}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <CircularProgress color='neutral' thickness={4} />
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export const CircularLoadingSkeleton: React.FC<{ sx?: SxProps }> = ({ sx }) => {
  return (
    <Box
      data-testid='loading'
      height={200}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...sx }}
    >
      <CircularProgress color='primary' thickness={4} sx={sx} />
    </Box>
  );
};
