import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export interface PillProps extends ChipProps {
  onClick?: (event?: React.MouseEvent) => void;
  iconColor?:
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'action'
    | 'inherit';
}

export const Pill: React.FC<PillProps> = ({ iconColor, onClick, ...props }) => {
  return (
    <>
      {onClick ? (
        <Chip size='small' onClick={onClick} {...props} />
      ) : (
        <Chip
          icon={<FiberManualRecordIcon color={iconColor} />}
          size='small'
          sx={{ backgroundColor: (theme) => theme.palette.grey['300'] }}
          {...props}
        />
      )}
    </>
  );
};
