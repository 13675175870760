import { Schema } from '@data-driven-forms/react-form-renderer';
import { useState, useMemo, Dispatch, SetStateAction } from 'react';

export interface UseWizardReturnProps {
  presentationalSchema: Schema;
  showDialog: boolean;
  showMandatory: boolean;
  setShowMandatory: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (values: Record<string, unknown>) => Promise<void> | void;
  handleCloseDialog: () => void;
  handleDialog: () => void;
  handleCancel: () => void;
}

export const useWizard = (
  schema: Schema,
  onSubmit: (values: Record<string, unknown>) => Promise<void> | void,
  onCancel?: () => void
) => {
  const [showMandatory, setShowMandatory] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [savedValues, setSavedValues] = useState<Record<string, unknown>>({});

  const presentationalSchema = useMemo(
    () =>
      showMandatory
        ? {
            // Wizard
            fields: [
              {
                component: schema.fields[0].component,
                name: schema.fields[0].name,
                // Steps
                fields: (
                  schema.fields[0].fields as (Schema & {
                    name: string;
                    title?: React.ReactNode;
                    component: 'group' | 'panel';
                  })[]
                ).map((step, i, allSteps) => {
                  // Mark a step required if within their fields there's at least one required input
                  const isRequired = step.fields.some((field) => field.isRequired);
                  const filteredFields = step.fields.map((field) => ({
                    ...field,
                    hideField: !field.isRequired || field.hideField,
                  }));
                  // The next step should be the next one that has mandatory fields in it
                  const comingSteps = allSteps.slice(i + 1);
                  const nextStep = comingSteps.find((comingStep) =>
                    comingStep.fields.some((input) => input.isRequired)
                  );

                  return {
                    ...step,
                    nextStep: nextStep?.name,
                    isRequired,
                    // Form fields
                    fields: filteredFields,
                  };
                }),
              },
            ],
          }
        : schema,
    [schema, showMandatory]
  );

  const handleSubmit = (values: Record<string, unknown>) => {
    if (!showMandatory) {
      onSubmit(values);
    } else {
      setShowDialog(true);
      setSavedValues(values);
    }
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleCancel = () => {
    setShowMandatory(false);
    setSavedValues({});
    onCancel?.();
  };

  const handleDialog = () => {
    onSubmit(savedValues);
    setSavedValues({});
    closeDialog();
  };

  return {
    presentationalSchema,
    showDialog,
    showMandatory,
    setShowMandatory,
    handleSubmit,
    handleCloseDialog,
    handleDialog,
    handleCancel,
  };
};
