import { Typography } from '@mui/material';
import React from 'react';
import { DashboardCard } from './DashboardCard';

interface TextDashboardCardProps {
  loading: boolean;
  title: string;
  text?: string | number;
}

export const TextDashboardCard: React.FC<TextDashboardCardProps> = ({
  loading,
  text,
  title,
}) => {
  return (
    <DashboardCard
      loading={loading}
      title={title}
      sx={{
        gridColumnEnd: {
          sm: 'span 6',
          md: 'span 2',
        },
        placeSelf: 'stretch',
      }}
    >
      <Typography fontSize={32} fontWeight='bold'>
        {text}
      </Typography>
    </DashboardCard>
  );
};
