import { useDataRetrievalApolloClient } from '~/common/graphql-data-retrieval';
import { GetPollingDatesDocument } from '~/__generated-data-retrieval__/graphql';
import { useQuery } from '@apollo/client';

export const useGetPollingDates = (serialNumber?: string, ipAddress?: string | null) => {
  const client = useDataRetrievalApolloClient();

  return useQuery(GetPollingDatesDocument, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      viperAsset: {
        serialNumber: serialNumber ?? '',
        ipAddress: ipAddress ?? '',
      },
    },
    skip: !serialNumber || !ipAddress,
  });
};
