import { Box, BoxProps, ButtonGroup, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIrAndVoltageGraph } from './useIrAndVoltageGraph';
import { SelectButton } from '~/components/SelectButton';
import { ChartWithLoadingAndErrorMessages } from '~/pages/Graphs/ChartWithLoadingAndErrorMessages';

export const IrAndVoltageGraph: React.FC<
  {
    title: string;
    subtitle?: string;
    channelId?: string;
    startDate?: string;
    endDate?: string;
  } & BoxProps
> = ({ channelId, startDate, endDate, title, subtitle, ...boxProps }) => {
  const { t } = useTranslation('ir-graph');
  const { yAxisScale, setYAxisScale, loading, csv, loadingViperAssets, maxIrCapError, error } =
    useIrAndVoltageGraph(channelId, startDate, endDate);

  return (
    <Box {...boxProps}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Box display='flex' flexDirection='column' gap={2}>
          <Typography variant='h3'>{title}</Typography>
          {subtitle && <Typography variant='body2'>{subtitle}</Typography>}
        </Box>
        <Box display='flex' flexDirection='row' gap={2} alignItems='flex-end'>
          <ButtonGroup size='small' disableRipple disableElevation aria-label='y axis scale'>
            <SelectButton
              label={t('logarithmic')}
              selected={yAxisScale === 'logarithmic'}
              onClick={() => setYAxisScale('logarithmic')}
            />
            <SelectButton
              label={t('linear')}
              selected={yAxisScale === 'linear'}
              onClick={() => setYAxisScale('linear')}
            />
          </ButtonGroup>
        </Box>
      </Box>
      <ChartWithLoadingAndErrorMessages
        maxIrCapError={maxIrCapError}
        loading={loading || loadingViperAssets}
        error={!!error}
        yAxisScale={yAxisScale}
        csv={csv}
        chartHeight={250}
      />
    </Box>
  );
};
