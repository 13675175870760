import { Schema, componentTypes } from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { SelectActionHandler } from '~/components/Form/formFields/select-action-handler';
import { GetCreateChannelOptionsQuery } from '~/__generated-asset__/graphql';
import { getDerivedValue } from '../../../tools';

interface GetSystemInformationCreateSchemaProps {
  t: TFunction;
  umbilicals: GetCreateChannelOptionsQuery['umbilicals'];
  platforms: GetCreateChannelOptionsQuery['platforms'];
  productionControlSystems: GetCreateChannelOptionsQuery['productionControlSystems'];
  onAddPlatform: SelectActionHandler;
  onAddUmbilical: SelectActionHandler;
  onAddProductionControlSystem?: SelectActionHandler;
}

export function getSystemInformationCreateSchema({
  t,
  umbilicals,
  platforms,
  productionControlSystems,
  onAddPlatform,
  onAddProductionControlSystem,
  onAddUmbilical,
}: GetSystemInformationCreateSchemaProps): Schema {
  return {
    fields: [
      {
        name: 'platformId',
        sortAlphabetical: true,
        isSearchable: true,
        label: t('customer-asset-forms::platform'),
        isRequiredForReporting: true,
        component: componentTypes.SELECT,
        options: platforms,
        sx: { gridColumnEnd: 'span 2' },
        actionLinks: [
          {
            label: t('viper::actions/add'),
            action: onAddPlatform,
          },
        ],
      },
      {
        name: 'productionControlSystemId',
        sortAlphabetical: true,
        isSearchable: true,
        label: t('customer-asset-forms::production-control-system'),
        options: [],
        component: componentTypes.SELECT,
        resolveProps: (_props, _fieldApi, formOptions) => {
          const platformId = formOptions.getState().values['platformId'];
          return {
            isDisabled: !platformId,
            options: productionControlSystems
              .filter((pcs) => pcs.platform.id === platformId)
              .map((pcs) => ({
                id: pcs.id,
                name: getDerivedValue(pcs, ['epuManufacturer.name', 'pcsManufacturer.name']),
              })),
          };
        },
        actionLinks: [
          {
            label: t('viper::actions/add'),
            action: onAddProductionControlSystem,
            disabled: (value: string | undefined, values: Record<string, unknown>) =>
              !values['platformId'],
          },
        ],
      },
      {
        name: 'umbilicalId',
        sortAlphabetical: true,
        isSearchable: true,
        label: t('customer-asset-forms::umbilical'),
        component: componentTypes.SELECT,
        resolveProps: (_props, _fieldApi, formOptions) => {
          const platformId = formOptions.getState().values['platformId'];
          return {
            isDisabled: !platformId,
            options: umbilicals
              .filter((umbilical) => umbilical.platform.id === platformId)
              .map((umbilical) => ({
                id: umbilical.id,
                name: `${getDerivedValue(umbilical, ['manufacturer.name', 'length'])} km`,
              })),
          };
        },
        actionLinks: [
          {
            label: t('viper::actions/add'),
            action: onAddUmbilical,
            disabled: (value: string | undefined, values: Record<string, unknown>) =>
              !values['platformId'],
          },
        ],
      },
    ],
  };
}
