import {
  Schema,
  componentTypes,
  validatorTypes,
  dataTypes,
} from '@data-driven-forms/react-form-renderer';
import {
  GetCreateViperAssetOptionsQuery,
  GetViperAssetQuery,
} from '~/__generated-asset__/graphql';
import { ipAddress, macAddress } from '../../../../schemas/tools';
import { useTranslation } from 'react-i18next';

interface GetConfigurationCreateSchemaProps {
  commsTypes?: GetCreateViperAssetOptionsQuery['commsTypes'];
  firmwareRevisions?: GetCreateViperAssetOptionsQuery['firmwareRevisions'];
  ipAssignmentMethods?: GetCreateViperAssetOptionsQuery['ipAssignmentMethods'];
  rootRevisionPasswords?: GetCreateViperAssetOptionsQuery['rootRevisionPasswords'];
  remoteAccessOptions?: { id: boolean; name: string }[];
  viperAsset?: GetViperAssetQuery['viperAsset'];
  editing?: boolean;
}

export const useConfigurationCreateSchema = ({
  commsTypes,
  firmwareRevisions,
  ipAssignmentMethods,
  rootRevisionPasswords,
}: GetConfigurationCreateSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  const remoteAccessOptions = [
    { value: true, label: t('remoteAccessOptions/yes') },
    { value: false, label: t('remoteAccessOptions/no') },
  ];

  return {
    fields: [
      {
        name: 'primaryCommsId',
        label: t('primaryComms'),
        helperText: t('primary-comms-helper'),
        isClearable: true,
        sortAlphabetical: true,
        isSearchable: true,
        component: componentTypes.SELECT,
        options: commsTypes,
      },
      {
        name: 'currentFirmwareRevisionId',
        label: t('currentFirmwareRevision'),
        isRequiredForReporting: true,
        isClearable: true,
        sortAlphabetical: true,
        isSearchable: true,
        component: componentTypes.SELECT,
        options: firmwareRevisions,
      },
      {
        name: 'ipAddress',
        placeholder: 'e.g 123.1.11.0',
        label: t('ipAddress'),
        helperText: t('ip-address-helper'),
        component: componentTypes.TEXT_FIELD,
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: ipAddress,
            message: t('ip-address-message'),
          },
        ],
      },
      {
        name: 'ipAssignmentMethodId',
        label: t('ipAssignmentMethod'),
        isClearable: true,
        sortAlphabetical: true,
        isSearchable: true,
        component: componentTypes.SELECT,
        options: ipAssignmentMethods,
      },
      {
        name: 'macAddress',
        placeholder: 'e.g 5c:aa:12:45:c3:f4',
        label: t('macAddress'),
        sx: { gridColumnEnd: 'span 2' },
        component: componentTypes.TEXT_FIELD,
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: macAddress,
            message: t('mac-address-message'),
          },
        ],
      },
      {
        name: 'adminPassword',
        label: t('adminPassword'),
        component: componentTypes.TEXT_FIELD,
      },
      {
        name: 'rootRevisionPasswordId',
        label: t('rootRevisionPassword'),
        isClearable: true,
        sortAlphabetical: true,
        isSearchable: true,
        component: componentTypes.SELECT,
        options: rootRevisionPasswords,
      },
      {
        name: 'maxIrCap',
        label: t('maxIrCap'),
        isRequiredForReporting: true,
        component: componentTypes.TEXT_FIELD,
        dataType: dataTypes.INTEGER,
        type: 'number',
        suffix: 'Ω',
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 1000,
            message: t('max-ir-cap-message'),
          },
          {
            type: validatorTypes.MAX_NUMBER_VALUE,
            includeThreshold: true,
            value: 1000000000,
            message: t('max-ir-cap-message-max'),
          },
        ],
      },
      {
        name: 'remoteAccess',
        label: t('remoteAccess'),
        component: componentTypes.SELECT,
        sortAlphabetical: true,
        isSearchable: true,
        options: remoteAccessOptions,
        useStandardOptions: true,
        dataType: dataTypes.BOOLEAN,
      },
    ],
  };
};
