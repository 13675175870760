import { useLazyQuery, useQuery } from '@apollo/client';
import {
  DateRangeInput,
  FloatMeasurementType,
  GetFloatCsvForAssetsDocument,
  GetFloatReadingsForAssetsDocument,
  GetGapsInVlogMessagesDocument,
  GetLatestFloatReadingsForAssetsDocument,
  GetStringReadingsForAssetsDocument,
  GetVLogFileDownloadTokenDocument,
  GetVLogFilesDocument,
  StringMeasurementType,
  ViperAssetMaxIrCapInput,
  ViperAssetPeriodInput,
} from '~/__generated-measurement__/graphql';
import { useMeasurementApolloClient } from '~/common/graphql-measurement';

export function useGetVLogFileDownloadToken() {
  const client = useMeasurementApolloClient();
  return useLazyQuery(GetVLogFileDownloadTokenDocument, {
    fetchPolicy: 'no-cache',
    client,
  });
}

export function useGetVLogFiles() {
  const client = useMeasurementApolloClient();
  return useQuery(GetVLogFilesDocument, {
    fetchPolicy: 'no-cache',
    client,
    pollInterval: 5000,
  });
}

export function useGetFloatReadingsForSerialNumbers(
  viperAssetPeriods: ViperAssetPeriodInput[] | undefined,
  viperAssetMaxIRCaps: ViperAssetMaxIrCapInput[] | undefined,
  measurementTypes: FloatMeasurementType[]
) {
  const client = useMeasurementApolloClient();
  return useQuery(GetFloatReadingsForAssetsDocument, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      viperAssetPeriods: viperAssetPeriods ?? [],
      maxIrCaps: viperAssetMaxIRCaps ?? [],
      measurementTypes,
      maxDownsampledDataPoints: 8000,
    },
    skip: (viperAssetMaxIRCaps?.length ?? 0) === 0 || (viperAssetPeriods?.length ?? 0) === 0,
  });
}

/*
  This query returns an array
 */
export function useGetLatestFloatReadingsForAssets(
  viperAssetPeriods: ViperAssetPeriodInput[][] | undefined,
  viperAssetMaxIRCaps: ViperAssetMaxIrCapInput[] | undefined,
  measurementType: FloatMeasurementType
) {
  const client = useMeasurementApolloClient();
  return useQuery(GetLatestFloatReadingsForAssetsDocument, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      viperAssetPeriods: viperAssetPeriods ?? [],
      maxIrCaps: viperAssetMaxIRCaps ?? [],
      measurementType,
    },
    skip: (viperAssetMaxIRCaps?.length ?? 0) === 0 || (viperAssetPeriods?.length ?? 0) === 0,
  });
}

export function useGetLatestFloatReadingsForAssetsLazy() {
  const client = useMeasurementApolloClient();
  return useLazyQuery(GetLatestFloatReadingsForAssetsDocument, {
    fetchPolicy: 'no-cache',
    client,
  });
}

export function useGetStringReadingsForSerialNumbers(
  viperAssetPeriods: ViperAssetPeriodInput[] | undefined,
  measurementTypes: StringMeasurementType[]
) {
  const client = useMeasurementApolloClient();
  return useQuery(GetStringReadingsForAssetsDocument, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      viperAssetPeriods: viperAssetPeriods ?? [],
      measurementTypes: measurementTypes,
    },
    skip: (viperAssetPeriods?.length ?? 0) === 0,
  });
}

export function useGetVoltageAndIRForSerialNumbers(
  viperAssetPeriods: ViperAssetPeriodInput[] | undefined,
  viperAssetMaxIRCaps: ViperAssetMaxIrCapInput[] | undefined,
  maxDownsampledDataPoints: number
) {
  const client = useMeasurementApolloClient();
  return useQuery(GetFloatCsvForAssetsDocument, {
    fetchPolicy: 'cache-first',
    client,
    variables: {
      viperAssetPeriods: viperAssetPeriods ?? [],
      maxIrCaps: viperAssetMaxIRCaps ?? [],
      maxDownsampledDataPoints,
      measurementTypes: [FloatMeasurementType.Limresistance, FloatMeasurementType.Linevoltage],
    },
    skip: (viperAssetPeriods?.length ?? 0) === 0 || (viperAssetMaxIRCaps?.length ?? 0) === 0,
  });
}

export function useGetGapsInVlogMessages(
  period: DateRangeInput,
  minGapDurationMinutes: number,
  viperAssetPeriods: ViperAssetPeriodInput[] | undefined,
  licensePeriods: DateRangeInput[] | undefined,
  measurementTypes: FloatMeasurementType[] | undefined
) {
  const client = useMeasurementApolloClient();
  return useQuery(GetGapsInVlogMessagesDocument, {
    fetchPolicy: 'cache-first',
    client,
    skip:
      (viperAssetPeriods ?? []).length === 0 ||
      (licensePeriods ?? []).length === 0 ||
      (measurementTypes ?? []).length === 0,
    variables: {
      period: period,
      licencePeriods: licensePeriods ?? [],
      viperAssetPeriods: viperAssetPeriods ?? [],
      minGapDurationMinutes: minGapDurationMinutes,
      measurementTypes: measurementTypes ?? [],
    },
  });
}
