import {
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import React, { isValidElement } from 'react';
import { IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const DataGridCustomExpander = ({
  id,
  value: isExpanded,
  row,
}: GridRenderCellParams) => {
  const apiRef = useGridApiContext();

  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  const hasDetail = isValidElement(contentCache[id]);

  if (!hasDetail) {
    return <></>;
  }

  const handleClick = (event: React.MouseEvent) => {
    apiRef.current.toggleDetailPanel(row.id);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <IconButton
      size='small'
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
      onClick={handleClick}
    >
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize='inherit'
      />
    </IconButton>
  );
};
