import { useMemo } from 'react';
import { buildPath, Path } from '~/routes/paths';
import { WarningLink } from '~/components/WarningLink';
import { useGetLatestFloatReadingsForAssets } from '~/queries-measurement/measurement';
import { formatDate } from '~/utils/date-utils';
import { FloatMeasurementType } from '~/__generated-measurement__/graphql';
import { useTranslation } from 'react-i18next';
import { WarningListResponse } from '~/pages/warnings/types';
import { useGetInstalledViperAssetPeriodAndMaxIRCaps } from '~/queries-asset/channel';

/*
  This hook only cares about the presence / lack of limresistance measurements as this parameter
  is most important to V-Life, as per the discussion below:

  Q: If there are no limresistance readings but there is linevoltage, we want to consider
   the data insufficient for creating a report?
  [11:25 04/Dec/2023] Gregory Hutchings
  Yes, though in theory that situation shouldn't be possible as you can't get a type1 or type3
  limresistance measurement without a linevoltage measurement. Future products might not hold
  to that assumption though so I reckon having it based on the limresistance is the best way forward
 */
export const useGetMissingReadingsWarnings = (
  channelId: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined
): WarningListResponse => {
  const { t } = useTranslation('warnings');
  const {
    loading: loadingViperAssets,
    viperAssetPeriods,
    viperAssetMaxIRCaps,
  } = useGetInstalledViperAssetPeriodAndMaxIRCaps(channelId, startDate, endDate);

  const { data: irReadings, loading: loadingReadings } = useGetLatestFloatReadingsForAssets(
    [viperAssetPeriods ?? []] ?? [],
    viperAssetMaxIRCaps ?? [],
    FloatMeasurementType.Limresistance
  );

  const loading = useMemo(
    () => loadingViperAssets || loadingReadings,
    [loadingViperAssets, loadingReadings]
  );

  const missingReadings: boolean = useMemo(() => {
    // If loading, don't return a warning
    if (loading) {
      return false;
    }
    // If there are no installed viper asset periods, there can't be any measurement data, show warnings
    if (
      !viperAssetPeriods ||
      viperAssetPeriods.length === 0 ||
      !viperAssetMaxIRCaps ||
      viperAssetMaxIRCaps.length === 0
    ) {
      return true;
    }

    // If measurement data is returned but there is none, show warnings
    const irReadingsFound = irReadings?.getLatestFloatReadingsForAssets.find(
      (reading) => reading !== null
    );

    return !irReadingsFound;
  }, [irReadings, viperAssetPeriods, viperAssetMaxIRCaps, loading]);

  const warnings = useMemo<WarningLink[]>((): WarningLink[] => {
    if (missingReadings) {
      return [
        {
          id: `missing-readings-${channelId}`,
          warningMessage: t('missing-readings', {
            start: formatDate(startDate),
            end: formatDate(endDate),
          }),
          link: {
            text: t('missing-readings-link'),
            url: buildPath(Path.V_LOGS),
          },
        },
      ];
    }

    return [];
  }, [missingReadings, channelId, endDate, startDate, t]);

  return {
    warnings,
    loading,
  };
};
