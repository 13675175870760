import { DateRange, DateStringRange } from '~/common/types';
import { formatDateYYYYMMDD } from '~/components/DataGrid/filters/utils';
import { useMemo } from 'react';
import { getMonthStartAndEnd, getEndOfDay, getStartOfDay } from '~/pages/Dashboard/date-utils';

interface DateRangesResponse {
  dateRanges: DateStringRange[];
}

export const useGetMonthlyDateRanges = (dateRange: DateRange): DateRangesResponse => {
  const dateRanges = useMemo(() => {
    if (dateRange.endDate <= dateRange.startDate) {
      return [];
    }

    const periodStartDate = getStartOfDay(dateRange.startDate);
    const periodEndDate = getEndOfDay(dateRange.endDate);

    const allDateRanges: DateStringRange[] = [];
    const date = new Date(periodStartDate);

    while (date < periodEndDate) {
      const { startDate, endDate } = getMonthStartAndEnd(date);
      allDateRanges.push({
        startDate: formatDateYYYYMMDD(
          new Date(Math.max(startDate.getTime(), periodStartDate.getTime())).toISOString()
        ),
        endDate: formatDateYYYYMMDD(
          new Date(Math.min(endDate.getTime(), periodEndDate.getTime())).toISOString()
        ),
      });
      // Set iterator date to one month from start of this month
      date.setTime(startDate.getTime());
      date.setUTCMonth(date.getUTCMonth() + 1);
    }

    return allDateRanges;
  }, [dateRange.endDate, dateRange.startDate]);

  return { dateRanges };
};
