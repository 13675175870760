import { Box, useTheme } from '@mui/material';
import React from 'react';

export const Logo: React.FC<{ colors?: [string, string] }> = ({ colors }) => {
  const theme = useTheme();
  return (
    <Box>
      <svg
        width='98'
        height='109'
        viewBox='0 0 98 109'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M97.6309 0.980469H0.367188V43.9844H31.7956L49.0879 65.818L66.3802 43.9844H97.6309V0.980469Z'
          fill={colors?.[0] ?? theme.palette.divider}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.4139 60.6094H0.367188V108.246H97.6309V60.6094H73.7619L49.0879 91.7633L24.4139 60.6094Z'
          fill={colors?.[1] ?? theme.palette.divider}
        />
      </svg>
    </Box>
  );
};
