import { GetViperAssetQuery } from '~/__generated-asset__/graphql';
import { componentTypes, dataTypes, Schema } from '@data-driven-forms/react-form-renderer';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '~/utils/date-utils';
import { useGetPollingDates } from '~/queries-data-retrieval/useGetPollingDates';

interface UseAutomaticPollingSchemaProps {
  viperAsset?: GetViperAssetQuery['viperAsset'];
}

export const useAutomaticPollingEditSchema = ({
  viperAsset,
}: UseAutomaticPollingSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  const { data } = useGetPollingDates(viperAsset?.serialNumber, viperAsset?.ipAddress);

  const remoteAccessOptions = [
    { value: true, label: t('remoteAccessOptions/yes') },
    { value: false, label: t('remoteAccessOptions/no') },
  ];

  return {
    fields: [
      {
        name: 'remoteAccess',
        label: t('remoteAccess'),
        component: componentTypes.SELECT,
        options: remoteAccessOptions,
        useStandardOptions: true,
        dataType: dataTypes.BOOLEAN,
        initialValue: viperAsset?.remoteAccess,
        sx: { gridColumnEnd: 'span 2' },
      },
      {
        name: 'lastPollingDate',
        label: t('lastPollingDate'),
        component: componentTypes.PLAIN_TEXT,
        initialValue: formatDateTime(data?.getPollingDates?.lastPollingDate),
      },
      {
        name: 'nextPollingDate',
        label: t('nextPollingDate'),
        component: componentTypes.PLAIN_TEXT,
        initialValue: formatDateTime(data?.getPollingDates?.nextPollingDate),
      },
    ],
  };
};
