import React from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { TextFieldProps } from '@mui/material/TextField';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { NegatableNumberFilters } from '~/components/DataGrid/filters/types';
import { CustomInput } from '~/components/Table/components/CustomInput';
import { useFilterDebounceAndApply } from '~/components/DataGrid/filters/useFilterDebounceAndApply';
import { parseNumberOrUndefined } from '~/components/DataGrid/filters/utils';
import { useTranslation } from 'react-i18next';

const defaultValues: NegatableNumberFilters = {
  isNegated: false,
  comparatorValue: undefined,
};

export const NegatableNumberInputFilter = ({
  item,
  applyValue,
}: GridFilterInputValueProps) => {
  const { t } = useTranslation();
  const { values, updateFilterValue } = useFilterDebounceAndApply<NegatableNumberFilters>(
    item,
    applyValue,
    defaultValues
  );

  const handleValueFilterChange: TextFieldProps['onChange'] = (event) => {
    const newValue = parseNumberOrUndefined(event.target.value);
    updateFilterValue({ comparatorValue: newValue });
  };

  const handleNegationFilterChange = (): void => {
    updateFilterValue({ isNegated: !values.isNegated });
  };

  return (
    <Box>
      <CustomInput
        name='number'
        placeholder={t('filter-inputs::number')}
        value={parseNumberOrUndefined(values.comparatorValue) ?? ''}
        onChange={handleValueFilterChange}
        type='number'
      />
      <FormControlLabel
        sx={{ ml: 2 }}
        label={t('filter-inputs::negate')}
        control={
          <Switch
            checked={values.isNegated}
            onChange={handleNegationFilterChange}
            size='small'
          />
        }
      />
    </Box>
  );
};
