import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGetCreateViperAssetOptions } from '~/queries-asset/viper-asset';
import { GetCreateViperAssetOptionsQuery } from '~/__generated-asset__/graphql';

type ViperAssetFormOptions = Omit<GetCreateViperAssetOptionsQuery, '__typename'>;

const defaultFormOptions: ViperAssetFormOptions = {
  parts: [],
  commsTypes: [],
  customers: [],
  firmwareRevisions: [],
  ipAssignmentMethods: [],
  manufacturers: [],
  rootRevisionPasswords: [],
};

const FormOptionsContext = createContext<ViperAssetFormOptions>(defaultFormOptions);

export const FormOptionsProvider = ({ children }: PropsWithChildren) => {
  const [options, setOptions] = useState<ViperAssetFormOptions>();

  const { data, loading } = useGetCreateViperAssetOptions(options != undefined);

  useEffect(() => {
    if (!options && !loading && data) {
      setOptions(data);
    }
  }, [options, loading, data]);

  const cachedOptions = useMemo<ViperAssetFormOptions>(
    () => options ?? defaultFormOptions,
    [options]
  );

  return (
    <FormOptionsContext.Provider value={cachedOptions}>{children}</FormOptionsContext.Provider>
  );
};

export const useViperAssetFormOptions = (): ViperAssetFormOptions => {
  return useContext(FormOptionsContext);
};
