import { TFunction } from 'i18next';
import { GetMaintenanceLogOptionsQuery } from '~/__generated-asset__/graphql';
import { getMaintenanceLogEditSchema } from '../../edit/maintenanceLog/maintenanceLogEditSchema';

export const getMaintenanceLogCreateSchema = ({
  t,
  options,
  maintenanceLog,
}: {
  t: TFunction;
  options: GetMaintenanceLogOptionsQuery | undefined;
  // @ts-expect-error type too deep for TS
  maintenanceLog?: GetViperAssetQuery['viperAsset']['maintenanceLogs'][0];
}) => {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-maintenance-log-wizard',
        hideStepper: true,
        fields: [
          {
            name: 'maintenanceLog',
            title: t('viper-asset::maintenance-log'),
            fields: getMaintenanceLogEditSchema({
              t,
              options,
              maintenanceLog,
            }).fields,
          },
        ],
      },
    ],
  };
};
