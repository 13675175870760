import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditPane } from '~/components/Form/EditPane';
import { Dialog } from '~/components/Dialog';
import { Field } from '@data-driven-forms/react-form-renderer';
import { FormSpyWithBlocker, SpyHandlerProps } from '~/components/Form/FormSpyWithBlocker';

export interface FormDialogProps {
  open: boolean;
  fields: Field[];
  title: string;
  onCancel: () => void;
  onSave: (values: Record<string, unknown> | undefined) => void;
  validate?: (values: Record<string, unknown>) => Record<string, string> | undefined;
  disabled?: boolean;
  afterContent?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
}

export const FormDialog: React.FC<FormDialogProps> = ({
  open,
  fields,
  onCancel,
  onSave,
  disabled,
  title,
  validate,
  afterContent,
  confirmText,
  cancelText,
}) => {
  const { t } = useTranslation();
  const [spyProps, setSpyProps] = useState<SpyHandlerProps>();

  useEffect(() => {
    if (!open) {
      spyProps?.form?.reset();
      setSpyProps(undefined);
    }
  }, [open, spyProps?.form]);

  const Container = useRef(({ children }: React.PropsWithChildren) => (
    <FormSpyWithBlocker onCancel={onCancel}>
      {(spyProps) => {
        setSpyProps(spyProps);
        return children;
      }}
    </FormSpyWithBlocker>
  ));

  const content = useMemo(
    () => (
      <>
        <EditPane
          validate={validate}
          disableFields={disabled}
          fields={fields}
          container={Container.current}
        />
        {afterContent}
      </>
    ),
    [validate, disabled, fields, afterContent]
  );

  return (
    <Dialog
      confirmText={confirmText ?? t('viper::save')}
      open={open}
      cancelText={cancelText ?? t('viper::cancel')}
      title={title}
      content={content}
      onClose={(confirm) => {
        if (!confirm) {
          spyProps?.handleCancel();
        }
      }}
      onConfirm={async () => {
        onSave(spyProps?.values);
      }}
      confirmDisabled={spyProps === undefined || spyProps?.invalid || disabled}
      disabled={disabled}
    />
  );
};
