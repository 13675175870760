import { Person } from '~/__generated-asset__/graphql';

export function formatPerson(person: Pick<Person, 'id' | 'forename' | 'surname'>): string;
export function formatPerson(person: null | undefined): undefined;
export function formatPerson(
  person: Pick<Person, 'id' | 'forename' | 'surname'> | null | undefined
): string | undefined;
export function formatPerson(
  person: Pick<Person, 'id' | 'forename' | 'surname'> | null | undefined
): string | undefined {
  if (!person) {
    return undefined;
  }
  return `${person.forename} ${person.surname}`;
}
