import { useQuery } from '@apollo/client';
import {
  GetDashboardDetailsByMonthDocument,
  GetDashboardValuesDocument,
} from '~/__generated-asset__/graphql';

export function useGetDashboardValues() {
  return useQuery(GetDashboardValuesDocument);
}

export function useGetDashboardDetailsByMonth(month: string) {
  return useQuery(GetDashboardDetailsByMonthDocument, { variables: { month }, skip: !month });
}
