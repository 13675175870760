import { useEffect, useMemo, useRef, useState } from 'react';
import usePagination, { UsePaginationItem } from '@mui/material/usePagination/usePagination';
import { debounce } from 'lodash';
import { ComplexValueType, RowDataType, ValueType } from './Table';
import { Field } from '@data-driven-forms/react-form-renderer';
import { ActionMenuItem } from '~/components/ActionMenu';

export interface ActionMenuColumn<T> {
  /**
   * The menu to show when the menu dots in this column are clicked on.
   * This can either be an actual list of menu items, or a function that
   * will be called when the menu dots are clicked.
   */
  actionMenu: ActionMenuItem[] | ((rowData?: T) => ActionMenuItem[]);
  label: string;
}

export interface UseTableProps<T> {
  itemsPerPage?: number;
  data: T[];
  omittedKeys?: (keyof T)[];
  defaultOrderBy?: keyof T;
  defaultOrder?: Order;
  onRowClick?: (rowData: T | undefined) => void;
  /**
   * Fired when the menu dots are clicked, but before the menu is shown.
   * Gives you a change to change the menu dynamically depending on the row.
   */
  onActionMenuClick?: (
    rowIndex: number,
    rowData: T | undefined,
    menuItems: ActionMenuItem[]
  ) => ActionMenuItem[];
  onActionClick?: (menuItemId: string, rowIndex: number, rowData: T | undefined) => void;
  nestedData?: Partial<T>[];
  actionMenu?: ActionMenuColumn<T>;
}

export interface UseTableReturnProps<T> {
  showPagination: boolean;
  presentationalData: T[];
  columnLabels: string[];
  items: UsePaginationItem[];
  skip: number;
  take: number;
  total: number;
  handleRowClick?: (rowData?: T) => void;
  handleSearchChange: (term?: string) => void;
  nestedTableData?: Field[][];
  orderBy: keyof T;
  order: Order;
  getSortHandler: (property: keyof T) => (event: React.MouseEvent<unknown>) => void;
}

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aa = (a[orderBy] as ComplexValueType)?.value ?? a[orderBy] ?? '';
  const bb = (b[orderBy] as ComplexValueType)?.value ?? b[orderBy] ?? '';

  if (bb < aa) {
    return -1;
  }
  if (bb > aa) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof RowDataType>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: ValueType }, b: { [key in Key]: ValueType }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const useTable = <T extends RowDataType>({
  itemsPerPage = 10,
  data,
  omittedKeys,
  onRowClick,
  nestedData = [],
  actionMenu,
  defaultOrderBy = '',
  defaultOrder = 'asc',
}: UseTableProps<T>): UseTableReturnProps<T> => {
  const [page, setPage] = useState(1);
  const [term, setTerm] = useState<string | undefined>();
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = useState<keyof T>(defaultOrderBy);

  const getSort = (_event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    getSort(event, property);
  };

  const debouncedSearch = useRef(
    debounce(async (term?: string) => {
      setTerm(term);
      setPage(1);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const filteredData = useMemo(
    () =>
      data
        .filter((row) => searchAllCaseInsensitive<T>(row, term, omittedKeys))
        .sort(getComparator(order, orderBy)),
    [data, order, orderBy, term, omittedKeys]
  );

  const total = filteredData.length;
  const skip = (page - 1) * itemsPerPage;
  const take = Math.min(total, page * itemsPerPage);

  const handleSearchChange = (term?: string) => {
    debouncedSearch(term);
  };
  // this does nothing
  const handleRowClick = onRowClick ? (rowData?: T) => onRowClick?.(rowData) : undefined;
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const { items } = usePagination({
    count: Math.ceil(total / itemsPerPage),
    boundaryCount: 1,
    showLastButton: false,
    showFirstButton: false,
    siblingCount: 0,
    onChange: handleChange,
  });

  const columnLabels = useMemo(() => {
    const labels = data.reduce((acc: string[], elem) => {
      const keys: string[] = Object.keys(elem);
      const uniqueKeys = keys.filter(
        (key) => !acc.includes(key) && !omittedKeys?.includes(key)
      );
      return [...acc, ...uniqueKeys];
    }, []);
    if (actionMenu) {
      labels.push(actionMenu.label);
    }
    return labels;
  }, [actionMenu, data, omittedKeys]);

  const presentationalData = useMemo(
    () => filteredData.slice(skip, take),
    [filteredData, skip, take]
  );

  const nestedTableData: Field[][] = nestedData.map((data) => {
    const tables: Field[] = [];

    for (const key in data) {
      tables.push({
        name: key,
        label: key,
        initialValue: data[key],
      } as unknown as Field);
    }

    return tables;
  });

  const showPagination = data.length > itemsPerPage;

  return {
    showPagination,
    presentationalData,
    columnLabels,
    items,
    skip,
    take,
    total,
    handleRowClick,
    handleSearchChange,
    nestedTableData,
    orderBy,
    order,
    getSortHandler,
  };
};

export function searchAllCaseInsensitive<T>(
  row: T,
  term?: string,
  omittedKeys?: Array<keyof T>
) {
  // TODO: maybe a more performant function here?
  const _row = Object.assign({}, row);
  if (omittedKeys) {
    for (const key of omittedKeys) {
      delete _row[key];
    }
  }
  const values = Object.values(_row).map((value) => {
    if (!value) {
      return '';
    }
    if (typeof value === 'object' && 'textValue' in value) {
      return (value as ComplexValueType)?.textValue?.toLowerCase(); // use textValue for search if available
    } else if (typeof value === 'object') {
      return `${(value as ComplexValueType)?.label}`?.toLowerCase(); // use label for search if value is an object and no text values is specified
    } else if (typeof value === 'string') {
      return value.toLowerCase(); // use value for search if value is a string
    }
  });
  return !term ? true : values.some((value) => value?.includes(term.toLowerCase()));
}
