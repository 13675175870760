/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
  GridPagination as MuiGridPagination,
} from '@mui/x-data-grid-pro';
import { Pagination } from './Pagination';
import { useTranslation } from 'react-i18next';
import { PaginationProps } from '@mui/material';

interface GridPaginationProps extends PaginationProps {
  page: number; // 0-based
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  ref: React.Ref<HTMLDivElement>;
}

const ViperGridPagination: React.FC<GridPaginationProps> = ({ page, onPageChange }) => {
  const apiRef = useGridApiContext();

  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  // 1-based
  const { items } = usePagination({
    count: pageCount,
    page: page + 1,
    onChange: (_event, value) => {
      onPageChange(_event, value - 1);
    },
  });

  return <Pagination items={items} />;
};

export function GridPagination(props: Readonly<GridPaginationProps>) {
  const { t } = useTranslation();

  return (
    <MuiGridPagination
      ActionsComponent={ViperGridPagination}
      {...props}
      labelDisplayedRows={({
        from,
        to,
        count,
      }: {
        from: number;
        to: number;
        count: number;
      }) => {
        return count > 0
          ? t('table::footer-results', {
              skip: from,
              take: to,
              total: count,
            })
          : t('table::footer-empty-items');
      }}
    />
  );
}
