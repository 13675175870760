import { useMemo } from 'react';
import { useGetPendingVLifeReports } from '~/queries-asset/channel';
import { usePendingVLifeReportColumns } from '~/pages/VLifeReports/usePendingVLifeReportColumns';
import { dataGridRowMapper } from '~/components/DataGrid/utils';

export const usePendingVLifeReports = () => {
  const { data, loading, error } = useGetPendingVLifeReports();
  const { columns } = usePendingVLifeReportColumns();

  const rows = useMemo(() => {
    const channelIds = new Set<string>();
    return (
      data?.pendingVLifeReports
        .filter((report) => {
          if (channelIds.has(report.channel.id)) {
            return false;
          }
          channelIds.add(report.channel.id);
          return true;
        })
        .map((report) => ({ id: report.channel.id, action: true, ...report }))
        .map(dataGridRowMapper(columns)) ?? []
    );
  }, [columns, data?.pendingVLifeReports]);

  const displayModel = {
    id: false,
    field: true,
    'channel.customer.name': true,
    'channel.operator.name': false,
    'channel.field.name': true,
    'channel.platform.name': false,
    'channel.name': true,
    latestReportDate: true,
    daysOfVLifeCoverageToDate: false,
    'vLifeConfig.startDate': false,
    'vLifeConfig.expiryDateCommToCustomer': false,
    'vLifeConfig.resumptionDate': false,
    'vLifeConfig.isDisabled': false,
    'vLifeConfig.isExpired': false,
    'vLifeConfig.deactivationReason.name': false,
  };

  return {
    columns,
    rows,
    loading,
    error,
    displayModel,
  };
};
