export function getCustomLabel(
  boxPlot: { subGroup: string; data: { extrema: [number, number] } },
  index: number,
  gainString = 'Gain'
) {
  // the first label should be the min or max value of the group depending on the subgroup
  if (index === 0) {
    return boxPlot.subGroup === gainString ? boxPlot.data.extrema[1] : boxPlot.data.extrema[0];
  } else {
    return boxPlot.subGroup === gainString
      ? boxPlot.data.extrema[1] - boxPlot.data.extrema[0]
      : boxPlot.data.extrema[0] - boxPlot.data.extrema[1];
  }
}

export function getCurrentFinancialMonth(date: Date, offset = 0): number {
  //  1 based (0 is previous year)
  const financialMonth = date.getMonth() + offset + 1;
  if (financialMonth % 12 <= 0) {
    return 12 + (financialMonth % 12);
  }
  return financialMonth % 12;
}
