import { DateTime, DateTimeFormatOptions } from 'luxon';

export type GetDatePlaceholderOptions = {
  locale: string;
  dayPlaceholder: string;
  monthPlaceholder: string;
  yearPlaceholder: string;
};

export function getDatePlaceholder(options: GetDatePlaceholderOptions): string {
  const date = DateTime.fromISO('2033-01-05');
  const formatted = date.toLocaleString(DateTime.DATE_SHORT, {
    locale: options.locale,
  });
  if (!formatted.includes('33')) {
    // we don't recognise the number script, so we can't produce a placeholder
    return '';
  }
  const placeholder = formatted
    .replace('01', options.monthPlaceholder)
    .replace('1', options.monthPlaceholder)
    .replace('05', options.dayPlaceholder)
    .replace('5', options.dayPlaceholder)
    .replace('2033', options.yearPlaceholder)
    .replace('33', options.yearPlaceholder);

  return placeholder;
}

export function formatDate(
  isoDate: string | Date | undefined | null,
  formatOptions?: DateTimeFormatOptions
): string | undefined {
  if (isoDate) {
    return DateTime.fromISO(`${isoDate}`).setLocale('en-gb').toLocaleString(formatOptions);
  }
  return undefined;
}

export function formatDateTime(isoDate: string | undefined | null): string | undefined {
  if (isoDate) {
    return DateTime.fromISO(isoDate)
      .setLocale('en-gb')
      .toLocaleString(DateTime.DATETIME_SHORT);
  }
  return undefined;
}

export function formatDateTimeUTC(isoDate: string | undefined | null): string | undefined {
  if (isoDate) {
    return DateTime.fromISO(isoDate)
      .toUTC()
      .setLocale('en-gb')
      .toLocaleString(DateTime.DATETIME_SHORT);
  }
  return undefined;
}

export function getRecentYears() {
  let year = new Date().getFullYear();
  const years = [];
  do {
    years.push({
      id: year,
      name: year,
    });
  } while (year-- > 1950);
  return years;
}

export function getAllMonthsBetweenStartAndEnd(
  startDateISO: string,
  endDateISO: string,
  locale = 'en-gb'
) {
  const months = [];
  const startDate = DateTime.fromISO(startDateISO);
  const endDate = DateTime.fromISO(endDateISO);
  // return no options
  if (startDate > endDate) {
    return [];
  }
  let currentDate = startDate;
  do {
    months.push({
      id: currentDate.toFormat('yyyy-MM-01'),
      name: currentDate.toLocaleString({ month: 'long', year: 'numeric' }, { locale }), // e.g. 'January 2021'
    });
    currentDate = currentDate.plus({ months: 1 });
  } while (currentDate <= endDate);
  // add last month if start date and end date are not in the same month and less than a month apart
  if (endDate.minus({ months: 1 }) < startDate && !(endDate.month === startDate.month)) {
    months.push({
      id: endDate.toFormat('yyyy-MM-01'),
      name: endDate.toLocaleString({ month: 'long', year: 'numeric' }, { locale }), // e.g. 'January 2021'
    });
  }
  return months;
}
