import { getVLifeEditSchema } from '../../../edit/customerAsset/vLifeEditSchema';
import { GetCreateViperServiceOptionsQuery } from '~/__generated-asset__/graphql';
import { TFunction } from 'i18next';

export const getVLifeCreateSchema = ({
  t,
  createViperServiceOptions,
}: {
  t: TFunction;
  createViperServiceOptions: GetCreateViperServiceOptionsQuery | undefined;
}) => {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-licence-wizard',
        editing: true,
        fields: [
          {
            name: 'v-life-licence',
            title: t('customer-asset-forms::v-life-licence'),
            fields: getVLifeEditSchema({
              t,
              editing: true,
              creating: true,
              vlifeLicenceTypes: createViperServiceOptions?.vlifeLicenceTypes,
              vlifeVersions: createViperServiceOptions?.vlifeVersions,
              deactivationReasons: createViperServiceOptions?.deactivationReasons,
            }).fields,
            editing: true,
          },
        ],
      },
    ],
  };
};
