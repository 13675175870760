import {
  NegatableStringFilters,
  TypedGridFilterItem,
} from '~/components/DataGrid/filters/types';
import { GetApplyFilterFnLegacy, GridCellParams } from '@mui/x-data-grid-pro';

export const getStringContainsFilter: GetApplyFilterFnLegacy = ({
  value: filterValues,
}: TypedGridFilterItem<NegatableStringFilters>) => {
  if (filterValues === undefined) {
    return null;
  }

  const { comparatorValue, isNegated } = filterValues;

  if (comparatorValue === undefined) {
    return null;
  }

  return ({ value }: GridCellParams) => {
    const comparison =
      `${value}`.toLowerCase().trim().indexOf(`${comparatorValue}`.toLowerCase().trim()) > -1;
    return isNegated ? !comparison : comparison;
  };
};
