import {
  NegatableVLifeStatusListFilters,
  TypedGridFilterItem,
} from '~/components/DataGrid/filters/types';
import { GetApplyFilterFnLegacy, GridCellParams } from '@mui/x-data-grid-pro';
import { VLifeStatus } from '~/__generated-asset__/graphql';

export const getVLifeStatusesContainsFilter: GetApplyFilterFnLegacy = ({
  value: filterValues,
}: TypedGridFilterItem<NegatableVLifeStatusListFilters>) => {
  if (filterValues === undefined) {
    return null;
  }

  const { comparatorValues, isNegated } = filterValues;

  if (!comparatorValues?.length) {
    return null;
  }

  return ({ value }: GridCellParams) => {
    console.log({ comparatorValues, value });

    const comparison = comparatorValues.includes(value as VLifeStatus);
    return isNegated ? !comparison : comparison;
  };
};
