import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { DateRangeCard } from './DateRangeCard';
import { DateRange, DateStringRange } from '~/common/types';
import { useTranslation } from 'react-i18next';
import { GainsAttritionChart } from '~/pages/Dashboard/charts/GainsAttritionChart';
import { useGainAttritionGraph } from '~/pages/Dashboard/useGainAttritionGraph';
import { TickerWidget } from '~/pages/Dashboard/components/TickerWidget';
import { LicenceInsightsTable } from '~/pages/LicenceInsights/LicenceInsightsTable';
import { getCurrentFinancialYearDateRange } from '~/pages/Dashboard/date-utils';

export const LicenceInsightsPage = () => {
  const { t } = useTranslation('licence-insights');
  const [evaluationPeriod, setEvaluationPeriod] = useState<DateRange>(
    getCurrentFinancialYearDateRange()
  );

  const {
    data,
    loading: loadingGainsAttrition,
    xAxis,
    gainCount,
    attritionCount,
  } = useGainAttritionGraph(evaluationPeriod);

  const updatePeriod = useCallback(
    (values: DateStringRange) => {
      if (!values.startDate || !values.endDate) {
        return;
      }

      if (isNaN(Date.parse(values.startDate)) || isNaN(Date.parse(values.endDate))) {
        return;
      }

      const startDate = new Date(values.startDate);
      const endDate = new Date(values.endDate);

      if (startDate > endDate) {
        return;
      }

      if (
        startDate.getTime() === evaluationPeriod?.startDate.getTime() &&
        endDate.getTime() === evaluationPeriod?.endDate.getTime()
      ) {
        return;
      }
      setEvaluationPeriod({ startDate: startDate, endDate: endDate });
    },
    [evaluationPeriod?.endDate, evaluationPeriod?.startDate]
  );

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gap: '1rem',
          gridTemplateColumns: { sm: '1fr', md: 'repeat(6, 1fr)' },
          gridTemplateRows: 'auto',
          marginBottom: '1rem',
        }}
      >
        <DateRangeCard setValues={updatePeriod} defaultValues={evaluationPeriod} />
        <TickerWidget
          loading={loadingGainsAttrition}
          title={t('gains-attrition')}
          sx={{
            gridColumnEnd: {
              sm: 'span 6',
              md: 'span 2',
            },
            placeSelf: 'stretch',
          }}
          gainCount={gainCount}
          attritionCount={attritionCount}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gap: '1rem',
          gridTemplateColumns: { sm: '1fr', md: 'repeat(6, 1fr)' },
          gridTemplateRows: 'auto',
          marginBottom: '1rem',
        }}
      >
        <GainsAttritionChart
          data={data}
          loading={loadingGainsAttrition}
          title={t('gains-attrition-data', {
            startDate: evaluationPeriod.startDate.toLocaleDateString(),
            endDate: evaluationPeriod.endDate.toLocaleDateString(),
          })}
          xAxis={xAxis}
        />
      </Box>
      <Box>
        <LicenceInsightsTable evaluationPeriod={evaluationPeriod} />
      </Box>
    </>
  );
};
