import { VLifeStatus } from '~/__generated-asset__/graphql';

export const isLicenceActive = (vLifeStatus?: VLifeStatus): boolean => {
  if (!vLifeStatus) {
    return false;
  }

  return [
    VLifeStatus.ActivePaid,
    VLifeStatus.ActiveFoc,
    VLifeStatus.ActiveTrial,
    VLifeStatus.ActiveNonRefundable,
  ].includes(vLifeStatus);
};

interface ObjectWithStartDate {
  startDate?: string | Date | null;
  endDate?: string | Date | null;
}

export const sortByStartDate = (a: ObjectWithStartDate, b: ObjectWithStartDate) => {
  if (!a.startDate) {
    return -1;
  }

  if (!b.startDate) {
    return 1;
  }

  return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
};
