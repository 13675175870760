import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetChannelsWithInstalledViperAssetPeriods } from '~/queries-asset/channel';
import { dataGridRowMapper } from '~/components/DataGrid/utils';
import { useCustomerAssetColumns } from './useCustomerAssetColumns';
import { CustomerAssetRow } from '~/pages/CustomerAssets/types';
import { useEnrichCustomerAssets } from '~/pages/CustomerAssets/useEnrichCustomerAssets';

export const useCustomerAssets = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useGetChannelsWithInstalledViperAssetPeriods(
    '1970-01-01',
    '2050-01-01'
  );
  const [closeError, setCloseError] = useState(false);
  const { columns } = useCustomerAssetColumns();
  const rows = useMemo(() => {
    // Map to grid columns
    return data?.channels.map(dataGridRowMapper<CustomerAssetRow>(columns)) ?? [];
  }, [columns, data?.channels]);

  const { enrichedRows } = useEnrichCustomerAssets(rows);

  return {
    columns,
    rows: enrichedRows,
    loading,
    error,
    closeError,
    setCloseError,
    navigate,
  };
};
