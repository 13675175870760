import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditEntityDialog } from '../CreateEditEntityDialog/CreateEditEntityDialog';
import { getMaintenanceLogCreateSchema } from '~/pages/schemas/create/maintenanceLog/maintenanceLogCreateSchema';
import {
  useCreateMaintenanceLog,
  useGetMaintenanceLogOptions,
} from '~/queries-asset/viper-asset';
import { formatDate } from '~/utils/date-utils';

export const CreateMaintenanceLogDialog: React.FC<{
  id: string;
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose, id }) => {
  const { t } = useTranslation('viper-asset');

  return (
    <CreateEditEntityDialog
      open={open}
      onClose={onClose}
      title={t('create-maintenance-log')}
      errorMessage={t(`error-creating-maintenance-log`)}
      successMessage={(log) => t('maintenance-log-created-successfully', { log })}
      useGetOptions={useGetMaintenanceLogOptions}
      useMutate={useCreateMaintenanceLog}
      useMutateOptions={{ id }}
      getSchema={({ t, options }) =>
        getMaintenanceLogCreateSchema({ t, options }).fields[0].fields[0].fields
      }
      handleMutate={async (create, values) => {
        await create({
          variables: {
            maintenanceLogInput: {
              viperAssetId: id,
              maintenanceStartDate: values.maintenanceStartDate as string,
              maintenanceEndDate: values.maintenanceEndDate as string,
              maintenanceTypeId: values.maintenanceTypeId as string,
              personId: values.personId as string,
              outcomeOfMaintenance: values.outcomeOfMaintenance as string,
            },
          },
        });
        return formatDate(values.maintenanceEndDate as string) as string;
      }}
      validate={({ maintenanceEndDate, maintenanceStartDate }) => {
        if (
          maintenanceEndDate &&
          maintenanceStartDate &&
          maintenanceEndDate < maintenanceStartDate
        ) {
          return {
            maintenanceEndDate: t('end-date-must-be-after-start-date'),
          };
        }
        return undefined;
      }}
    />
  );
};
