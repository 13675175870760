import React from 'react';
import { Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

export interface WarningLink {
  id: string | number;
  warningMessage: string;
  link?: {
    text: string;
    url: string;
  };
}
export const WarningLink = ({ warningMessage, link }: WarningLink) => {
  return (
    <span>
      {warningMessage}&nbsp;
      {link && (
        <Link component={NavLink} to={link.url}>
          {link.text}
        </Link>
      )}
    </span>
  );
};
