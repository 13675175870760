import {
  ButtonProps,
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  TableCellProps,
  Theme,
} from '@mui/material';
import colors from './colors';

enum TextTransform {
  NONE = 'none',
}

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      light: colors.darkblue[300],
      main: colors.darkblue[500],
      dark: colors.darkblue[700],
      contrastText: colors.white,
    },
    neutral: {
      light: colors.grey[300],
      main: colors.grey[400],
      dark: colors.grey[500],
      contrastText: colors.black,
    },
    secondary: {
      light: colors.yellow[300],
      main: colors.yellow[500],
      dark: colors.yellow[700],
      contrastText: colors.darkblue[500],
    },
    success: {
      light: colors.green[300],
      main: colors.green[500],
      dark: colors.green[700],
      contrastText: colors.white,
    },
    error: {
      light: colors.red[300],
      main: colors.red[500],
      dark: colors.red[700],
      contrastText: colors.white,
    },
    warning: {
      light: colors.orange[300],
      main: colors.orange[500],
      dark: colors.orange[700],
      contrastText: colors.white,
    },
    info: {
      light: colors.blue[300],
      main: colors.blue[500],
      dark: colors.blue[700],
      contrastText: colors.white,
    },
    highlight: {
      light: colors.lightblue[50],
      main: colors.lightblue[100],
      dark: colors.lightblue[600],
      contrastText: colors.black,
    },
    divider: colors.grey[500],
    grey: {
      '300': colors.grey[300],
      '400': colors.grey[400],
      '500': colors.grey[500],
      '600': colors.grey[600],
      '700': colors.grey[700],
    },
    text: {
      primary: colors.black,
      secondary: colors.grey[700],
    },
  },
  typography: {
    fontSize: 14,
    td: {
      color: colors.grey[700],
    },
    h3: {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: colors.white,
        color: colors.white,
      },
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
          transform: 'translateX(16px)',
          color: colors.white,
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: `1px solid ${colors.grey[500]}`,
        backgroundColor: colors.grey[300],
        opacity: 1,
        transition:
          'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: colors.darkblue[500],
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTabBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState }: { theme: Theme; ownerState: ButtonProps }) => ({
          boxShadow: 'none',
          borderRadius: '4px',
          ...(ownerState.size === 'medium' && {
            padding: '8px 32px',
          }),
          ...(ownerState.size === 'small' && {
            padding: '4px 16px',
          }),
          textTransform: TextTransform.NONE,
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiButton-containedPrimary:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
          '&.MuiButton-containedSuccess:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
        }),
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: colors.grey[300],
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecorationColor: colors.darkblue[500],
          color: colors.darkblue[500],
          cursor: 'pointer',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          border: 0,
          '& .MuiDataGrid-row.Mui-hovered': {
            backgroundColor: colors.lightblue[100],
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: 52,
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.darkblue[500],
          },
          '& .MuiDataGrid-columnHeader': {
            padding: '0 8px',
            '&:first-of-type': {
              paddingLeft: '24px',
            },
            '&:last-of-type': {
              paddingRight: '24px',
            },
          },
          '& .MuiDataGrid-columnHeadersInner': {
            color: colors.white,
            backgroundColor: colors.darkblue[500],
          },
          '& .MuiDataGrid-withBorderColor': {
            borderColor: colors.grey[400],
          },
          '& .MuiDataGrid-iconButtonContainer svg': {
            fill: colors.white,
          },
          '& .MuiDataGrid-menuIcon svg': {
            fill: colors.white,
            opacity: 0.5,
          },
          '& .MuiDataGrid-iconSeparator': {
            fill: colors.white,
            opacity: 0.1,
          },
          '& .MuiDataGrid-cell:focus': {
            outline: `solid ${colors.blue[100]} 1px`,
          },
          '& .MuiDataGrid-cellContent': {
            color: colors.grey[700],
          },
          '& .MuiDataGrid-footerContainer': {
            padding: '0.25rem 1.5rem',
            borderTop: `1px solid ${colors.grey[400]}`,
            borderRadius: '0 0 0.5rem 0.5rem',
            backgroundColor: colors.grey[300],
          },
          '& .MuiDataGrid-cell': {
            padding: '0 8px',
            '&:first-of-type': {
              paddingLeft: '1.5rem',
            },
            '&:last-of-type': {
              paddingRight: '1.5rem',
            },
          },
          '& .MuiCheckbox-root': {
            paddingLeft: 0,
            paddingRight: 0,
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            paddingLeft: '5px',
            '& input[type=checkbox]+label::after': {
              color: 'white',
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          display: 'flex',
          flex: '1 1 100%',
          color: colors.grey[700],
          '& .MuiToolbar-root': {
            flex: '1 1 100%',
            justifyContent: 'space-between',
            paddingLeft: 0,
          },
          '& .MuiTablePagination-spacer': {
            flex: '0 0 0',
          },
          '& .MuiTablePagination-toolbar': {
            justifyContent: 'flex-start',
            '& .MuiTablePagination-pagination': {
              marginLeft: 'auto',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: TableCellProps }) => ({
          verticalAlign: 'top',
          color: colors.grey[700],
          ...(ownerState.component === 'th' && {
            color: colors.black,
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: '8px',
          backgroundColor: colors.white,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: colors.grey[500],
              borderRadius: '6px',
            },
            '&:hover fieldset': {
              borderColor: colors.grey[700],
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.grey[500],
              boxShadow: `0 0 0 3px ${colors.lightblue}`,
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input:focus': {
            backgroundColor: colors.white,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          filter:
            'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: 12,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 16,
          backgroundColor: colors.grey[300],
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: { paddingTop: '16px !important' },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: colors.grey[300],
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.grey[500]}`,

          '& .MuiTabs-indicator': {
            backgroundColor: colors.darkblue[300],
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderBottom: '2px solid transparent',
          textTransform: TextTransform.NONE,
          color: colors.grey[700],
          fontWeight: 500,

          '&:hover': {
            opacity: 1,
            borderBottom: `2px solid ${colors.grey[400]}`,
          },
          '&.Mui-selected': {
            fontWeight: 500,
            color: colors.darkblue[300],
          },
          '&.Mui-focusVisible': {
            backgroundColor: colors.lightblue[100],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: '2rem 0 2rem 0',
        },
      },
    },
  },
  props: {
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiAppBar: {
      color: 'default',
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 6,
  },
};

declare module '@mui/material/styles' {
  interface Palette {
    neutral: PaletteColor;
    highlight: PaletteColor;
  }

  interface PaletteOptions {
    neutral?: PaletteColorOptions;
    highlight?: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    highlight: true;
  }
}

declare module '@mui/material/TableRow' {
  interface TableRowPropsColorOverrides {
    neutral: true;
    highlight: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    neutral: true;
    highlight: true;
  }
}

export const theme = createTheme(themeOptions);
