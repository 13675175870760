import { useMemo } from 'react';
import { LicenceInsightsRow } from '~/pages/LicenceInsights/types';
import { DateRange } from '~/common/types';
import {
  attritionFallsWithinEvaluationPeriod,
  flaggedForDecommissionFallsWithinEvaluationPeriod,
  gainFallsWithinEvaluationPeriod,
} from '~/pages/LicenceInsights/utils';

export interface FilterCounts {
  gainCount: number;
  attritionCount: number;
  flaggedForDecommissionCount: number;
}

export const useFilterCounts = (
  rows: LicenceInsightsRow[],
  evaluationPeriod: DateRange
): FilterCounts => {
  return useMemo((): FilterCounts => {
    return rows.reduce(
      (counts: FilterCounts, row) => {
        // Gains increased if row has gain flag and start date is within evaluation period
        if (gainFallsWithinEvaluationPeriod(row, evaluationPeriod)) {
          counts.gainCount += 1;
        }
        // Attrition increased if row has attrition flag and end date is within evaluation period
        if (attritionFallsWithinEvaluationPeriod(row, evaluationPeriod)) {
          counts.attritionCount += 1;
        }
        // Flagged for Decommission increased if row has flaggedForDecommission flag
        if (flaggedForDecommissionFallsWithinEvaluationPeriod(row)) {
          counts.flaggedForDecommissionCount += 1;
        }
        return counts;
      },
      {
        gainCount: 0,
        attritionCount: 0,
        flaggedForDecommissionCount: 0,
      }
    );
  }, [evaluationPeriod, rows]);
};
