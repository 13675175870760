import { Schema, componentTypes } from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { extraValidatorTypes } from '~/components/Form/validators';
import { GetChannelQuery } from '~/__generated-asset__/graphql';

interface GetDataEditSchemaProps {
  t: TFunction;
  channel?: GetChannelQuery['channel'];
  editing?: boolean;
}

export function getDataEditSchema({ t, channel }: GetDataEditSchemaProps): Schema {
  return {
    fields: [
      {
        component: componentTypes.DATE_PICKER,
        name: 'latestFileReceived',
        label: t('customer-asset-forms::latest-file-received'),
        helperText: t('customer-asset-forms::latest-file-received-helper'),
        sx: { gridColumnEnd: 'span 2' },
        initialValue: channel?.latestFileReceived,
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        ],
      },
      {
        component: componentTypes.DATE_PICKER,
        name: 'dataDue',
        label: t('customer-asset-forms::data-due'),
        helperText: t('customer-asset-forms::data-due-helper'),
        sx: { gridColumnEnd: 'span 2' },
        initialValue: channel?.dataDue,
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        ],
      },
    ],
  };
}
