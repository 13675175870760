export function formatFileSize(bytes: number) {
  switch (true) {
    case bytes < 1024:
      return bytes + ' bytes';
    case bytes < 1024 * 1024:
      return (bytes / 1024).toFixed(1) + ' KB';
    case bytes < 1024 * 1024 * 1024:
      return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
    default:
      return (bytes / (1024 * 1024 * 1024)).toFixed(1) + ' GB';
  }
}
