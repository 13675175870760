import { useFieldApi, useFormApi, FormSpy } from '@data-driven-forms/react-form-renderer';
import { Box, Typography, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  VLifeReportLogMessage,
  VLifeReportLogMessageCreateInput,
} from '~/__generated-asset__/graphql';
import { SelectEvents } from '~/pages/VLifeReports/SelectEvents';
import { DataGrid } from '~/components/DataGrid';
import { useMessageListColumns } from '~/pages/VLifeReports/useMessageListColumns';

export const MessageList: React.FC<{
  name: string;
  label?: string;
  logs: VLifeReportLogMessage[];
  allLogs: VLifeReportLogMessage[];
}> = ({ name, label, logs, allLogs }) => {
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation('message-list');
  const { input } = useFieldApi<VLifeReportLogMessage[]>({ name });
  const { change } = useFormApi();
  const { columns } = useMessageListColumns();

  const data = Array.isArray(input.value)
    ? input.value.map(({ message, date }: VLifeReportLogMessage, index: number) => {
        return {
          id: index,
          message,
          date,
        };
      })
    : [];

  const handleLogsChange = (updatedLogs: VLifeReportLogMessage[]): void | Promise<void> => {
    const mappedLogs: VLifeReportLogMessageCreateInput[] = updatedLogs.map(
      ({ message, date }): VLifeReportLogMessageCreateInput => ({
        message,
        date,
      })
    );
    change(name, mappedLogs);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormSpy subscription={{ values: true, validating: true }}>
      {() => (
        <>
          <Box sx={{ gridColumnEnd: 'span 2' }}>
            <Box
              display={'flex'}
              justifyContent={label ? 'space-between' : 'flex-end'}
              alignItems={'center'}
              sx={{ mb: 3 }}
            >
              {label && (
                <Typography variant='h3' component='h3'>
                  {label}
                </Typography>
              )}
              <Button variant='contained' color='secondary' onClick={() => setOpen(true)}>
                {t('select-messages::select-messages')}
              </Button>
            </Box>
            {data.length === 0 ? (
              <Typography>{t('no-data', { eventCount: allLogs.length })}</Typography>
            ) : (
              <DataGrid
                columns={columns}
                rows={data}
                columnVisibilityModel={{
                  id: false,
                }}
                disableDensitySelector={true}
                disableColumnSelector={true}
              />
            )}
          </Box>
          <SelectEvents
            open={open}
            selectedLogs={logs}
            allLogs={allLogs}
            onClose={handleClose}
            onConfirm={handleLogsChange}
          />
        </>
      )}
    </FormSpy>
  );
};
