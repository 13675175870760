import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { styled, Theme } from '@mui/material';
import { UsePaginationItem } from '@mui/material/usePagination/usePagination';
import { transientOptions } from '~/themes/utils';

export const StyledTableCell = styled(
  TableCell,
  transientOptions
)<{ $alignRight?: boolean; $muted?: boolean }>(({ $alignRight, $muted, theme }) => ({
  padding: '1rem 1.5rem',
  textAlign: $alignRight ? 'right' : undefined,
  [`&.${tableCellClasses.head}`]: $muted
    ? {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.secondary,
      }
    : {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    borderBottomColor: theme.palette.divider,
  },
}));

export const StyledTableRow = styled(
  TableRow,
  transientOptions
)<{ $clickable: boolean }>(({ $clickable, theme }) => ({
  '&:hover': {
    backgroundColor: $clickable ? theme.palette.highlight?.main : 'inherit',
    cursor: $clickable ? 'pointer' : 'default',
  },
}));

export const Footer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.text.secondary,
  display: 'flex',
  fontSize: '14px',
  padding: '0.75rem 1.5rem',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const PageButton = styled(Button)<{ selected?: boolean }>(
  ({ theme, selected }: { theme: Theme; selected?: UsePaginationItem['selected'] }) => [
    {
      color: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      backgroundColor: selected ? theme.palette.grey[300] : theme.palette.common.white,
      fontWeight: '400',
      borderRadius: '6px',
      maxWidth: '50px',
    },
    {
      '&:hover': {
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.grey[300],
      },
    },
  ]
);

export const GridPageButton = styled(Button)<{ selected?: boolean }>(
  ({ theme, selected }: { theme: Theme; selected?: UsePaginationItem['selected'] }) => [
    {
      color: theme.palette.text.secondary,
      backgroundColor: selected ? theme.palette.grey[300] : theme.palette.common.white,
      fontWeight: '400',
      borderRadius: '6px',
      maxWidth: '50px',
      border: `1px solid ${theme.palette.divider}`,
      minHeight: '43px',
    },
    {
      '&:hover': {
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.grey[300],
      },
    },
  ]
);
