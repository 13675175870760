import React from 'react';
import { IconButton, Typography, useTheme } from '@mui/material';
import { ModeComment } from '@mui/icons-material';

export const CellComments: React.FC<{
  onClick: () => void;
  comments: Record<string, unknown>[];
}> = ({ comments, onClick }) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={onClick}
      sx={{
        marginTop: '-0.625rem',
        marginBottom: '-0.625rem',
        position: 'relative',
      }}
      aria-label='Comments'
    >
      <ModeComment sx={{ color: theme.palette.primary.main, fontSize: '1.5rem' }} />
      <Typography
        fontSize='0.625rem'
        fontWeight='bold'
        sx={{
          color: theme.palette.primary.contrastText,
          position: 'absolute',
          left: comments.length > 9 ? '0.875rem' : '1.0625rem',
          top: 12,
        }}
      >
        {comments.length}
      </Typography>
    </IconButton>
  );
};
