import React from 'react';
import { AnyObject } from '@data-driven-forms/react-form-renderer';
import { WarningBar } from '../../WarningBar';

export type WithAnnotationProps = {
  annotation?: {
    text: string;
  };
} & AnyObject;

export function withAnnotation(Component: React.ComponentType) {
  const ComponentWithAnnotation = (props: WithAnnotationProps) => {
    const { annotation, ...rest } = props;
    if (annotation) {
      return (
        <div>
          <Component {...rest} />
          <WarningBar message={annotation.text} />
        </div>
      );
    } else {
      return <Component {...rest} />;
    }
  };
  return ComponentWithAnnotation;
}
