// This is a crude conversion from JS to TS, so allow 'any' at the moment
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PropTypes from 'prop-types';

import DDFSelect from '@data-driven-forms/common/select';
import parseInternalValue from '@data-driven-forms/common/select/parse-internal-value';
import { FormSpy, useFieldApi, useFormApi } from '@data-driven-forms/react-form-renderer';
import { Box, CircularProgress, Autocomplete, Stack, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { validationError } from './validation';
import MuiTextField from '@mui/material/TextField';
import { getSelectOption } from '~/pages/schemas/tools';
import { FormLabel } from './FormLabel';

/**
 * Returns label of selected option
 * @param {Object|Array} option currently selected option
 * @param {Array<Object>} options all options avaiable
 * @returns {String}
 */
export const getOptionLabel = (option: any, options: any) => {
  if (typeof option === 'undefined') {
    return '';
  }

  if (option === '') {
    return '';
  }

  if (Array.isArray(option) && option.length === 0) {
    return '';
  }

  if (typeof option === 'object') {
    return option.label;
  }

  const item = options.find(({ value }: any) => value === option);
  return item?.label || '';
};

/**
 * Function that creates correct DDF select value format
 * @param {Object|Array} option currently selected values
 * @param {Boolean} isMulti multiple select flag
 * @returns {Object|Array<Object>}
 */
export const createValue = (option: any, isMulti: any) => {
  if (isMulti) {
    return Array.isArray(option)
      ? option.map((item) => (typeof item === 'object' ? item : { value: item }))
      : option;
  }

  return option;
};

const InternalSelect = ({
  value,
  options,
  helperText,
  validateOnMount,
  meta,
  isSearchable,
  description,
  isMulti,
  placeholder,
  onInputChange,
  isFetching,
  noOptionsMessage,
  hideSelectedOptions,
  required,
  onChange,
  onFocus,
  onBlur,
  TextFieldProps: { inputProps: textFieldInputProps, ...TextFieldProps },
  inputProps,
  isClearable,
  isDisabled,
  isInvalid: invalid,
  classNamePrefix,
  closeMenuOnSelect,
  FormFieldGridProps,
  sortAlphabetical,
  ...rest
}: any) => {
  const internalValue = parseInternalValue(value, isMulti);

  sortAlphabetical &&
    options.sort((a: any, b: any) =>
      a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })
    );

  return (
    <Autocomplete
      filterSelectedOptions={hideSelectedOptions}
      disabled={isDisabled}
      disableClearable={isClearable}
      popupIcon={
        isFetching ? <CircularProgress size={20} color='inherit' /> : <ExpandMoreIcon />
      }
      size='small'
      fullWidth
      classnameprefix={classNamePrefix}
      closemenuonselect={closeMenuOnSelect ? 'true' : 'false'}
      formfieldgridprops={FormFieldGridProps}
      {...rest}
      renderInput={(params) => (
        <MuiTextField
          onFocus={onFocus}
          onBlur={onBlur}
          {...params}
          required={required}
          error={!!invalid}
          helperText={
            invalid ||
            ((meta.touched || validateOnMount) && meta.warning) ||
            helperText ||
            description
          }
          FormHelperTextProps={{
            sx: {
              fontSize: 14,
              mx: 0,
            },
          }}
          label={null}
          margin='dense'
          {...TextFieldProps}
          inputProps={{
            ...params.inputProps,
            ...textFieldInputProps,
            ...inputProps,
            readOnly: !isSearchable,
            placeholder:
              !internalValue || internalValue.length === 0 ? placeholder : undefined,
          }}
        />
      )}
      noOptionsText={noOptionsMessage()}
      onInputChange={(_event, value) => onInputChange(value)}
      options={options}
      multiple={isMulti}
      getOptionLabel={(option) => getOptionLabel(option, options)}
      value={typeof internalValue === 'undefined' ? null : internalValue}
      onChange={(_event, option) => onChange(createValue(option, isMulti))}
      loading={isFetching}
      isOptionEqualToValue={(option: any, value) => option.value === value}
    />
  );
};

InternalSelect.propTypes = {
  placeholder: PropTypes.node,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any.isRequired, label: PropTypes.node.isRequired })
  ).isRequired,
  description: PropTypes.node,
  FormFieldGridProps: PropTypes.object,
  value: PropTypes.any,
  isClearable: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  isMulti: PropTypes.bool,
  isInvalid: PropTypes.bool,
  loadingMessage: PropTypes.node,
  onInputChange: PropTypes.func,
  isFetching: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  closeMenuOnSelect: PropTypes.bool,
  isRequiredForReporting: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  hideSelectedOptions: PropTypes.bool,
  required: PropTypes.bool,
  sortAlphabetical: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  TextFieldProps: PropTypes.object,
  inputProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  meta: PropTypes.object,
  actionLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    })
  ),
};

InternalSelect.defaultProps = {
  placeholder: 'Please choose',
  FormFieldGridProps: {},
  TextFieldProps: {},
  inputProps: {},
  loadingText: 'Loading...',
};

export const Select = (props: any) => {
  const {
    input,
    isRequired,
    isDisabled,
    isReadOnly,
    disabled,
    multiple,
    isMulti,
    isClearable,
    disableClearable,
    loadingMessage,
    loadingText,
    noOptionsMessage,
    noOptionsText,
    validateOnMount,
    isRequiredForReporting,
    label,
    actionLinks,
    sx,
    ...rest
  } = useFieldApi({
    ...props,
    resolveProps: (...args: any) => {
      const propsArg = args[0];
      // eslint-disable-next-line react/prop-types
      const resolvedProps = props.resolveProps?.apply(this, args) || propsArg;
      const initialValueProp = resolvedProps?.initialValue || propsArg.initialValue;
      const initialValue = Array.isArray(initialValueProp)
        ? initialValueProp
        : typeof initialValueProp === 'object'
        ? initialValueProp?.id
        : initialValueProp;
      return {
        ...resolvedProps,
        initialValue,
        options: props.useStandardOptions
          ? props.options
          : (resolvedProps?.options || propsArg.options)?.map(getSelectOption),
      };
    },
  });

  const formApi = useFormApi();

  const invalid = validationError(rest?.meta, validateOnMount);

  return (
    <Box sx={sx}>
      <Stack direction='row' justifyContent={'space-between'}>
        <FormLabel
          sx={{ color: (theme) => (invalid ? theme.palette.error.main : 'inherit') }}
          htmlFor={input.name}
          isRequiredForReporting={isRequiredForReporting}
        >
          {isRequired && (
            <sup style={{ position: 'absolute', top: -3, left: -6 }}>*&nbsp;</sup>
          )}
          {label}
        </FormLabel>

        {actionLinks && (
          <Stack direction='row' spacing={1}>
            {actionLinks.map(({ label, action, disabled }: any) => {
              if (typeof disabled === 'function') {
                disabled = disabled(input.value, formApi.getState().values);
              }
              return (
                <Link
                  component='button'
                  type='button'
                  onClick={(event) => {
                    event.preventDefault();
                    !disabled && action(event, input.value, formApi.getState().values);
                  }}
                  key={label}
                  sx={{
                    cursor: disabled ? 'default' : 'pointer',
                    fontSize: 14,
                    textDecorationColor: (theme) =>
                      disabled ? theme.palette.grey[700] : theme.palette.primary.main,
                    color: (theme) =>
                      disabled ? theme.palette.grey[700] : theme.palette.primary.main,
                    '&:hover': {
                      textDecorationColor: (theme) =>
                        disabled ? theme.palette.grey[700] : theme.palette.primary.light,
                      color: (theme) =>
                        disabled ? theme.palette.grey[700] : theme.palette.primary.light,
                    },
                  }}
                  disabled={disabled}
                >
                  {label}
                </Link>
              );
            })}
          </Stack>
        )}
      </Stack>
      <DDFSelect
        {...input}
        // @ts-expect-error id is required
        id={input.name}
        isMulti={multiple || isMulti}
        required={isRequired}
        disabled={isDisabled || isReadOnly || disabled}
        disableClearable={!isClearable || disableClearable}
        loadingText={loadingMessage || loadingText}
        noOptionsMessage={noOptionsMessage || noOptionsText}
        isInvalid={invalid}
        {...rest}
        SelectComponent={InternalSelect}
      />
    </Box>
  );
};

export const EnhancedSelect = (props: any) => (
  <FormSpy subscription={{ values: true, validating: true }}>
    {() => <Select size='small' clearedValue={null} {...props} />}
  </FormSpy>
);
