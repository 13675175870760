export const formatDateYYYYMMDD = (date: Date | string | undefined) => {
  const parsedDate = parseDateOrUndefined(date);
  if (!parsedDate) {
    return '';
  }
  return new Date(parsedDate).toISOString().split('T')[0];
};

export const parseDateOrUndefined = (
  value: Date | string | number | undefined | null
): Date | undefined => {
  const timestamp = Date.parse(`${value}`);
  if (Number.isNaN(timestamp)) {
    return undefined;
  }
  return new Date(timestamp);
};

export const parseNumberOrUndefined = (
  value: string | number | undefined
): number | undefined => {
  if (value === undefined || value === null || value.toString().length === 0) {
    return undefined;
  }
  const parsedValue = Number(value);
  if (isNaN(parsedValue)) {
    return undefined;
  }
  return parsedValue;
};
