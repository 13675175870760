import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog } from '~/components/Dialog';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { EnableBlocker } from '~/components/Blocker';
import { UploadIcon } from '~/components/upload/UploadIcon';
import { EditPane } from '../Form/EditPane';
import { UploadDialogProps } from './UploadDialog';
import { FormDialogProps } from '../Form/FormDialog';
import { FormSpyWithBlocker, SpyHandlerProps } from '../Form/FormSpyWithBlocker';

export const UploadWithFormDialog: React.FC<
  UploadDialogProps & Omit<FormDialogProps, 'open' | 'onCancel' | 'onSave'>
> = ({
  open,
  onUpload,
  onCancel,
  fields,
  disabled,
  title,
  validate,
  afterContent,
  accept,
  validationError,
  extension,
}) => {
  const { t } = useTranslation('upload-with-form-dialog');
  const theme = useTheme();

  const [fileList, setFileList] = useState<File[]>([]);
  const [spyProps, setSpyProps] = useState<SpyHandlerProps>();

  useEffect(() => {
    if (!open) {
      spyProps?.form?.reset();
      setSpyProps(undefined);
    }
  }, [open, spyProps?.form]);

  useEffect(() => {
    if (open) {
      setFileList([]);
    }
  }, [open]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    disabled: fileList.length > 0,
    accept,
    validator: (file) => {
      if (!file.name) {
        console.log('unknown');
        return null;
      }
      if (typeof extension === 'string' && !file.name?.endsWith(extension)) {
        return validationError ?? null;
      }

      return null;
    },
    onDropAccepted(files) {
      const newFileList = fileList.slice();
      files.forEach((file) => {
        if (!fileList.find((existingFile) => existingFile.name === file.name)) {
          newFileList.push(file);
        }
      });
      setFileList(newFileList);
    },
  });

  const form = useMemo(
    () => (
      <>
        <EditPane
          validate={validate}
          disableFields={disabled}
          fields={fields}
          container={({ children }) => {
            return (
              <FormSpyWithBlocker onCancel={onCancel}>
                {(spyProps) => {
                  setSpyProps(spyProps);
                  return children;
                }}
              </FormSpyWithBlocker>
            );
          }}
        />
        {afterContent}
      </>
    ),
    [validate, disabled, fields, onCancel, afterContent]
  );

  return (
    <>
      <EnableBlocker enabled={open && fileList.length > 0} />
      <Dialog
        open={open}
        confirmDisabled={
          spyProps?.invalid ||
          spyProps?.submitting ||
          fileList.length === 0 ||
          spyProps?.pristine
        }
        onConfirm={() => {
          onUpload(fileList, spyProps?.values);
        }}
        onClose={(confirm) => {
          if (!confirm) {
            onCancel();
          }
        }}
        title={title}
        confirmText={t('confirm-button')}
        cancelText={t('cancel-button')}
        content={
          <section>
            <Box sx={{ mb: 4, ml: -0.5 }}>{form}</Box>
            <Box
              {...getRootProps()}
              sx={{
                margin: '2px',
                backgroundImage: isDragActive
                  ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='1' y='1' width='calc(100%25 - 3px)' height='98%25' fill='${encodeURIComponent(
                      theme.palette.highlight.light
                    )}' rx='4' ry='4' stroke='${encodeURIComponent(
                      theme.palette.highlight.dark
                    )}' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
                  : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='1' y='1' width='calc(100%25 - 3px)' height='98%25' fill='none' rx='4' ry='4' stroke='${encodeURIComponent(
                      theme.palette.grey[500]
                    )}' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                padding: '1.5rem 9rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                marginBottom: '1rem',
              }}
            >
              <input {...getInputProps()} />
              <UploadIcon />
              <Typography fontSize='16px' padding={1}>
                <Trans
                  i18nKey='click-to-upload'
                  t={t}
                  components={{
                    u: <u />,
                  }}
                />
              </Typography>
              <Typography fontSize='14px' color={theme.palette.grey[700]}>
                {t('file-spec')}
              </Typography>
            </Box>
            <List>
              {fileList.map((file, index) => (
                <Fragment key={file.name}>
                  {index > 0 ? <Divider /> : null}
                  <ListItem key={file.name} sx={{ minHeight: '2.5rem' }}>
                    <ListItemAvatar>
                      <UploadIcon />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography fontSize='14px' color={theme.palette.grey[700]}>
                        {file.name}
                      </Typography>
                    </ListItemText>
                    <IconButton
                      aria-label={t('remove-file')}
                      onClick={() => {
                        setFileList([
                          ...fileList.slice(0, index),
                          ...fileList.slice(index + 1),
                        ]);
                      }}
                    >
                      <Close
                        sx={{
                          color: (theme) => theme.palette.neutral.contrastText,
                          fontSize: 18,
                        }}
                      />
                    </IconButton>
                  </ListItem>
                </Fragment>
              ))}
            </List>
          </section>
        }
      />
    </>
  );
};
