import { useCallback, useEffect, useState } from 'react';

interface IdentifiableRow {
  id: string | number;
}

interface UseEnrichRowsResponse<
  UnenrichedRowType extends IdentifiableRow,
  EnrichedRowType extends IdentifiableRow
> {
  enrichedRows: (UnenrichedRowType | EnrichedRowType)[];
  loading: boolean;
}

export const useEnrichRows = <
  UnenrichedRowType extends IdentifiableRow,
  EnrichedRowType extends IdentifiableRow
>(
  rows: UnenrichedRowType[],
  enrichDataQuery: (
    rowData: (UnenrichedRowType | EnrichedRowType)[]
  ) => Promise<EnrichedRowType[]>
): UseEnrichRowsResponse<UnenrichedRowType, EnrichedRowType> => {
  // Cache rows
  const [loading, setLoading] = useState(false);
  const [enriched, setEnriched] = useState(false);
  const [cachedRows, setCachedRows] = useState<(UnenrichedRowType | EnrichedRowType)[]>([
    ...rows,
  ]);

  const enrichRows = useCallback(() => {
    setLoading(true);

    enrichDataQuery(cachedRows).then((enrichedRows) => {
      setCachedRows(enrichedRows);
      setEnriched(true);
      setLoading(false);
    });
  }, [setEnriched, enrichDataQuery, cachedRows, setLoading]);

  useEffect(() => {
    if (!loading && !enriched && cachedRows.length > 0) {
      enrichRows();
    }
  }, [loading, enriched, cachedRows, enrichRows]);

  useEffect(() => {
    if (rows.length > 0 && cachedRows.length === 0) {
      setCachedRows(rows);
    }
  }, [cachedRows, rows]);

  return {
    enrichedRows: cachedRows,
    loading,
  };
};
