import { GetApplyFilterFnLegacy, GridCellParams } from '@mui/x-data-grid-pro';
import { TypedGridFilterItem } from '~/components/DataGrid/filters/types';
import { NegatableVLifeLicenceChangeRangeFilters } from '~/components/DataGrid/filters/custom/v-life-licences/types';
import { EnrichedCustomerAssetRow } from '~/pages/CustomerAssets/types';
import { sortByStartDate } from '~/pages/CustomerAssets/utils';
import { filterStatusWindowsOutsideFilterDateRange } from '~/components/DataGrid/filters/custom/v-life-licences/filter-functions/utils';
import { VLifeTimelinePeriod } from '~/__generated-asset__/graphql';

export const getBackToBackStatusChangeFilter: GetApplyFilterFnLegacy = ({
  value: filterValues,
}: TypedGridFilterItem<NegatableVLifeLicenceChangeRangeFilters>) => {
  if (filterValues === undefined) {
    return null;
  }

  const { fromStatuses, toStatuses, startDate, endDate, isNegated } = filterValues;

  if (!fromStatuses.length || !toStatuses.length || !startDate || !endDate) {
    // Some filter values not set - don't filter out anything
    return null;
  }

  return ({
    value: vLifeTimelinePeriods,
  }: GridCellParams<EnrichedCustomerAssetRow, VLifeTimelinePeriod[]>) => {
    // Immediately filter out rows with no configs
    if (!vLifeTimelinePeriods) {
      return isNegated;
    }

    // Filter out licences that don't overlap with filter date window
    const filteredStatusWindows = filterStatusWindowsOutsideFilterDateRange(
      vLifeTimelinePeriods,
      startDate,
      endDate
    );

    // Sort by date ascending
    filteredStatusWindows.sort(sortByStartDate);

    // Find back to back transition
    const transitionFound = filteredStatusWindows.find(
      (vLifeStatusWindow, currentIndex, statusWindows) => {
        const nextStatusWindow = statusWindows[currentIndex + 1];
        return (
          nextStatusWindow &&
          fromStatuses.includes(vLifeStatusWindow.status) &&
          toStatuses.includes(nextStatusWindow.status)
        );
      }
    );

    const keepItemIn = !!transitionFound;

    // Apply negation if present
    return isNegated ? !keepItemIn : keepItemIn;
  };
};
