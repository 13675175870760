import React, { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useNegatableFilterOperators } from '~/components/DataGrid/filters/filter-operators';
import { ReportValidationWarningIcon } from '~/pages/VLifeReports/ReportValidationWarningIcon';
import { PendingVLifeReportRow } from '~/pages/VLifeReports/types';
import { dateFormatter, formatBoolean } from '~/components/DataGrid/value-formatters';

const baseColumn: Partial<GridColDef> = {
  minWidth: 200,
  maxWidth: 500,
  flex: 1,
};
export function usePendingVLifeReportColumns() {
  const { t } = useTranslation('v-life-reports');
  const {
    negatableNumberFilterOperators,
    negatableDateFilterOperators,
    negatableStringFilterOperators,
    negatableBooleanFilterOperators,
  } = useNegatableFilterOperators();

  const columns: GridColDef<PendingVLifeReportRow>[] = useMemo(
    () => [
      {
        ...baseColumn,
        field: 'id',
        headerName: t('id'),
        minWidth: 50,
        maxWidth: 100,
        flex: 0.5,
        filterOperators: negatableNumberFilterOperators,
      },
      {
        ...baseColumn,
        field: 'channel.customer.name',
        headerName: t('customer'),
        minWidth: 350,
        maxWidth: 450,
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'channel.operator.name',
        headerName: t('operator'),
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'channel.field.name',
        headerName: t('field'),
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'channel.platform.name',
        headerName: t('platform'),
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'channel.name',
        headerName: t('channel'),
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'latestReportDate',
        headerName: t('latestReportDate'),
        type: 'date',
        valueFormatter: dateFormatter,
        filterOperators: negatableDateFilterOperators,
      },
      {
        ...baseColumn,
        field: 'daysOfVLifeCoverageToDate',
        headerName: t('vlife-coverage-days'),
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'vLifeConfig.startDate',
        headerName: t('vlife-start-date'),
        type: 'date',
        valueFormatter: dateFormatter,
        filterOperators: negatableDateFilterOperators,
      },
      {
        ...baseColumn,
        field: 'vLifeConfig.expiryDateCommToCustomer',
        headerName: t('vlife-expiry-date'),
        type: 'date',
        valueFormatter: dateFormatter,
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'vLifeConfig.resumptionDate',
        headerName: t('vlife-resumption-date'),
        type: 'date',
        valueFormatter: dateFormatter,
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'vLifeConfig.isDisabled',
        headerName: t('vlife-is-disabled'),
        type: 'boolean',
        valueFormatter: formatBoolean.bind({}, t('csv::yes'), t('csv::no')),
        filterOperators: negatableBooleanFilterOperators,
      },
      {
        ...baseColumn,
        field: 'vLifeConfig.isExpired',
        headerName: t('vlife-is-expired'),
        type: 'boolean',
        valueFormatter: formatBoolean.bind({}, t('csv::yes'), t('csv::no')),
        filterOperators: negatableBooleanFilterOperators,
      },
      {
        ...baseColumn,
        field: 'vLifeConfig.deactivationReason.name',
        headerName: t('vlife-deactivation-reason'),
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'action',
        headerName: t('action'),
        minWidth: 100,
        maxWidth: 100,
        renderCell: ({ row }) => <ReportValidationWarningIcon row={row} />,
      },
    ],
    [
      t,
      negatableNumberFilterOperators,
      negatableStringFilterOperators,
      negatableDateFilterOperators,
      negatableBooleanFilterOperators,
    ]
  );

  return { columns };
}
