import {
  NegatableNumberFilters,
  TypedGridFilterItem,
} from '~/components/DataGrid/filters/types';
import { GetApplyFilterFnLegacy, GridCellParams } from '@mui/x-data-grid-pro';

export const getNumberLessThanFilter: GetApplyFilterFnLegacy = ({
  value: filterValues,
}: TypedGridFilterItem<NegatableNumberFilters>) => {
  if (filterValues === undefined) {
    return null;
  }

  const { comparatorValue, isNegated } = filterValues;

  if (comparatorValue === undefined) {
    return null;
  }

  return ({ value }: GridCellParams) => {
    const comparison = Number(value) < Number(comparatorValue);
    return isNegated ? !comparison : comparison;
  };
};
