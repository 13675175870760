import { useParams } from 'react-router-dom';

/**
 * Gets the id from the current react-router route.
 *
 * If there is no id, an exception will be thrown. Only use this
 * on routes that have a non-optional id paramter.
 */
export function useRouteId(): string {
  const { id } = useParams();
  if (typeof id !== 'string') {
    throw Error('id must not be undefined in this route');
  }
  return id;
}
