import React from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { TextFieldProps } from '@mui/material/TextField';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { NegatableDateFilters } from '~/components/DataGrid/filters/types';
import { CustomInput } from '~/components/Table/components/CustomInput';
import { formatDateYYYYMMDD } from '~/components/DataGrid/filters/utils';
import { useFilterDebounceAndApply } from '~/components/DataGrid/filters/useFilterDebounceAndApply';
import { useTranslation } from 'react-i18next';

const defaultValues: NegatableDateFilters = {
  isNegated: false,
  comparatorDate: undefined,
};

export const NegatableDateInputFilter = ({ item, applyValue }: GridFilterInputValueProps) => {
  const { t } = useTranslation();
  const { values, updateFilterValue } = useFilterDebounceAndApply<NegatableDateFilters>(
    item,
    applyValue,
    defaultValues
  );

  const handleValueFilterChange: TextFieldProps['onChange'] = (event) => {
    const { value } = event.target;
    const newValue = new Date(formatDateYYYYMMDD(value));
    updateFilterValue({ comparatorDate: newValue });
  };

  const handleNegationFilterChange = (): void => {
    updateFilterValue({ isNegated: !values.isNegated });
  };

  return (
    <Box>
      <CustomInput
        name='date'
        placeholder={t('filter-inputs::date')}
        value={formatDateYYYYMMDD(values.comparatorDate)}
        onChange={handleValueFilterChange}
        type='date'
      />
      <FormControlLabel
        sx={{ ml: 2 }}
        label={t('filter-inputs::negate')}
        control={
          <Switch
            checked={values.isNegated}
            onChange={handleNegationFilterChange}
            size='small'
          />
        }
      />
    </Box>
  );
};
