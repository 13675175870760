import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventType, GetVLogFilesQuery } from '~/__generated-measurement__/graphql';
import { useVLogs } from './useVLogs';
import { Actions } from '~/components/Actions/Actions';
import { RowType } from '~/components/DataList';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';
import { Toast } from '~/components/Toast';
import { useVLogColumns } from './useVLogColumns';

import { UploadDialog } from '~/components/upload/UploadDialog';
import { DataGrid } from '~/components/DataGrid';
import { VLogExpandableRow } from './VLogExpandableRow';

export type VLogsRowData = {
  id: string;
  name?: string | null | undefined;
  size?: string | null | undefined;
  person?: string | null | undefined;
  dateUploaded?: string | null | undefined;
  errorMessage?: string | null | undefined;
  status?: EventType | null | undefined;
  [RowType.NODE]?: React.ReactNode;
  events: GetVLogFilesQuery['vlogFiles'][0]['events'];
};

// t('v-log::name')
// t('v-log::size')
// t('v-log::status')
// t('v-log::dateUploaded')
// t('v-log::uploadPerson')
// t('v-log::errorMessage')
// t('v-log::timestamp)

export const VLogs: React.FC = () => {
  const { t } = useTranslation('v-log');
  const { loading, error, data, closeError, setCloseError, uploadFiles } = useVLogs();

  const { columns } = useVLogColumns();

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Actions
        actions={[
          {
            label: t('upload-v-log'),
            onClick: () => {
              setUploadDialogOpen(true);
            },
          },
        ]}
      />
      <Toast
        severity='error'
        open={Boolean(error) && !closeError}
        onClose={() => setCloseError(true)}
      >
        {t('unable-to-load')}
      </Toast>
      <UploadDialog
        open={uploadDialogOpen}
        onCancel={() => setUploadDialogOpen(false)}
        onUpload={(files) => {
          setUploadDialogOpen(false);
          uploadFiles(files);
        }}
      />

      <DataGrid
        initialState={{
          sorting: {
            sortModel: [
              { field: 'dateUploaded', sort: 'desc' },
              { field: 'status', sort: 'asc' },
            ],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        columns={columns}
        rows={data}
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={({ row }) => <VLogExpandableRow row={row} />}
        loading={loading}
      />
    </>
  );
};
