import React, { useId } from 'react';

import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stack,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface DialogProps extends Omit<MuiDialogProps, 'onClose' | 'title'> {
  confirmText: React.ReactNode;
  title?: React.ReactNode;
  content?: React.ReactNode;
  cancelText?: React.ReactNode;
  confirmDisabled?: boolean;
  disabled?: boolean;
  tight?: boolean;
  loading?: boolean;
  onClose: (confirm?: boolean) => void;
  onConfirm?: () => void | Promise<void>;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  confirmColor?:
    | 'inherit'
    | 'primary'
    | 'neutral'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
}

export const Dialog: React.FC<DialogProps> = ({
  open,
  disabled,
  loading,
  title,
  confirmText = 'OK',
  cancelText,
  content,
  confirmDisabled = false,
  buttonVariant = 'contained',
  confirmColor = 'secondary',
  onConfirm,
  onClose,
  tight,
  ...rest
}) => {
  const handleConfirm = async () => {
    await onConfirm?.();
    onClose(true);
  };
  const handleClose = (_event: React.MouseEvent<HTMLElement>, reason: string) => {
    if (disabled && reason && reason == 'backdropClick') return;
    onClose(false);
  };
  const dialogContentId = useId();
  const dialogTitleId = useId();

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      aria-labelledby={dialogTitleId}
      aria-describedby={dialogContentId}
      disableEscapeKeyDown={disabled}
      {...rest}
    >
      {title && (
        <DialogTitle id={dialogTitleId}>
          <Stack direction={'row'} gap={2} justifyContent='space-between' alignItems='center'>
            {title}
            {onClose && (
              <IconButton
                aria-label='close'
                onClick={() => {
                  onClose(false);
                }}
                disabled={disabled || loading}
              >
                <CloseIcon
                  sx={{ color: (theme) => theme.palette.neutral.contrastText, fontSize: 16 }}
                />
              </IconButton>
            )}
          </Stack>
        </DialogTitle>
      )}
      {content && (
        <DialogContent sx={tight ? { p: '0 !important' } : { px: 3, py: 2 }}>
          {typeof content === 'string' ? (
            <DialogContentText id={dialogContentId}>{content}</DialogContentText>
          ) : (
            content
          )}
        </DialogContent>
      )}
      <DialogActions sx={{ px: 3, py: 1.5 }}>
        <Stack direction='row' gap={2}>
          {cancelText && (
            <Button
              variant={buttonVariant}
              color='neutral'
              onClick={() => onClose(false)}
              autoFocus
              disabled={disabled || loading}
            >
              {loading ? (
                <CircularProgress
                  size={24.5}
                  sx={{
                    color: (theme) => theme.palette.grey[600],
                  }}
                />
              ) : (
                cancelText
              )}
            </Button>
          )}
          <Button
            variant={buttonVariant}
            color={confirmColor}
            onClick={handleConfirm}
            disabled={confirmDisabled || disabled || loading}
          >
            {loading ? (
              <CircularProgress
                size={24.5}
                sx={{
                  color: (theme) => theme.palette.grey[600],
                }}
              />
            ) : (
              confirmText
            )}
          </Button>
        </Stack>
      </DialogActions>
    </MuiDialog>
  );
};
