import {
  FloatMeasurementType,
  ViperAssetMaxIrCapInput,
  ViperAssetPeriodInput,
} from '~/__generated-measurement__/graphql';
import { useEnrichRows } from '~/components/DataGrid/hooks/useEnrichRows';
import { useGetLatestFloatReadingsForAssetsLazy } from '~/queries-measurement/measurement';
import { CustomerAssetRow, EnrichedCustomerAssetRow } from './types';

export const useEnrichCustomerAssets = (rows: CustomerAssetRow[]) => {
  // Use lazy query for use inside enrichRowQuery async function
  const [getLatestFloatReadingsForAssets] = useGetLatestFloatReadingsForAssetsLazy();

  const enrichRowQuery = async (
    rows: (CustomerAssetRow | EnrichedCustomerAssetRow)[]
  ): Promise<EnrichedCustomerAssetRow[]> => {
    // Create query variables
    const uniqueMaxIRCaps: { [key: string]: number } = {};
    const allViperAssetPeriods: ViperAssetPeriodInput[][] = rows.map(
      ({ installedViperAssetPeriods }) =>
        installedViperAssetPeriods.map((period) => {
          if (!uniqueMaxIRCaps[period.viperAsset.serialNumber]) {
            uniqueMaxIRCaps[period.viperAsset.serialNumber] = period.viperAsset.maxIrCap ?? 0;
          }
          return {
            serialNumber: period.viperAsset.serialNumber,
            period: {
              start: period.startDate,
              end: period.endDate,
            },
          };
        })
    );
    const allMaxIRCaps: ViperAssetMaxIrCapInput[] = Object.keys(uniqueMaxIRCaps).map(
      (serialNumber) => ({
        serialNumber,
        maxIrCap: uniqueMaxIRCaps[serialNumber],
      })
    );

    // Query latest float reading for each set of viperAssetPeriods
    const { data } = await getLatestFloatReadingsForAssets({
      variables: {
        viperAssetPeriods: allViperAssetPeriods ?? [],
        maxIrCaps: allMaxIRCaps ?? [],
        measurementType: FloatMeasurementType.Limresistance,
      },
    });

    // Order if rows is preserved between query input and output, making mapping values 1:1 possible
    return rows.map((rowData, index): EnrichedCustomerAssetRow => {
      const { referenceIR } = rowData;
      const latestIRValue = data?.getLatestFloatReadingsForAssets[index]?.value ?? undefined;
      const percentageIRChange =
        latestIRValue && referenceIR
          ? 100 * ((latestIRValue - referenceIR) / referenceIR)
          : undefined;
      return {
        ...rowData,
        latestIRValue,
        percentageIRChange,
      };
    });
  };

  const { enrichedRows, loading } = useEnrichRows<CustomerAssetRow, EnrichedCustomerAssetRow>(
    rows,
    enrichRowQuery
  );

  return {
    enrichedRows,
    loading,
  };
};
