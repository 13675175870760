import { GetApplyFilterFnLegacy, GridCellParams } from '@mui/x-data-grid-pro';
import { TypedGridFilterItem } from '~/components/DataGrid/filters/types';
import { NegatableVLifeLicenceChangeRangeFilters } from '~/components/DataGrid/filters/custom/v-life-licences/types';
import { EnrichedCustomerAssetRow } from '~/pages/CustomerAssets/types';
import { sortByStartDate } from '~/pages/CustomerAssets/utils';
import { filterStatusWindowsOutsideFilterDateRange } from '~/components/DataGrid/filters/custom/v-life-licences/filter-functions/utils';
import { VLifeTimelinePeriod } from '~/__generated-asset__/graphql';

export const getNonBackToBackStatusChangeFilter: GetApplyFilterFnLegacy = ({
  value: filterValues,
}: TypedGridFilterItem<NegatableVLifeLicenceChangeRangeFilters>) => {
  if (filterValues === undefined) {
    return null;
  }

  const { fromStatuses, toStatuses, startDate, endDate, isNegated } = filterValues;

  if (!fromStatuses.length || !toStatuses.length || !startDate || !endDate) {
    // No start or end dates set in filter - don't filter out anything
    return null;
  }

  return ({
    value: vLifeTimelinePeriods,
  }: GridCellParams<EnrichedCustomerAssetRow, VLifeTimelinePeriod[]>) => {
    console.log(vLifeTimelinePeriods);

    // Immediately filter out rows with no configs
    if (!vLifeTimelinePeriods) {
      return isNegated;
    }

    // Filter out licences that don't overlap with filter date window
    const filteredStatusWindows = filterStatusWindowsOutsideFilterDateRange(
      vLifeTimelinePeriods,
      startDate,
      endDate
    );

    console.log({ filteredStatusWindows });

    // Sort by date ascending
    filteredStatusWindows.sort(sortByStartDate);

    // Determine whether transition happened within the filter time window, for this channel
    const searchStatuses = filteredStatusWindows.reduce(
      (flags, vLifeStatusWindow) => {
        if (!flags.startStatusFound && fromStatuses.includes(vLifeStatusWindow.status)) {
          flags.startStatusFound = true;
          return flags;
        }

        if (flags.startStatusFound && toStatuses.includes(vLifeStatusWindow.status)) {
          flags.endStatusFound = true;
          return flags;
        }

        return flags;
      },
      { startStatusFound: false, endStatusFound: false }
    );

    const keepItemIn = searchStatuses.startStatusFound && searchStatuses.endStatusFound;

    // Apply negation if present
    return isNegated ? !keepItemIn : keepItemIn;
  };
};
