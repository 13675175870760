import { GridFilterItem, useGridRootProps } from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';

export interface UseFilterDebounceAndApplyResponse<T> {
  values: T;
  updateFilterValue: (partialValues: Partial<T>) => void;
}
export const useFilterDebounceAndApply = <T>(
  item: GridFilterItem,
  applyValue: (value: GridFilterItem) => void,
  defaultValues: T
): UseFilterDebounceAndApplyResponse<T> => {
  const { filterDebounceMs } = useGridRootProps();
  const filterDebounceTimeout = useRef<ReturnType<typeof setTimeout> | null>();
  const [values, setValues] = useState<T>(item.value ?? defaultValues);

  // The state of the filters is also managed by the grid, allowing the closing of the filter modals
  // This part allows the grid to pass in the filter values if they've been previously set
  useEffect(() => {
    if (!item.value) {
      setValues(defaultValues);
    }
  }, [item, applyValue, setValues, defaultValues]);

  // Updating any of the values resets the debounce timeout - only applying the filters once
  // the user stops typing for a set amount of time
  const updateFilterValue = (updatedValues: Partial<T>) => {
    if (filterDebounceTimeout.current) {
      clearTimeout(filterDebounceTimeout.current);
    }

    const newValues = { ...values, ...updatedValues };
    setValues(newValues);

    filterDebounceTimeout.current = setTimeout(() => {
      applyValue({ ...item, value: newValues });
    }, filterDebounceMs);
  };

  return { values, updateFilterValue };
};
