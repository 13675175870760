import React, { useEffect, useState } from 'react';
import { FileUploadState, isActiveUpload, useUpload } from './useUpload';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  circularProgressClasses,
  useTheme,
} from '@mui/material';
import {
  ClearAll,
  Close,
  Done,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Refresh,
  WarningAmber,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload } from 'react-router-dom';
import { Tooltip } from '../Tooltip';

const TwoColorProgress: React.FC<
  CircularProgressProps & {
    backgroundColor: string;
  }
> = (props) => {
  const { backgroundColor, ...rest } = props;
  const theme = useTheme();
  return (
    <Box position='relative' display='flex'>
      <CircularProgress
        variant='determinate'
        sx={{
          color: backgroundColor,
        }}
        size={40}
        thickness={4}
        {...rest}
        value={100}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          color: theme.palette.primary.main,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...rest}
      />
    </Box>
  );
};

export const UploadIndicator: React.FC = () => {
  const { files, clearList, retry } = useUpload();
  const { t } = useTranslation('v-log-upload');
  const [minimized, setMinimized] = useState(false);
  const [lastListSize, setLastListSize] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const listSize = Object.keys(files).length;
    if (listSize > lastListSize) {
      setMinimized(false);
    }
    setLastListSize(listSize);
  }, [lastListSize, files]);

  const hasActiveUploads = !!Object.values(files).find((file) => isActiveUpload(file));
  const hasErroredUploads = !!Object.values(files).find(
    (file) => file.state === FileUploadState.ERRORED
  );
  const [totalBytes, uploadedBytes] = Object.values(files).reduce(
    (prev, curr) => [prev[0] + curr.total, prev[1] + curr.loaded],
    [0, 0]
  );

  useBeforeUnload((event) => {
    if (hasActiveUploads) {
      event.preventDefault();
      return (event.returnValue = '');
    }
  });

  if (Object.keys(files).length === 0) {
    return null;
  }

  return (
    <Box
      bgcolor='white'
      borderRadius='6px 6px 0 0'
      position='fixed'
      left={8}
      bottom='0'
      width={280 - 16}
      zIndex={1}
      boxShadow='0 0 10px rgba(0, 0, 0, 0.5)'
    >
      <Box
        display='flex'
        flexDirection='row'
        padding='0.5rem 1rem 0.5rem'
        justifyContent='space-between'
        bgcolor={theme.palette.grey[300]}
        borderRadius='6px 6px 0 0'
        alignItems='center'
      >
        <Typography fontSize='14px' color={theme.palette.text.secondary}>
          Uploads
        </Typography>
        <Box display='flex' flexDirection='row' alignItems='center'>
          {minimized && (
            <>
              {hasErroredUploads ? (
                <Tooltip title={t('error-list')}>
                  <WarningAmber sx={{ color: theme.palette.error.main }} />
                </Tooltip>
              ) : hasActiveUploads ? (
                <TwoColorProgress
                  size={25}
                  thickness={5}
                  backgroundColor={theme.palette.grey[500]}
                  variant='determinate'
                  value={totalBytes === 0 ? 0 : (uploadedBytes / totalBytes) * 100}
                />
              ) : (
                <Tooltip title={t('done-list')}>
                  <Done sx={{ color: theme.palette.primary.main }} />
                </Tooltip>
              )}
              <Box
                sx={{
                  paddingLeft: '0.5rem',
                }}
              />
            </>
          )}
          {!minimized && !hasActiveUploads && (
            <Tooltip title={t('clear-all')}>
              <IconButton
                onClick={() => {
                  if (!hasActiveUploads) {
                    clearList();
                  }
                }}
              >
                <ClearAll
                  sx={{ color: (theme) => theme.palette.text.secondary, fontSize: 22 }}
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={minimized ? t('restore') : t('minimize')}>
            <IconButton
              onClick={() => {
                setMinimized((value) => !value);
              }}
            >
              {minimized ? (
                <KeyboardArrowUp
                  sx={{ color: (theme) => theme.palette.text.secondary, fontSize: 20 }}
                />
              ) : (
                <KeyboardArrowDown
                  sx={{ color: (theme) => theme.palette.text.secondary, fontSize: 20 }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {minimized ? null : (
        <Box maxHeight='calc(100vh - 200px)' overflow='visible auto'>
          <List sx={{ padding: 0 }}>
            {Object.entries(files).map(([key, file], index) => (
              <React.Fragment key={key}>
                {index > 0 ? <Divider color={theme.palette.grey[500]} /> : null}
                <ListItem key={key} sx={{ minHeight: '2.5rem' }}>
                  <ListItemText
                    disableTypography // so that the ellipsis works
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      fontSize: 14,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {file.name}
                  </ListItemText>
                  <Box
                    sx={{
                      paddingLeft: '0.5rem',
                    }}
                  />
                  {file.state === FileUploadState.ERRORED ? (
                    <>
                      <Tooltip title={t('retry')}>
                        <IconButton
                          onClick={() => {
                            retry(key);
                          }}
                        >
                          <Refresh
                            sx={{
                              color: (theme) => theme.palette.text.secondary,
                              fontSize: 18,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('error')}>
                        <WarningAmber sx={{ color: theme.palette.error.main }} />
                      </Tooltip>
                    </>
                  ) : file.state === FileUploadState.UPLOADED ? (
                    <Tooltip title={t('done')}>
                      <Done sx={{ color: theme.palette.primary.main }} />
                    </Tooltip>
                  ) : (
                    <>
                      <TwoColorProgress
                        size={25}
                        thickness={5}
                        backgroundColor={theme.palette.grey[400]}
                        variant={
                          file.state === FileUploadState.NOT_STARTED
                            ? 'indeterminate'
                            : 'determinate'
                        }
                        value={file.total === 0 ? 0 : (file.loaded / file.total) * 100}
                      />
                      <Box
                        sx={{
                          paddingLeft: '0.5rem',
                        }}
                      />
                      <Tooltip title={t('cancel-upload')}>
                        <IconButton
                          onClick={() => {
                            file.xhr.abort();
                          }}
                        >
                          <Close
                            sx={{
                              color: (theme) => theme.palette.text.secondary,
                              fontSize: 18,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};
