import { GridRenderCellParams } from '@mui/x-data-grid-pro';

/*
  Value getters should:
  - Return undefined if the value is undefined / null / empty array, allowing cells to render dashes
    and allowing the filters to immediately filter out these values.
  - Return a coerced value for the filters to make use of (e.g parsing strings into Dates).

  NOTE: Value getters change the actual values within the grid, and will affect filtering values
        and Value formatters.
 */

export function getDate(params: GridRenderCellParams) {
  const date = params.value;
  return date ? new Date(date) : undefined;
}

export const getArrayValue = (params: GridRenderCellParams) => {
  const values = params.value;
  if (typeof values === 'undefined' || values === null || values.length === 0) {
    return undefined;
  }
  return values;
};
