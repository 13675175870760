import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import { Header } from './components/Header';
import { BreadcrumbsWithLogout as Breadcrumbs } from './components/Breadcrumbs';
import { Outlet } from 'react-router-dom';
import { SidebarNav } from './components/SidebarNav';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { mainNavigation } from './routes/mainNavigation';
import { RenderActions } from './components/Actions/RenderActions';
import { Blocker } from './components/Blocker';
import { useGetCurrentUser } from '~/queries-asset/user';
import { ErrorPage } from './pages/ErrorPage';
import { MainLoadingSkeleton } from './components/LoadingSkeleton';
import { CookieOverlay } from './components/CookieOverlay';
import { useCookiePreferences } from './common/useCookiePreferences';
import { applyAppInsights } from './telemetry/appInsights';

/**
 * The main app layout
 */
export const BaseApp: React.FC = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <BasicLayout />
    </MsalAuthenticationTemplate>
  );
};

export const App = applyAppInsights(BaseApp, 'App');

export function BasicLayout() {
  const { data, loading } = useGetCurrentUser();
  const { cookiePreferences, onAcceptCookiePreferences, showCookieOverlay } =
    useCookiePreferences();

  if (loading) {
    return <MainLoadingSkeleton />;
  }

  if (data?.currentUser?.roles.length === 0) {
    return <ErrorPage error={{ status: 401, statusText: 'Not Authorized' }} />;
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header />
        <Box
          component='main'
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            flexGrow: 1,
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <Toolbar /> {/* placeholder */}
          <Grid container spacing={0}>
            <Grid item>
              <SidebarNav />
            </Grid>
            <Grid
              item
              sx={{ flex: 1, overflowY: 'auto', height: 'calc(100vh - 64px)', pb: 3 }}
            >
              <Box sx={{ px: 3 }} display='flex' flexDirection='column'>
                <Box display='flex' flexDirection='row'>
                  <Box flexGrow={1}>
                    <Breadcrumbs routes={mainNavigation} />
                  </Box>
                  <Box>
                    <RenderActions />
                  </Box>
                </Box>
                <Outlet />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CookieOverlay
        show={showCookieOverlay && !cookiePreferences}
        onAccept={onAcceptCookiePreferences}
      />
      <Blocker />
    </>
  );
}
