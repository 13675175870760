import { Alert, AlertColor, Snackbar } from '@mui/material';
import React from 'react';

export interface ToastProps {
  open: boolean;
  severity: AlertColor;
  children: React.ReactNode;
  onClose?: () => void;
  autoHideDuration?: number;
}

export const Toast: React.FC<ToastProps> = ({
  open,
  onClose,
  severity,
  children,
  autoHideDuration,
}) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={autoHideDuration}
    >
      <Alert elevation={6} variant='filled' onClose={onClose} severity={severity}>
        {children}
      </Alert>
    </Snackbar>
  );
};
