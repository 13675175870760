import React from 'react';
import Home from '@mui/icons-material/Home';
import Memory from '@mui/icons-material/Memory';
import {
  Business,
  MonitorHeartOutlined,
  PersonOutline,
  RocketLaunchOutlined,
} from '@mui/icons-material';
import { Dashboard } from '~/pages/Dashboard';
import { ViperAssets } from '~/pages/ViperAssets';
import { CustomerAssets } from '~/pages/CustomerAssets';
import { CustomerAsset } from '~/pages/CustomerAsset';
import { CreateCustomerAsset } from '~/pages/CreateCustomerAsset';
import { ViperAsset } from '~/pages/ViperAsset';
import { CreateViperAsset } from '~/pages/ViperAsset/CreateViperAsset';
import { CreateLicence } from '~/pages/CreateLicence';
import { Navigate, RouteObject } from 'react-router-dom';
import { Path } from './paths';
import { Companies } from '~/pages/Companies';
import { CreateCompany } from '~/pages/CreateCompany';
import { Contacts } from '~/pages/Contacts/Contacts';
import { CreateContact } from '~/pages/CreateContact';
import { UserProfile } from '~/pages/UserProfile/UserProfile';
import { VLogs } from '~/pages/v-logs/VLogs';
import VLifeReports from '~/pages/VLifeReports';
import { CreateVLifeReport } from '~/pages/CreateVLifeReport';
import { ReviewVLifeReport } from '~/pages/ReviewVLifeReport';
import { ApproveVLifeReport } from '~/pages/ApproveVLifeReport';
import { PublishVLifeReport } from '~/pages/PublishVLifeReport';
import { StaticContent } from '~/pages/StaticContent';
import { LicenceInsightsPage } from '~/pages/LicenceInsights/LicenceInsightsPage';

// t('nav/dashboard')
// t('nav/assets')
// t('nav/customer-assets')
// t('nav/create')
// t('nav/viper-assets')
// t('nav/create-licence')
// t('nav/companies')
// t('nav/contacts')
// t('nav/v-logs')
// t('nav/v-life-reports')
// t('nav/v-life-service')
// t('nav/review')
// t('nav/approve')
// t('nav/publish')
// t('nav/cookie-policy')
// t('nav/user-profile')

/* Static contents */
// t('static::cookie-policy')
// t('nav/licence-insights')

export type NavigationItem = Omit<RouteObject, 'children'> & {
  key: string | null;
  icon: JSX.Element | null;
  /**
   * If `tab` is true, the final element of the path will not be displayed in the
   * breadcrumb trail, as it will be visible in the tab navigation bar
   */
  tab?: boolean;
  index?: false;
  children?: NavigationItem[];
};

export const mainNavigation: NavigationItem[] = [
  {
    key: 'nav/dashboard',
    icon: <Home />,
    path: Path.HOME,
    element: <Dashboard />,
    id: 'Dashboard',
  },
  {
    key: 'nav/assets',
    icon: <Memory />,
    id: 'Assets',
    children: [
      {
        key: 'nav/viper-assets',
        icon: null,
        path: Path.VIPER_ASSETS,
        element: <ViperAssets />,
        id: 'ViperAssets',
      },
      {
        key: 'nav/customer-assets',
        icon: null,
        path: Path.CUSTOMER_ASSETS,
        element: <CustomerAssets />,
        id: 'CustomerAssets',
      },
      {
        key: null,
        icon: null,
        path: Path.CREATE_CUSTOMER_ASSET,
        element: <CreateCustomerAsset />,
        id: 'CreateCustomerAsset',
      },
      {
        key: null,
        icon: null,
        path: Path.CREATE_VIPER_ASSET,
        element: <CreateViperAsset />,
        id: 'CreateViperAsset',
      },
      {
        key: null,
        icon: null,
        path: Path.CUSTOMER_ASSET,
        element: <CustomerAsset />,
        id: 'CustomerAsset',
      },
      {
        key: null,
        icon: null,
        tab: true,
        path: Path.COMMON_CUSTOMER_ASSET,
        element: <CustomerAsset />,
        id: 'CustomerAssetLogs',
      },
      {
        key: null,
        icon: null,
        tab: true,
        path: Path.COMMON_CUSTOMER_ASSET,
        element: <CustomerAsset />,
        id: 'CustomerAssetReports',
      },
      {
        key: null,
        icon: null,
        tab: true,
        path: Path.COMMON_CUSTOMER_ASSET,
        element: <CustomerAsset />,
        id: 'CustomerAssetGraph',
      },
      {
        key: null,
        icon: null,
        path: Path.CREATE_LICENCE,
        element: <CreateLicence />,
        id: 'CreateLicence',
      },
      {
        key: null,
        icon: null,
        path: Path.VIPER_ASSET,
        element: <ViperAsset />,
        id: 'ViperAsset',
      },
    ],
  },
  {
    key: 'nav/v-life-service',
    icon: <MonitorHeartOutlined />,
    id: 'VLifeService',
    children: [
      {
        key: 'nav/v-life-reports',
        icon: null,
        path: Path.V_LIFE_REPORTS,
        element: <VLifeReports />,
        id: 'VLifeReports',
      },
      {
        key: 'nav/licence-insights',
        icon: null,
        path: Path.LICENCE_INSIGHTS,
        element: <LicenceInsightsPage />,
        id: 'LicenceInsights',
      },
    ],
  },
  {
    key: null,
    icon: null,
    path: `${Path.V_LIFE_REPORTS}/create`,
    element: <Navigate to={Path.V_LIFE_REPORTS} replace />,
    id: 'CreateVLifeReportsRedirect',
  },
  {
    key: null,
    icon: null,
    tab: true,
    path: Path.COMMON_V_LIFE_REPORTS,
    element: <VLifeReports />,
    id: 'ReviewVLifeReports',
  },
  {
    key: null,
    icon: null,
    tab: true,
    path: Path.COMMON_V_LIFE_REPORTS,
    element: <VLifeReports />,
    id: 'ApproveVLifeReports',
  },
  {
    key: null,
    icon: null,
    tab: true,
    path: Path.COMMON_V_LIFE_REPORTS,
    element: <VLifeReports />,
    id: 'PublishVLifeReports',
  },
  {
    key: null,
    icon: null,
    path: Path.CREATE_V_LIFE_REPORT,
    element: <CreateVLifeReport />,
    id: 'CreateVLifeReport',
  },
  {
    key: null,
    icon: null,
    path: Path.REVIEW_V_LIFE_REPORT,
    element: <ReviewVLifeReport />,
    id: 'ReviewVLifeReport',
  },
  {
    key: null,
    icon: null,
    path: Path.APPROVE_V_LIFE_REPORT,
    element: <ApproveVLifeReport />,
    id: 'ApproveVLifeReport',
  },
  {
    key: null,
    icon: null,
    path: Path.PUBLISH_V_LIFE_REPORT,
    element: <PublishVLifeReport />,
    id: 'PublishVLifeReport',
  },
  {
    key: 'nav/v-logs',
    icon: <RocketLaunchOutlined />,
    path: Path.V_LOGS,
    element: <VLogs />,
    id: 'VLogs ',
  },
  {
    key: 'nav/companies',
    icon: <Business />,
    path: Path.COMPANIES,
    element: <Companies />,
    id: 'Companies',
  },
  {
    key: null,
    icon: null,
    path: Path.CREATE_COMPANY,
    element: <CreateCompany />,
    id: 'CreateCompany',
  },
  {
    key: 'nav/contacts',
    icon: <PersonOutline />,
    path: Path.CONTACTS,
    element: <Contacts />,
    id: 'Contacts',
  },
  {
    key: null,
    icon: null,
    path: Path.CREATE_CONTACT,
    element: <CreateContact />,
    id: 'CreateContact',
  },
  {
    key: null,
    icon: null,
    path: Path.COOKIE_POLICY,
    element: (
      <StaticContent
        content='cookie-policy'
        translationParams={{ userProfileRoute: Path.USER_PROFILE }}
      />
    ),
    id: 'CookiePolicy',
  },
  {
    key: null,
    icon: null,
    path: Path.USER_PROFILE,
    element: <UserProfile />,
    id: 'UserProfile',
  },
];
