import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDeleteVLifeReportForm, usePublishVLifeReportForm } from '~/queries-asset/channel';
import { withErrorHandling } from '~/components/Form/SaveError';
import { usePublishVLifeReportFormSchema } from './usePublishVLifeReportFormSchema';
import { formDefaults } from '../VLifeReports/panes/formDefaults';

// t('date')
// t('message')

export function usePublishVLifeReportFormHook() {
  const { reportForm, schema, loading } = usePublishVLifeReportFormSchema();

  const { reportFormId } = useParams();
  const [deleteReportForm, deleteError] = withErrorHandling(useDeleteVLifeReportForm());
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();

  // save

  const [showConfirmPublish, setShowConfirmPublish] = useState<'publish' | 'review'>();
  const [saving, setSaving] = useState(false);
  const [values, setValues] = useState<Record<string, unknown>>({});

  const [publishReportForm, publishReportFormError] = withErrorHandling(
    usePublishVLifeReportForm()
  );

  const onCancel = useCallback(() => {
    // set saving to true so the blocker is disabled
    setSaving(true);
    // allow page to rerender before navigating, so the blocker is actually disabled
    setTimeout(() => navigate(formDefaults.publish.path), 0);
  }, [navigate]);

  const onPublish = () => {
    setShowConfirmPublish('publish');
  };

  const onSendForReview = () => {
    setShowConfirmPublish('review');
  };

  const handleConfirmPublish = useCallback(async () => {
    setSaving(true);
    try {
      if (reportFormId) {
        const result = await publishReportForm({
          variables: {
            id: reportFormId,
            vlifeReportFormPublishInput: {
              sendBackForReview: showConfirmPublish === 'review',
              comment: values.comment as string,
            },
          },
        });
        if (!result.errors) {
          navigate(formDefaults.publish.path);
        }
      }
    } finally {
      setSaving(false);
      setShowConfirmPublish(undefined);
    }
  }, [navigate, publishReportForm, reportFormId, showConfirmPublish, values.comment]);

  // delete

  const deleteForm = useCallback(() => {
    deleteError.reset();
    setShowDelete(true);
  }, [deleteError]);

  const handleConfirmDelete = useCallback(async () => {
    setShowDelete(false);
    if (reportFormId) {
      setSaving(true);
      const result = await deleteReportForm({ variables: { id: reportFormId } });
      if (result.data?.deleteVLifeReportForm === reportFormId) {
        navigate(formDefaults.publish.path);
      } else {
        setSaving(false);
      }
    }
  }, [deleteReportForm, reportFormId, navigate]);

  return {
    reportForm,
    schema,
    loading,
    deleteForm,
    showDelete,
    setShowDelete,
    handleConfirmDelete,
    deleteError,

    onCancel,
    onPublish,
    onSendForReview,
    setValues,
    saving,
    setShowConfirmPublish,
    showConfirmPublish,
    handleConfirmPublish,
    publishReportFormError,
  };
}
