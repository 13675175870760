import React, { useEffect, useMemo } from 'react';
import {
  AppInsightsContext,
  ReactPlugin,
  useAppInsightsContext,
  // withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { useCookiePreferences } from '~/common/useCookiePreferences';
import { createAppInsights } from './appInsights';

export const AppInsightsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { cookiePreferences } = useCookiePreferences();

  const reactPlugin = useMemo(() => {
    // run at startup only
    return createAppInsights();
  }, []);

  useEffect(() => {
    reactPlugin?.getCookieMgr().setEnabled(cookiePreferences?.performance ?? false);
  }, [reactPlugin, cookiePreferences?.performance]);

  if (reactPlugin) {
    return (
      <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
    );
  }

  return <>{children}</>;
};

/**
 * Returns an app insights ReactPlugin if app insights is configured.
 *
 * The `useAppInsightsContext` hook has the wrong return type - it
 * assumes the result will never be undefined.
 */
export function useAppInsights() {
  return useAppInsightsContext() as ReactPlugin | undefined;
}
