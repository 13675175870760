import React from 'react';

import { Field, Schema } from '@data-driven-forms/react-form-renderer';
import { EditOutlined, Check, ClearOutlined } from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  useTheme,
} from '@mui/material';

const Outlined = styled('div')<{ color?: string; solid?: boolean }>`
  ${({ color, solid }) => `
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 2px solid ${color ?? 'inherit'};
    background: ${solid ? color ?? 'inherit' : 'transparent'};
  `}
`;

export interface WizardStepperProps {
  schema: Schema;
  activeStepIndex: number;
  currentStepName: string;
  jumpToStep: (n: number) => void;
  showMandatory?: boolean;
}

export const WizardStepper: React.FC<WizardStepperProps> = ({
  schema,
  activeStepIndex,
  currentStepName,
  jumpToStep,
  showMandatory,
}) => {
  const theme = useTheme();
  return (
    <List component={Paper} sx={{ p: 0 }}>
      {schema.fields[0].fields.map((field: Field, stepIndex: number) => {
        const disabled = showMandatory && !field.isRequired;
        const active = currentStepName === field.name;

        const activeColor = active
          ? theme.palette.neutral.contrastText
          : theme.palette.grey[700];
        const checkedColor = active ? theme.palette.grey[700] : theme.palette.secondary.main;

        const requiredSteps = schema.fields[0].fields.filter(
          ({ isRequired }: Field) => isRequired
        );
        const remainingRequiredSteps = requiredSteps.slice(0, activeStepIndex + 1);
        const isCheckedStep =
          !active && remainingRequiredSteps.some(({ name }: Field) => name === field.name);
        const checked = showMandatory ? isCheckedStep : activeStepIndex > stepIndex;

        const requiredStepIndex = requiredSteps.findIndex(
          ({ name }: Field) => name === field.name
        );

        return (
          <ListItem
            disabled={disabled}
            key={field.name}
            sx={{
              backgroundColor: (theme) =>
                active && !disabled ? theme.palette.grey[300] : 'inherit',
              cursor: !disabled ? 'pointer' : 'inherit',
              px: 3,
              py: 2,
              '&:hover': {
                background: (theme) => (!disabled ? theme.palette.grey[300] : 'inherit'),
              },
            }}
            onClick={() => {
              jumpToStep(showMandatory ? requiredStepIndex : stepIndex);
            }}
            data-testid={`step-${field.name}`}
          >
            <ListItemIcon>
              {active && (
                <Outlined color={activeColor} data-testid='edit-icon'>
                  <EditOutlined sx={{ color: activeColor }} />
                </Outlined>
              )}
              {checked && (
                <Outlined color={checkedColor} solid={!active} data-testid='check-icon'>
                  <Check sx={{ color: theme.palette.primary.main }} />
                </Outlined>
              )}
              {(activeStepIndex < stepIndex || disabled) && !active && (
                <Outlined color={activeColor} data-testid='clear-icon'>
                  <ClearOutlined sx={{ color: activeColor }} />
                </Outlined>
              )}
            </ListItemIcon>
            <ListItemText
              primary={field.title}
              primaryTypographyProps={{
                fontWeight: active ? 700 : 400,
              }}
            />
          </ListItem>
        );
      })}
    </List>
  );
};
