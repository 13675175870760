import colors from '~/themes/colors';
import { colors as MuiColors } from '@mui/material';

export type LegendItemDirection =
  | 'left-to-right'
  | 'right-to-left'
  | 'top-to-bottom'
  | 'bottom-to-top';

export type Anchor =
  | 'top'
  | 'top-right'
  | 'right'
  | 'bottom-right'
  | 'bottom'
  | 'bottom-left'
  | 'left'
  | 'top-left'
  | 'center';

export type Direction = 'row' | 'column';

export type SymbolShape = 'circle' | 'square' | 'diamond' | 'triangle';

export const pieLegends = [
  {
    anchor: 'bottom' as Anchor,
    direction: 'row' as Direction,
    justify: false,
    translateX: 0,
    translateY: 60,
    itemsSpacing: 10,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: 'top-to-bottom' as LegendItemDirection,
    itemOpacity: 0.85,
    symbolSize: 16,
    symbolShape: 'circle' as SymbolShape,
    dataFrom: 'keys',
  },
];

export const pieTheme = {
  tooltip: {
    container: {
      background: '#ffffff',
      color: '#333333',
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
};

export const barTheme = {
  background: '#ffffff',
  textColor: '#333333',
  fontSize: 11,
  axis: {
    domain: {
      line: {
        stroke: '#777777',
        strokeWidth: 0,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: '#333333',
      },
    },
    ticks: {
      line: {
        stroke: '#777777',
        strokeWidth: 0,
      },
      text: {
        fontSize: 11,
        fill: '#333333',
      },
    },
  },
  grid: {
    line: {
      stroke: '#dddddd',
      strokeWidth: 0,
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 11,
        fill: '#333333',
      },
    },
    text: {
      fontSize: 11,
      fill: '#333333',
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: '#333333',
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: '#333333',
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
    link: {
      stroke: '#000000',
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
    outline: {
      stroke: '#000000',
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
    symbol: {
      fill: '#000000',
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
  },
  tooltip: {
    container: {
      background: '#ffffff',
      color: '#333333',
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
};

export const defaultColors = [
  colors.green[500],
  colors.green[300],

  colors.blue[500],
  colors.blue[300],

  colors.red[500],
  colors.red[300],

  colors.orange[500],
  colors.orange[300],

  colors.yellow[500],
  colors.yellow[300],
];

export const statusScheme = {
  ACTIVE_FOC: colors.blue[500],
  ACTIVE_PAID: colors.green[500],
  ACTIVE_TRIAL: colors.yellow[500],
  ACTIVE_NON_R: colors.purple[500],
  EXPIRED: colors.red[500],
  SUSPENDED: colors.orange[500],
  DISABLED: colors.grey[500],
};

export const attritionScheme = [colors.green[500], colors.red[500]];
export const attritionPadding = 16;

export const generalScheme = [
  colors.blue[500],
  colors.green[500],
  colors.yellow[500],
  colors.red[500],
  colors.orange[500],
  colors.grey[500],
];

export const blueScheme = [
  colors.blue[100],
  colors.blue[300],
  colors.blue[500],
  colors.blue[700],
];

export const muiScheme = Object.values(MuiColors)
  .slice(11)
  .map((color: Record<string | number, string>) => Object.values(color))
  .flat();
