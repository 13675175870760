import { useState } from 'react';
import { useCookies, Cookies } from 'react-cookie';

// All cookies that the application uses, including third party tools like Application Insights
export interface AllCookies {
  cookiePreferences: CookiePreferences;
  ai_session: string;
  ai_user: string;
}

// Groupings for cookies - which the user selects when specifying Cookie Preferences
export enum CookiePreferenceGroups {
  ESSENTIAL = 'essential',
  PERFORMANCE = 'performance',
  MARKETING = 'marketing',
  FUNCTIONALITY = 'functionality',
}

// The Cookie Preferences object, set when the user selects preferences in the overlay
export type CookiePreferences = {
  [key in CookiePreferenceGroups]: boolean;
};

// Groupings for cookies we manage / 3rd party cookies, which should be scrubbed when
// a preference isn't set to enabled
type CookieGroupings = {
  [key in CookiePreferenceGroups]?: (keyof AllCookies)[];
};

// This cookie grouping variable is used to remove any previously set cookies that belong
// to cookie preference groups
export const cookieGroups: CookieGroupings = {
  // Essential cookies are enabled by default
  [CookiePreferenceGroups.ESSENTIAL]: ['cookiePreferences'],
  [CookiePreferenceGroups.PERFORMANCE]: ['ai_session', 'ai_user'],
};

export interface UseCookiePreferences {
  cookies: AllCookies;
  cookiePreferences: CookiePreferences | undefined;
  showCookieOverlay: boolean;
  onAcceptCookiePreferences: (preferences: CookiePreferences) => void;
}

const cookieName: keyof AllCookies = 'cookiePreferences';

/**
 * Return the cookie preferences in a non-reactive way.
 */
export function getCookiePreferences(): CookiePreferences | undefined {
  const cookies = new Cookies();
  return cookies.get(cookieName);
}

export const useCookiePreferences = (): UseCookiePreferences => {
  const [cookies, setCookie, removeCookie] = useCookies<keyof AllCookies, AllCookies>([
    cookieName,
  ]);
  const [showCookieOverlay, setShowCookieOverlay] = useState(true);
  const cookiePreferences = cookies.cookiePreferences;

  const onAcceptCookiePreferences = (updatedCookiePreferences: CookiePreferences) => {
    setShowCookieOverlay(false);

    // Set the cookie preferences
    setCookie(cookieName, updatedCookiePreferences, { path: '/', secure: true });

    // Delete any previously set cookies that should no longer be set
    Object.keys(updatedCookiePreferences).forEach((preferenceGroup) => {
      const cookiePreferenceGroup = preferenceGroup as CookiePreferenceGroups;

      // If the group is Essential, always enable (never scrub), otherwise check set preferences
      const groupEnabled =
        cookiePreferenceGroup === CookiePreferenceGroups.ESSENTIAL ||
        updatedCookiePreferences[cookiePreferenceGroup];

      // If group isn't enabled, delete any cookies that fall within group
      if (!groupEnabled) {
        // Get list of cookie names within group and remove each of them
        const cookiesWithinGroup = cookieGroups[cookiePreferenceGroup];
        if (cookiesWithinGroup) {
          cookiesWithinGroup.forEach((cookieName) => {
            removeCookie(cookieName);
          });
        }
      }
    });
  };

  return {
    cookies,
    cookiePreferences,
    showCookieOverlay,
    onAcceptCookiePreferences,
  };
};
