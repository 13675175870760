import { CreateStyled } from '@emotion/styled';
/**
 * Helps to use "transient props" with styled components,
 * props that start with a $ wont't be forwarded to the DOM elements.
 *
    export const StyledComp = styled(
      'div',
      transientOptions
    )<{ $customProp: boolean }>(({ $customProp }) => ({
        color: $customProp ? 'red' : 'blue',
    }));
 */
export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};
