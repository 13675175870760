import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  useFieldApi,
  UseFieldApiConfig,
  useFormApi,
} from '@data-driven-forms/react-form-renderer';

interface SubHeadingProps {
  label: React.ReactNode;
  editing?: boolean;
}

export const SubHeadingFormComponent: React.FC<UseFieldApiConfig> = (props) => {
  const { label } = useFieldApi(props);

  return <SubHeading label={label} editing={true} />;
};

export const SubHeading: React.FC<SubHeadingProps> = ({ label, editing }) => {
  return (
    <Typography
      component='h3'
      variant='h3'
      sx={{
        marginTop: 4,
        ml: editing ? 0 : 0.75,
        mb: 0.25,
        gridColumnEnd: 'span 2',
      }}
    >
      {label}
    </Typography>
  );
};

export const Label: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Typography
      component='p'
      sx={{
        marginTop: 0,
        ml: 0.75,
        mb: 0.25,
        gridColumnEnd: 'span 2',
        fontSize: 14,
      }}
    >
      {label}
    </Typography>
  );
};

export const CustomFormField: React.FC<UseFieldApiConfig> = (props) => {
  const { child, label, sx } = useFieldApi(props);

  return (
    <Box display='flex' flexDirection='column' sx={{ ...sx }}>
      {label && (
        <Typography variant='h3' component='h3' sx={{ mb: 2 }}>
          {label}
        </Typography>
      )}
      <Box>{child}</Box>
    </Box>
  );
};

export const CustomFormGroup: React.FC<UseFieldApiConfig> = (props) => {
  const { label, fields } = useFieldApi(props);
  const { renderForm } = useFormApi();

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        sx={{
          '& + &': { borderTop: (theme) => `1px solid ${theme.palette.divider}`, pt: 3 },
          pb: 2,
        }}
      >
        {label && (
          <Typography variant='h3' component='h3' sx={{ mb: 4 }}>
            {label}
          </Typography>
        )}
        <Stack
          display={'grid'}
          gridTemplateColumns={'1fr 1fr'}
          sx={{ '&': { gap: '1.5rem' } }}
        >
          {renderForm(fields)}
        </Stack>
      </Box>
    </>
  );
};
