import {
  componentTypes,
  validatorTypes,
  dataTypes,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { extraValidatorTypes } from '~/components/Form/validators';
import { getAllMonthsBetweenStartAndEnd } from '~/utils/date-utils';

export function getVLifeReportsUploadSchema(t: TFunction, locale: string) {
  return [
    {
      component: componentTypes.TEXT_FIELD,
      name: 'documentNumber',
      label: t('customer-asset::document-number'),
      isRequired: true,
      placeholder: 'XXXX-YYYYYY',
      validate: [
        { type: validatorTypes.REQUIRED, message: t('viper::required') },
        {
          type: validatorTypes.PATTERN,
          pattern: /^[0-9a-zA-Z]{4}-[0-9a-zA-Z]{6}$/,
          message: t('customer-asset::invalid-document-number'),
        },
      ],
    },
    {
      component: componentTypes.DATE_PICKER,
      name: 'dateReleased',
      label: t('customer-asset::release-date'),
      isRequired: true,
      validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
    },
    {
      component: componentTypes.DATE_PICKER,
      name: 'startDate',
      label: t('customer-asset::start-date'),
      isRequired: true,
      validate: [
        { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        { type: validatorTypes.REQUIRED, message: t('viper::required') },
      ],
    },
    {
      component: componentTypes.DATE_PICKER,
      name: 'endDate',
      label: t('customer-asset::end-date'),
      isRequired: true,
      validate: [
        { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        { type: validatorTypes.REQUIRED, message: t('viper::required') },
      ],
    },
    {
      component: componentTypes.SELECT,
      name: 'periodCovered',
      label: t('customer-asset::month-covered'),
      isRequired: true,
      validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      // eslint-disable-next-line
      resolveProps: (_props: any, _field: any, { getState }: any) => {
        const startDate = getState().values.startDate;
        const endDate = getState().values.endDate;

        return {
          disabled: !startDate || !endDate,
          options: getAllMonthsBetweenStartAndEnd(startDate, endDate, locale),
          helperText:
            !startDate || !endDate
              ? t('customer-asset::select-start-and-end-date-first')
              : undefined,
        };
      },
    },
    {
      component: componentTypes.TEXT_FIELD,
      name: 'revision',
      label: t('customer-asset::revision'),
      isRequired: true,
      dataType: dataTypes.INTEGER,
      validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
    },
    {
      component: componentTypes.SELECT,
      name: 'vlifeGrade',
      label: t('customer-asset::v-life-grade'),
      options: [
        { id: 'A', name: 'A' },
        { id: 'B', name: 'B' },
        { id: 'C', name: 'C' },
        { id: 'D', name: 'D' },
        { id: 'E', name: 'E' },
      ],
      isRequired: true,
      sx: { gridColumnEnd: 'span 2' },
      validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
    },
  ];
}
