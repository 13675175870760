import {
  NegatableNumberRangeFilters,
  TypedGridFilterItem,
} from '~/components/DataGrid/filters/types';
import { GetApplyFilterFnLegacy, GridCellParams } from '@mui/x-data-grid-pro';

export const getNumberRangeFilter: GetApplyFilterFnLegacy = ({
  value: filterValues,
}: TypedGridFilterItem<NegatableNumberRangeFilters>) => {
  if (filterValues === undefined) {
    return null;
  }

  const { lowerBound, upperBound, isNegated } = filterValues;

  if (lowerBound === undefined && upperBound === undefined) {
    // No lowerbound or upperbound set - don't filter out anything
    return null;
  }

  return ({ value }: GridCellParams) => {
    const numberValue = Number(value);
    let keepItemIn = true;

    // Apply filtering logic based on filter fields that are set
    if (lowerBound !== undefined && upperBound === undefined) {
      // Only lowerbound set, filter out anything smaller
      keepItemIn = numberValue >= lowerBound;
    } else if (upperBound !== undefined && lowerBound === undefined) {
      // Only upperbound set, filter out anything higher
      keepItemIn = numberValue <= upperBound;
    } else if (upperBound !== undefined && lowerBound !== undefined) {
      // Both bounds set, filter out anything that doesn't fall between
      keepItemIn = numberValue >= lowerBound && numberValue <= upperBound;
    }

    // Apply negation if present
    return isNegated ? !keepItemIn : keepItemIn;
  };
};
