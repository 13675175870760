import React from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { TextFieldProps } from '@mui/material/TextField';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { NegatableNumberRangeFilters } from '~/components/DataGrid/filters/types';
import { CustomInput } from '~/components/Table/components/CustomInput';
import { useFilterDebounceAndApply } from '~/components/DataGrid/filters/useFilterDebounceAndApply';
import { parseNumberOrUndefined } from '~/components/DataGrid/filters/utils';
import { useTranslation } from 'react-i18next';

const defaultValues: NegatableNumberRangeFilters = {
  isNegated: false,
  lowerBound: undefined,
  upperBound: undefined,
};

export const NegatableNumberRangeInputFilter = ({
  item,
  applyValue,
}: GridFilterInputValueProps) => {
  const { t } = useTranslation();
  const { values, updateFilterValue } = useFilterDebounceAndApply<NegatableNumberRangeFilters>(
    item,
    applyValue,
    defaultValues
  );

  const handleUpperFilterChange: TextFieldProps['onChange'] = (event) => {
    const upperBound = parseNumberOrUndefined(event.target.value);
    updateFilterValue({ upperBound: upperBound });
  };

  const handleLowerFilterChange: TextFieldProps['onChange'] = (event) => {
    const lowerBound = parseNumberOrUndefined(event.target.value);
    updateFilterValue({ lowerBound: lowerBound });
  };

  const handleNegationFilterChange = (): void => {
    updateFilterValue({ isNegated: !values.isNegated });
  };

  return (
    <Box>
      <CustomInput
        name='lower-bound-input'
        placeholder={t('filter-inputs::min')}
        value={parseNumberOrUndefined(values.lowerBound) ?? ''}
        onChange={handleLowerFilterChange}
        type='number'
        style={{ marginRight: '8px', flex: '0 0.5 50%' }}
      />
      <CustomInput
        name='upper-bound-input'
        placeholder={t('filter-inputs::max')}
        value={parseNumberOrUndefined(values.upperBound) ?? ''}
        onChange={handleUpperFilterChange}
        type='number'
        style={{ flex: '0 0.5 50%' }}
      />
      <FormControlLabel
        sx={{ ml: 2 }}
        label={t('filter-inputs::negate')}
        control={
          <Switch
            checked={values.isNegated}
            onChange={handleNegationFilterChange}
            size='small'
          />
        }
      />
    </Box>
  );
};
