import {
  componentTypes,
  Schema,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { DateTime } from 'luxon';
import { extraValidatorTypes } from '~/components/Form/validators';
import { formatPerson } from '~/utils/format-person';
import { GetVLifeEditSchemaProps } from '~/pages/ViperAsset/Overview/schemas/edit/lifecycle/types';
import { useTranslation } from 'react-i18next';

export function useLifecycleDecommissioningEditSchema({
  lifecycleEvent,
  people,
  decommissionReasons,
}: GetVLifeEditSchemaProps): Schema {
  const { t } = useTranslation('viper-asset-forms');

  return {
    fields: [
      {
        name: 'dateOfLifecycleEvent',
        label: t('lifecycle/decommissioning/date'),
        component: componentTypes.DATE_PICKER,
        isRequired: true,
        initialValue: lifecycleEvent?.dateOfLifecycleEvent ?? DateTime.now().toISODate(),
        validateOnMount: true,
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
        ],
      },
      {
        name: 'personId',
        isSearchable: true,
        sortAlphabetical: true,
        label: t('lifecycle/decommissioning/person'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: people?.map((person) => ({
          id: person.id,
          name: formatPerson(person),
        })),
        validateOnMount: true,
        initialValue: {
          id: lifecycleEvent?.person?.id,
          name: formatPerson(lifecycleEvent?.person),
        },
      },
      {
        name: 'decommissionReasonId',
        label: t('lifecycle/decommissioning/reason'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: decommissionReasons,
        initialValue: lifecycleEvent?.decommissionReason,
      },
      {
        name: 'notes',
        label: t('lifecycle/decommissioning/notes'),
        component: componentTypes.TEXTAREA,
        initialValue: lifecycleEvent?.notes,
        sx: { gridColumnEnd: 'span 2' },
      },
    ],
  };
}
