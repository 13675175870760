import Dygraph, { dygraphs } from 'dygraphs';
import colors from '~/themes/colors';
import 'dygraphs/dist/dygraph.min.css';
import './styles.css';

const createGraph = (
  chartData: { data: string },
  chartDiv: HTMLDivElement,
  labels: string[],
  options?: dygraphs.Options
) => {
  const dataText = chartData.data.replace(/inf/g, `${Number.MAX_VALUE}`);

  const dygraphOptions: dygraphs.Options = {
    legend: 'never',
    labels: labels,
    ylabel: labels[1],
    y2label: labels[2],
    drawPoints: true,
    pointSize: 1,
    labelsKMB: true,
    strokeWidth: 0,
    connectSeparatedPoints: false,
    legendFormatter: legendFormatter,
    fillGraph: false,
    animatedZooms: true,
    independentTicks: true,
    series: {
      [labels[2]]: {
        axis: 'y2',
        color: colors.yellow[700],
      },
      [labels[1]]: {
        axis: 'y1',
        color: colors.darkblue[500],
      },
    },
    ...options,
  };

  return new Dygraph(chartDiv, dataText, dygraphOptions);
};

export { createGraph };

function legendFormatter(data: dygraphs.LegendData): string {
  if (data.x == null) {
    // This happens when there's no selection and {legend: 'always'} is set.
    return +data.series
      .map(function (series) {
        return series.dashHTML + ' ' + series.labelHTML;
      })
      .join() as unknown as string;
  }

  let html = '<b>' + data.xHTML + '</b>';
  data.series.forEach((series) => {
    if (!series.isVisible) return;

    let labeledData = series.labelHTML + ' <b>' + series.yHTML + '</b>';

    if (series.isHighlighted) {
      labeledData = '<b>' + labeledData + '</b>';
    }

    html +=
      "<div class='dygraph-legend-row'>" +
      series.dashHTML +
      '<div>' +
      labeledData +
      '</div></div>';
  });
  return html;
}
