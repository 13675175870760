import React, { useCallback, useMemo } from 'react';

import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers';

import { useFieldApi, UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import { validationError } from './validation';
import { Box } from '@mui/material';
import { LabelledTextField } from './LabelledTextField';
import { DateTime } from 'luxon';
import { getDatePlaceholder } from '~/utils/date-utils';
import { useTranslation } from 'react-i18next';

export const INVALID_DATE_VALUE = 'Invalid Date';

export const DatePicker = ({
  validateOnMount: _validateOnMount,
  initialValue,
  ...props
}: UseFieldApiConfig) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    isRequired,
    helperText,
    description,
    validateOnMount,
    meta,
    DatePickerProps,
    sx,
  } = useFieldApi({
    validateOnMount: _validateOnMount,
    initialValue,
    ...props,
  });
  const invalid = validationError(meta, validateOnMount);
  // The external value for this date picker is stored as an ISO date string.
  // Internally, the datepicker uses a DateTime object, and also keeps track
  // of the text input that the user has entered.
  const localValue = DateTime.fromISO(input.value);
  const { t } = useTranslation();

  const locale = navigator.language;
  const placeholder = useMemo(
    () =>
      getDatePlaceholder({
        locale,
        dayPlaceholder: t('viper::date-placeholder/day'),
        monthPlaceholder: t('viper::date-placeholder/month'),
        yearPlaceholder: t('viper::date-placeholder/year'),
      }),
    [locale, t]
  );

  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void => {
      // Only trigger a blur event (and a validation) if we're actually
      // clicking outside of the date field. If we're just clicking on
      // date picker icon ignore it.
      if (!event.target.parentElement?.contains(event.relatedTarget)) {
        input.onBlur(event);
      }
    },
    [input]
  );

  return (
    <Box sx={sx}>
      <MUIDatePicker
        renderInput={(params) => {
          return (
            <LabelledTextField
              {...params}
              error={!!invalid}
              required={isRequired}
              disabled={isDisabled}
              margin='dense'
              {...props}
              helperText={
                invalid ||
                ((meta.touched || validateOnMount) && meta.warning) ||
                helperText ||
                description
              }
              inputProps={{
                ...params.inputProps,
                placeholder,
                onBlur,
              }}
            />
          );
        }}
        OpenPickerButtonProps={{
          onBlur,
        }}
        disableMaskedInput={true}
        disabled={isDisabled || isReadOnly}
        readOnly={isReadOnly}
        {...input}
        value={localValue}
        {...DatePickerProps}
        onChange={(value: DateTime, keyboardInputValue: string) => {
          if ((value === null || !value.isValid) && !keyboardInputValue?.trim()) {
            // field has been edited to contain only spaces, or nothing
            input.onChange(null);
          } else {
            // we have to convert the date to an ISO date string, otherwise DDF throws a wobbly.
            const textValue = value?.isValid ? value.toISODate() : INVALID_DATE_VALUE;
            input.onChange(textValue);
          }
        }}
        desktopModeMediaQuery='@media (min-width: 720px)'
      />
    </Box>
  );
};
