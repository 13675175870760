import { ComponentMapper, componentTypes } from '@data-driven-forms/react-form-renderer';
import { Checkbox } from '../Checkbox';
import { Select } from '../Select';
import { Textarea } from '../Textarea';
import { TextField } from '../TextField';
import { DatePicker } from '../DatePicker';
import { PlainText } from '../PlainText';
import { extraComponents } from '../../extraComponents';
import {
  CustomFormField,
  CustomFormGroup,
  SubHeadingFormComponent,
  Label,
} from '../extraComponents';
import { withAnnotation } from './withAnnotation';
import { SubForm } from '@data-driven-forms/mui-component-mapper';
import { Button } from '../templates/FormTemplate';

export const componentMapper: ComponentMapper = {
  [componentTypes.TEXT_FIELD]: TextField,
  [componentTypes.TEXTAREA]: Textarea,
  [componentTypes.CHECKBOX]: Checkbox,
  [componentTypes.SELECT]: {
    component: withAnnotation(Select),
    clearedValue: null,
  },
  [componentTypes.DATE_PICKER]: DatePicker,
  [componentTypes.PLAIN_TEXT]: PlainText,
  [componentTypes.BUTTON]: Button,
  [componentTypes.SUB_FORM]: SubForm,
  [extraComponents.SUBHEADING]: SubHeadingFormComponent,
  [extraComponents.LABEL]: Label,
  [extraComponents.CUSTOM]: CustomFormField,
  [extraComponents.SUB_FORM]: CustomFormGroup,
};
