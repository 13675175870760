import React from 'react';
import { CreateWizard } from '~/components/Form/Wizard/CreateWizard';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';
import { SaveError } from '~/components/Form/SaveError';
import { useTranslation } from 'react-i18next';
import { useContact } from './useContact';

export const CreateContact: React.FC = () => {
  const { t } = useTranslation('contact');

  const {
    optionsLoading,
    createContactSchema,
    saving,
    errorState,
    handleSubmit,
    handleCancel,
  } = useContact();

  if (optionsLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <SaveError errorState={errorState}>{t('error-creating-contact')}</SaveError>

      <CreateWizard
        schema={createContactSchema}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        saving={saving}
      />
    </>
  );
};
