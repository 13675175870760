import React, { useCallback, useState } from 'react';
import { Box, Button, Divider } from '@mui/material';
import { Dialog } from '~/components/Dialog';
import { IrAndVoltageGraph } from './Graphs/IrAndVoltageGraph';
import { useTranslation } from 'react-i18next';
import { useGetViperAssetWarnings } from '~/pages/warnings/useGetViperAssetWarnings';
import { useGetMissingReadingsWarnings } from '~/pages/warnings/useGetMissingReadingsWarnings';

type IsoDatePeriod = { startDate?: string; endDate?: string };

export interface CompareReportFormGraphProps {
  channelId?: string;
  currentDates: IsoDatePeriod;
  previousDates: IsoDatePeriod;
}

export const CompareReportFormGraphs: React.FC<CompareReportFormGraphProps> = ({
  channelId,
  currentDates,
  previousDates,
}) => {
  const { t } = useTranslation('create-v-life-report-form');
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { warnings: viperAssetWarnings, loading: loadingViperAssetWarnings } =
    useGetViperAssetWarnings(channelId, currentDates.startDate, currentDates.endDate);

  const { warnings: missingReadingsWarnings, loading: loadingMissingReadingsWarnings } =
    useGetMissingReadingsWarnings(channelId, currentDates.startDate, currentDates.endDate);

  const warnings = [...viperAssetWarnings, ...missingReadingsWarnings];

  const disableComparison =
    loadingViperAssetWarnings ||
    loadingMissingReadingsWarnings ||
    !channelId ||
    !currentDates.startDate ||
    !currentDates.endDate ||
    !previousDates.startDate ||
    !previousDates.endDate ||
    (warnings && warnings?.length > 0);

  const renderDialogContents = useCallback((): React.ReactNode => {
    return (
      <>
        <IrAndVoltageGraph
          channelId={channelId}
          startDate={currentDates.startDate}
          endDate={currentDates.endDate}
          title={t('report-period')}
        />
        <Divider sx={{ my: 3 }} />
        <IrAndVoltageGraph
          title={t('previous-report-period')}
          channelId={channelId}
          startDate={previousDates.startDate}
          endDate={previousDates.endDate}
          sx={{ background: (theme) => theme.palette.grey[300], p: 3, m: -3 }}
        />
      </>
    );
  }, [
    channelId,
    currentDates.endDate,
    currentDates.startDate,
    previousDates.endDate,
    previousDates.startDate,
    t,
  ]);

  return (
    <Box
      sx={{
        mb: 2,
        width: '100%',
        justifyContent: 'flex-end',
        display: 'flex',
      }}
    >
      <Button
        variant='contained'
        color='secondary'
        onClick={handleOpen}
        disabled={disableComparison}
      >
        {t('compare-with-previous-report')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        confirmText={t('viper::ok')}
        content={renderDialogContents()}
        title={t('compare-with-previous-report')}
        fullWidth
        maxWidth={false}
        sx={{ '& .MuiPaper-root': { overflow: 'hidden' } }}
      />
    </Box>
  );
};
