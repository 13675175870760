import { componentTypes, Field } from '@data-driven-forms/react-form-renderer';

/**
 * Maps values, as returned from a submitted DDF form, to the variables
 * object needed to submit those variables in a GraphQL mutation.
 *
 * It is assumed that the DDF field names match the GraphQL field names.
 * Only a single level of DDF schema is handled.
 *
 * Any plain text fields are ignored.
 *
 * Where explicitly specifies to be ignored when editing, the field is ignored.
 *
 * If `forEditSchema` is false, any fields which are required (isRequired = true)
 * are passed in a value object: `{ value: fieldValue }`.
 */
export function mapValuesToVariables(
  fields: Field[],
  values: Record<string, unknown>,
  forCreateSchema?: boolean
): Record<string, unknown> {
  const variables: Record<string, unknown> = {};
  fields.forEach((field) => {
    if (field.component === componentTypes.PLAIN_TEXT || field.ignoreSave) {
      // ignore
    } else {
      const variableName =
        field.component === componentTypes.SELECT && !field.name.endsWith('Id')
          ? `${field.name}Id`
          : field.name;
      if (field.isRequired && !field.isNotRequiredOnTheBackEnd && !forCreateSchema) {
        variables[variableName] = {
          value: values[field.name],
        };
      } else {
        variables[variableName] = values[field.name] ?? null;
      }
    }
  });
  return variables;
}
