import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGetDashboardValues } from '~/queries-asset/reports';

import { statusScheme } from '~/pages/Dashboard/charts/defaults';
import { getLicenceHistory } from './dashboardTools';
import { TextDashboardCard } from './components/TextDashboardCard';
import { StackedBarChartCard } from './charts/StackedBarChartCard';
import { VLifeStatus } from '~/__generated-asset__/graphql';
import { ChannelDetailsDialog } from './ChannelDetailsDialog';
import { exportExcel } from './export-excel';
import { ChartType, WidgetCard } from './components/WidgetCard';
import { TopLabels } from './charts/ChartLabels';
import { GainsAttritionChart } from '~/pages/Dashboard/charts/GainsAttritionChart';
import { useGainAttritionGraph } from '~/pages/Dashboard/useGainAttritionGraph';
import { TickerWidget } from './components/TickerWidget';
import { Path } from '~/routes/paths';
import { getCurrentFinancialYearDateRange } from '~/pages/Dashboard/date-utils';

// t('continent')
// t('country')
// t('count')
// t('ACTIVE_TRIAL')
// t('ACTIVE_FOC')
// t('ACTIVE_PAID')
// t('ACTIVE_NON_REFUNDABLE')
// t('EXPIRED')
// t('SUSPENDED')
// t('DISABLED')

export const Dashboard: React.FC = () => {
  const { t } = useTranslation('dashboard');

  const [channelDetailsMonth, setChannelDetailsMonth] = React.useState<string | null>(null);
  const [channelDetailsOpen, setChannelDetailsOpen] = React.useState(false);

  const { data: dashboardValuesData, loading } = useGetDashboardValues();
  const stackedBarData = useMemo(
    () => getLicenceHistory(t, dashboardValuesData?.reports.vlifeLicenceHistory),
    [dashboardValuesData, t]
  );

  const handleChannelDetailsClose = () => {
    setChannelDetailsMonth(null);
    setChannelDetailsOpen(false);
  };

  const { startDate, endDate } = useMemo(() => getCurrentFinancialYearDateRange(), []);

  const {
    data: gainsAttritionData,
    loading: loadingGainsAttrition,
    xAxis,
    gainCount,
    attritionCount,
  } = useGainAttritionGraph({ startDate: startDate, endDate: endDate });

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gap: '1rem',
          gridTemplateColumns: { sm: '1fr', md: 'repeat(6, 1fr)' },
          gridTemplateRows: 'auto',
          marginTop: '1.5rem',
        }}
      >
        <TextDashboardCard
          loading={loading}
          title={t('number-of-v-life-days')}
          text={
            t('days', {
              days: Math.floor((dashboardValuesData?.reports.totalVLifeHours ?? 0) / 24),
            }) ?? ''
          }
        />
        <TextDashboardCard
          loading={loading}
          title={t('v-life-channels')}
          text={dashboardValuesData?.reports.totalVLifeChannels}
        />
        <TickerWidget
          loading={loadingGainsAttrition}
          title={t('gains-attrition')}
          sx={{
            gridColumnEnd: {
              sm: 'span 6',
              md: 'span 2',
            },
            placeSelf: 'stretch',
          }}
          path={`${Path.LICENCE_INSIGHTS}`}
          gainCount={gainCount}
          attritionCount={attritionCount}
        />

        <WidgetCard
          loading={loading}
          data={dashboardValuesData?.reports}
          dataTypes={['paidVlifeLicencesPerContinent', 'activeVlifeLicencesPerContinent']}
          title={t('v-life-licences-per-continent')}
          indexBy='continent'
          dataKey='count'
        />
        <WidgetCard
          loading={loading}
          data={dashboardValuesData?.reports}
          dataTypes={['paidVlifeLicencesPerCountry', 'activeVlifeLicencesPerCountry']}
          title={t('v-life-licences-per-country')}
          indexBy='country'
          dataKey='count'
          defaultChartType={ChartType.BAR}
        />
        <WidgetCard
          loading={loading}
          data={dashboardValuesData?.reports}
          dataTypes={['paidVlifeLicencesPerCustomer', 'activeVlifeLicencesPerCustomer']}
          title={t('v-life-licences-per-customer')}
          indexBy='customer'
          dataKey='count'
          defaultChartType={ChartType.BAR}
        />
        <GainsAttritionChart
          data={gainsAttritionData}
          loading={loadingGainsAttrition}
          title={t('gain-attrition-chart-title')}
          xAxis={xAxis}
        />
        <StackedBarChartCard
          loading={loading}
          data={stackedBarData}
          onClick={(bar) => {
            setChannelDetailsOpen(true);
            setChannelDetailsMonth(bar.data.month as string);
          }}
          title={t('v-life-licence-history')}
          subTitle={t('v-life-licence-history-description')}
          keys={[
            VLifeStatus.ActivePaid,
            VLifeStatus.ActiveFoc,
            VLifeStatus.ActiveTrial,
            VLifeStatus.ActiveNonRefundable,
          ]}
          indexBy={'indexBy'}
          colors={[
            statusScheme.ACTIVE_PAID,
            statusScheme.ACTIVE_FOC,
            statusScheme.ACTIVE_TRIAL,
            statusScheme.ACTIVE_NON_R,
          ]}
          topLabels={TopLabels}
        />
      </Box>
      <ChannelDetailsDialog
        open={channelDetailsOpen}
        month={channelDetailsMonth as string}
        onClose={handleChannelDetailsClose}
        onExport={async (month, data) => {
          return exportExcel(month, data);
        }}
      />
    </>
  );
};
