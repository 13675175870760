import { CookieOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Path } from '~/routes/paths';
import { CookiePreferenceGroups, CookiePreferences } from '~/common/useCookiePreferences';

const StyledCheckbox = styled(Checkbox)`
  ${({ theme }) =>
    `&.Mui-checked {
    color: white;
  }
  &.Mui-disabled {
    &.Mui-checked {
      color: ${theme.palette.grey[600]}};
    }
  }
`}
`;

export const CookieOverlay: React.FC<{
  onAccept: (cookiePreferences: CookiePreferences) => void;
  show: boolean;
}> = ({ show, onAccept }) => {
  const { t } = useTranslation('cookie-policy');
  const [preferences, setPreferences] = React.useState<CookiePreferences>({
    essential: true,
    performance: false,
    functionality: false,
    marketing: false,
  });

  const updateSettings = (key: CookiePreferenceGroups) => {
    setPreferences((settings: CookiePreferences) => ({ ...settings, [key]: !settings[key] }));
  };

  if (!show) {
    return <></>;
  }

  return (
    <Dialog open={show}>
      <Box
        sx={{
          p: 2,
          background: 'white',
          width: '100%',
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <Typography
          component='h3'
          display='flex'
          alignContent='center'
          sx={{ fontWeight: 'bold', fontSize: 32, my: 0 }}
        >
          <CookieOutlined sx={{ fontSize: 40, mr: 1 }} />
          {t('heading')}
        </Typography>
        <p>
          {t('text')}{' '}
          <Link
            component={RouterLink}
            to={Path.COOKIE_POLICY}
            sx={{ color: (theme) => theme.palette.secondary.main }}
          >
            {t('link')}
          </Link>
        </p>
        <FormGroup>
          <FormControlLabel
            disabled
            checked
            control={<StyledCheckbox />}
            label={t('label/necessary')}
            sx={{
              '& .MuiFormControlLabel-label.Mui-disabled': {
                color: (theme) => theme.palette.grey[600],
              },
            }}
          />
          <FormControlLabel
            checked={preferences.performance}
            onChange={() => updateSettings(CookiePreferenceGroups.PERFORMANCE)}
            control={<StyledCheckbox />}
            label={t('label/performance')}
          />
          <FormControlLabel
            checked={preferences.functionality}
            onChange={() => {
              updateSettings(CookiePreferenceGroups.FUNCTIONALITY);
            }}
            control={<StyledCheckbox />}
            label={t('label/functionality')}
          />
          <FormControlLabel
            checked={preferences.marketing}
            onChange={() => {
              updateSettings(CookiePreferenceGroups.MARKETING);
            }}
            control={<StyledCheckbox />}
            label={t('label/marketing')}
          />
        </FormGroup>
        <Stack direction='row' spacing={2} mt={2} justifyContent='flex-end' width='100%'>
          <Button onClick={() => onAccept(preferences)} variant='contained' color='secondary'>
            {t('button/accept')}
          </Button>
          <Button
            onClick={() =>
              onAccept({
                essential: true,
                performance: true,
                functionality: true,
                marketing: true,
              })
            }
            variant='contained'
            color='secondary'
          >
            {t('button/accept-all')}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};
