import { GridFilterOperator } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useFilterTooltips } from '~/components/DataGrid/filters/filter-tooltip-mappers/useFilterTooltips';
import { VLifeLicenceChangeInputFilter } from '~/components/DataGrid/filters/custom/v-life-licences/inputs/VLifeLicenceChangeInputFilter';
import { getNonBackToBackStatusChangeFilter } from '~/components/DataGrid/filters/custom/v-life-licences/filter-functions/getNonBackToBackStatusChangeFilter';
import { NegatableDateRangeInputFilter } from '~/components/DataGrid/filters/NegatableDateRangeInputFilter';
import { getBackToBackStatusChangeFilter } from '~/components/DataGrid/filters/custom/v-life-licences/filter-functions/getBackToBackStatusChangeFilter';
import { getPaidToUnpaidStatusChangeFilter } from '~/components/DataGrid/filters/custom/v-life-licences/filter-functions/getPaidToUnpaidStatusChangeFilter';
import { getUnpaidToPaidStatusChangeFilter } from '~/components/DataGrid/filters/custom/v-life-licences/filter-functions/getUnpaidToPaidStatusChangeFilter';
import { VLifeStatusListInputFilter } from './inputs/VLifeStatusListInputFilter';
import { getVLifeStatusesContainsFilter } from '~/components/DataGrid/filters/filter-functions/get-v-life-statuses-contains.filter';

type FilterOperatorMap = {
  NonBacktoBackStatusChangeOverPeriod: GridFilterOperator;
  BacktoBackStatusChangeOverPeriod: GridFilterOperator;
  InactiveToPaid: GridFilterOperator;
  PaidToInactive: GridFilterOperator;
  StatusMatches: GridFilterOperator;
};

export const useVLifeLicencesFilterOperators = () => {
  const { t } = useTranslation('custom-filter-operators');
  const { getNumberRangeFilterTooltip, getDateRangeFilterTooltip } = useFilterTooltips();

  const FilterOperators: FilterOperatorMap = {
    BacktoBackStatusChangeOverPeriod: {
      label: t('back-to-back-status-change'),
      value: 'b2b-status-change',
      getApplyFilterFn: getBackToBackStatusChangeFilter,
      // TODO: UPDATE
      getValueAsString: getNumberRangeFilterTooltip,
      InputComponent: VLifeLicenceChangeInputFilter,
    },
    NonBacktoBackStatusChangeOverPeriod: {
      label: t('non-back-to-back-status-change'),
      value: 'non-b2b-status-change',
      getApplyFilterFn: getNonBackToBackStatusChangeFilter,
      // TODO: UPDATE
      getValueAsString: getNumberRangeFilterTooltip,
      InputComponent: VLifeLicenceChangeInputFilter,
    },
    InactiveToPaid: {
      label: t('inactive-to-paid'),
      value: 'inactive-to-paid',
      getApplyFilterFn: getUnpaidToPaidStatusChangeFilter,
      getValueAsString: getDateRangeFilterTooltip,
      InputComponent: NegatableDateRangeInputFilter,
    },
    PaidToInactive: {
      label: t('paid-to-inactive'),
      value: 'paid-to-inactive',
      getApplyFilterFn: getPaidToUnpaidStatusChangeFilter,
      getValueAsString: getDateRangeFilterTooltip,
      InputComponent: NegatableDateRangeInputFilter,
    },
    StatusMatches: {
      label: t('status-matches'),
      value: 'status-matches',
      getApplyFilterFn: getVLifeStatusesContainsFilter,
      getValueAsString: getDateRangeFilterTooltip,
      InputComponent: VLifeStatusListInputFilter,
    },
  };

  return {
    vLifeLicenceFilterOperators: [
      FilterOperators.NonBacktoBackStatusChangeOverPeriod,
      FilterOperators.BacktoBackStatusChangeOverPeriod,
      FilterOperators.InactiveToPaid,
      FilterOperators.PaidToInactive,
    ],
    vLifeStatusFilterOperators: [FilterOperators.StatusMatches],
  };
};
