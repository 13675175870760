import React from 'react';
import {
  Checkbox as MUICheckbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  FormLabel,
  CheckboxProps,
  FormGroupProps,
  FormControlLabelProps,
  FormControlProps,
  FormHelperTextProps,
  FormLabelProps,
  SxProps,
  Theme,
} from '@mui/material';

import { useFieldApi, UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import { validationError } from './validation';

export const CheckboxWithLabel: React.FC<{
  id?: string;
  value?: unknown;
  rest?: CheckboxProps;
  FormGroupProps?: FormGroupProps;
  FormControlLabelProps?: FormControlLabelProps;
  FormControlProps?: FormControlProps<'fieldset'>;
  FormHelperTextProps?: FormHelperTextProps;
  FormLabelProps?: FormLabelProps;
  CheckboxProps?: CheckboxProps;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  label?: string;
  helperText?: string;
  invalid?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement> | undefined;
  sx?: SxProps<Theme> | undefined;
}> = ({
  isRequired,
  invalid,
  sx,
  FormControlProps,
  FormGroupProps,
  FormControlLabelProps,
  CheckboxProps,
  isDisabled,
  isReadOnly,
  inputProps,
  FormLabelProps,
  label,
  helperText,
  FormHelperTextProps,
  id,
  value,
  rest,
}) => {
  return (
    <FormControl
      required={isRequired}
      error={!!invalid}
      component='fieldset'
      {...FormControlProps}
      sx={sx}
    >
      <FormGroup {...FormGroupProps}>
        <FormControlLabel
          {...FormControlLabelProps}
          control={
            <MUICheckbox
              {...CheckboxProps}
              disabled={isDisabled || isReadOnly}
              disableRipple
              value={value}
              inputProps={{
                readOnly: isReadOnly,
                ...inputProps,
              }}
              id={id}
              sx={{
                color: invalid ? '#DC3545' : '#D1D5DB',
                '&.Mui-checked': {
                  color: '#002244',
                },
                '& .MuiSvgIcon-root': { fontSize: 20 },
              }}
              {...rest}
            />
          }
          disabled={isDisabled || isReadOnly}
          label={
            <FormLabel
              htmlFor={id}
              sx={{ fontSize: 14, cursor: 'pointer' }}
              {...FormLabelProps}
            >
              {label}
            </FormLabel>
          }
        />
        {helperText && (
          <FormHelperText sx={{ fontSize: 14, mx: 0 }} {...FormHelperTextProps}>
            {helperText}
          </FormHelperText>
        )}
      </FormGroup>
    </FormControl>
  );
};
export const SingleCheckbox = (props: UseFieldApiConfig) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    FormControlProps,
    FormGroupProps,
    FormControlLabelProps,
    CheckboxProps,
    FormLabelProps,
    FormHelperTextProps,
    inputProps,
    sx,
    ...rest
  } = useFieldApi({
    ...props,
    type: 'checkbox',
  });
  const invalid = validationError(meta, validateOnMount);
  const text =
    invalid ||
    ((meta.touched || validateOnMount) && meta.warning) ||
    helperText ||
    description;
  return (
    <CheckboxWithLabel
      isRequired={isRequired}
      invalid={invalid}
      sx={sx}
      FormControlProps={FormControlProps}
      FormGroupProps={FormGroupProps}
      FormControlLabelProps={FormControlLabelProps}
      CheckboxProps={{
        ...input,
        ...CheckboxProps,
      }}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      inputProps={inputProps}
      FormLabelProps={FormLabelProps}
      label={label}
      helperText={text}
      FormHelperTextProps={FormHelperTextProps}
      id={input.name}
      value={input.name}
      rest={rest}
    />
  );
};

export const Checkbox = (props: UseFieldApiConfig) => <SingleCheckbox {...props} />;
