import React from 'react';
import {
  FormRenderer,
  FormTemplateRenderProps,
  Schema,
} from '@data-driven-forms/react-form-renderer';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';
import { componentMapper } from '~/components/Form/formFields/mappers/componentMapper';
import { validatorMapper } from '~/components/Form/validators';
import { GroupFormTemplate } from '../formFields/templates/FormTemplate';

export const FormGroup: React.FC<{
  data: Schema;
  loading: boolean;
  saving: boolean;
  submitButtonText: string;
  additionalSubmitButtonText?: string;
  extraFooterContent?: React.ReactNode;
  validator?: (values: Record<string, unknown>) => Record<string, unknown> | undefined;
  onCancel?: () => void;
  onSubmit?: () => void;
  onAdditionalSubmit?: () => void;
  additionalSubmitButtonDisabled?: (invalid: boolean) => boolean;
  setValues?: (values: Record<string, unknown>) => void;
}> = ({
  data,
  loading,
  validator,
  onCancel,
  onSubmit,
  setValues,
  saving,
  submitButtonText,
  onAdditionalSubmit,
  additionalSubmitButtonDisabled,
  additionalSubmitButtonText,
  extraFooterContent,
}) => {
  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <FormRenderer
      componentMapper={{ ...componentMapper }}
      schema={data}
      handleSubmit={onSubmit}
      onCancel={onCancel}
      FormTemplate={(props: FormTemplateRenderProps) => (
        <GroupFormTemplate {...props} extraFooterContent={extraFooterContent} />
      )}
      validatorMapper={validatorMapper}
      validate={validator}
      FormTemplateProps={{
        setValues,
        saving,
        submitButtonText,
        onAdditionalSubmit,
        additionalSubmitButtonDisabled,
        additionalSubmitButtonText,
      }}
    />
  );
};
