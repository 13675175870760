import {
  Schema,
  componentTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import {
  GetCreateViperAssetOptionsQuery,
  GetViperAssetQuery,
} from '~/__generated-asset__/graphql';
import { useTranslation } from 'react-i18next';

interface GetIdentificationCreateSchemaProps {
  parts?: GetCreateViperAssetOptionsQuery['parts'];
  viperAsset?: GetViperAssetQuery['viperAsset'];
  editing?: boolean;
}

export const useIdentificationCreateSchema = ({
  parts,
}: GetIdentificationCreateSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  return {
    fields: [
      {
        name: 'serialNumber',
        label: t('serialNumber'),
        component: componentTypes.TEXT_FIELD,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'partId',
        label: t('part'),
        component: componentTypes.SELECT,
        placeholder: 'VA-00000',
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: parts,
        sortAlphabetical: true,
        isSearchable: true,
      },
    ],
  };
};
