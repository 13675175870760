import React from 'react';
import { WarningAmberOutlined } from '@mui/icons-material';
import { BoxProps, Typography } from '@mui/material';

export const WarningBar: React.FC<
  { message: React.ReactNode; inline?: boolean } & BoxProps
> = ({ message, inline }) => {
  return (
    <Typography
      sx={{
        backgroundColor: (theme) => theme.palette.secondary.main,
        borderRadius: '5px',
        marginTop: 2,
        paddingRight: 1,
        fontSize: 14,
        display: inline ? 'inline-flex' : 'flex',
        alignItems: 'center',
      }}
    >
      <WarningAmberOutlined
        sx={{
          margin: '3px 7px',
        }}
      />
      {message}
    </Typography>
  );
};
