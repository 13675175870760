import React, { useMemo } from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import { UsePaginationItem } from '@mui/material/usePagination/usePagination';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { PageButton } from './Styled';
import { useMediaQuery, useTheme } from '@mui/material';

export const Pagination: React.FC<{ items: UsePaginationItem[] }> = ({ items }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });

  const displayButtonGroup = useMemo(
    () => items.some((item) => item.type.includes('page')),
    [items]
  );

  const itemsToRender = useMemo(
    () =>
      isSmall ? items.filter(({ type }) => type === 'previous' || type === 'next') : items,
    [items, isSmall]
  );

  return displayButtonGroup ? (
    <ButtonGroup
      disableRipple={true}
      variant='outlined'
      aria-label='pagination'
      sx={{ fontSize: '14px' }}
      className='MuiTablePagination-pagination'
    >
      {itemsToRender.map(({ page, type, ...item }, index) => {
        let children;

        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          children = (
            <PageButton sx={{ px: 1, py: 0.875 }} data-testid={type}>
              …
            </PageButton>
          );
        } else if (type === 'page') {
          children = (
            <PageButton sx={{ px: 1, py: 0.875 }} data-testid={type} {...item}>
              {page}
            </PageButton>
          );
        } else {
          children = (
            <PageButton data-testid={type} {...item} sx={{ maxWidth: 40, px: 1, py: 0.875 }}>
              {type === 'previous' && <ChevronLeft sx={{ fontSize: 18 }} color='inherit' />}
              {type === 'next' && <ChevronRight sx={{ fontSize: 18 }} color='inherit' />}
            </PageButton>
          );
        }
        return <React.Fragment key={index}>{children}</React.Fragment>;
      })}
    </ButtonGroup>
  ) : null;
};
