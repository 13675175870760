import {
  Schema,
  componentTypes,
  validatorTypes,
  FieldApi,
  AnyObject,
  FormOptions,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import {
  GetChannelQuery,
  GetCustomerAssetMaintenanceLogOptionsQuery,
} from '~/__generated-asset__/graphql';
import { extraValidatorTypes } from '~/components/Form/validators';
import { formatPerson } from '~/utils/format-person';

interface GetCustomerAssetMaintenanceLogEditSchemaProps {
  t: TFunction;
  options: GetCustomerAssetMaintenanceLogOptionsQuery | undefined;
  maintenanceLog?: NonNullable<GetChannelQuery['channel']>['maintenanceLogs'][0];
}

/**
 * This schema also serves as the create schema returned by getFieldCreateSchema
 */
export function getCustomerAssetMaintenanceLogEditSchema({
  t,
  options,
  maintenanceLog,
}: GetCustomerAssetMaintenanceLogEditSchemaProps): Schema {
  const editSchema = {
    fields: [
      {
        name: 'maintenanceStartDate',
        component: componentTypes.DATE_PICKER,
        label: t('customer-asset-forms::maintenance/start-date'),
        initialValue: maintenanceLog?.maintenanceStartDate,
        isRequired: true,
        validate: [
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        ],
      },
      {
        name: 'maintenanceEndDate',
        component: componentTypes.DATE_PICKER,
        label: t('customer-asset-forms::maintenance/end-date'),
        initialValue: maintenanceLog?.maintenanceEndDate,
        isRequired: true,
        validate: [
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        ],
      },
      {
        name: 'maintenanceTypeId',
        label: t('customer-asset-forms::maintenance/type'),
        component: componentTypes.SELECT,
        initialValue: maintenanceLog?.maintenanceType,
        options: options?.customerAssetMaintenanceTypes,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'affectedViperAssetIds',
        label: t('customer-asset-forms::maintenance/affected-viper-assets'),
        component: componentTypes.SELECT,
        isSearchable: true,
        isMulti: true,
        noValueUpdates: true,
        initialValue: maintenanceLog?.affectedViperAssets.map((asset) => asset.id),
        options: options?.viperAssets.map((asset) => ({
          id: asset.id,
          name: asset.serialNumber,
        })),
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'personId',
        label: t('customer-asset-forms::maintenance/person'),
        component: componentTypes.SELECT,
        initialValue: maintenanceLog?.person,
        isClearable: true,
        sortAlphabetical: true,
        isSearchable: true,
        clearedValue: null,
        options: options?.people?.map((person) => ({
          id: person.id,
          name: formatPerson(person),
        })),
      },
      {
        name: 'companyId',
        label: t('customer-asset-forms::maintenance/company'),
        component: componentTypes.PLAIN_TEXT,
        ignoreSave: true,
        resolveProps: (
          _props: AnyObject,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          _field: FieldApi<any, HTMLElement>,
          formOptions: FormOptions
        ) => {
          const selectedPersonId = formOptions.getState().values.personId;
          const selectedPerson = options?.people?.find(
            (person) => person.id === selectedPersonId
          );
          return {
            initialValue: selectedPerson ? selectedPerson?.company?.name : null,
          };
        },
      },
      {
        name: 'outcomeOfMaintenance',
        label: t('customer-asset-forms::maintenance/outcome'),
        initialValue: maintenanceLog?.outcomeOfMaintenance,
        component: componentTypes.TEXTAREA,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        sx: { gridColumnEnd: 'span 2' },
      },
    ],
  };
  return editSchema;
}
