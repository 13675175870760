import React from 'react';
import { FormGroup } from '~/components/Form/FormGroup';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';
import { SaveError } from '~/components/Form/SaveError';
import { Dialog } from '~/components/Dialog';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useApproveVLifeReportFormHook } from './useApproveVLifeReportForm';
import { VLifeReportFormState } from '~/__generated-asset__/graphql';

export const ApproveVLifeReport: React.FC = () => {
  const {
    reportForm,
    schema,
    loading,
    deleteForm,
    showDelete,
    setShowDelete,
    handleConfirmDelete,
    deleteError,

    onCancel,
    onApprove,
    onSendForReview,
    setValues,
    saving,
    setShowConfirmReview,
    showConfirmReview,
    handleConfirmReview,
    approveReportFormError,
  } = useApproveVLifeReportFormHook();
  const { t } = useTranslation('approve-v-life-report-form');

  if (loading) {
    return <LoadingSkeleton />;
  }
  if (reportForm?.vlifeReportForm?.state !== VLifeReportFormState.Reviewed) {
    return <strong>{t('report-not-found')}</strong>;
  }
  const approving = showConfirmReview === 'approve';
  return (
    <>
      <FormGroup
        data={schema}
        loading={false}
        // validator={validator} // add validator here if needed
        onCancel={onCancel}
        onSubmit={onApprove}
        onAdditionalSubmit={onSendForReview}
        additionalSubmitButtonDisabled={() => false}
        setValues={(values) => setValues(values)}
        saving={saving}
        extraFooterContent={
          <>
            <Button color='error' variant='contained' onClick={deleteForm}>
              {t('viper::delete')}
            </Button>
            <div
              style={{
                flexGrow: 1,
              }}
            />
          </>
        }
        submitButtonText={t('approve')}
        additionalSubmitButtonText={t('send-for-review')}
      />
      <Dialog
        open={showConfirmReview !== undefined}
        onClose={() => setShowConfirmReview(undefined)}
        title={approving ? t('approve-report/title') : t('back-for-review/title')}
        content={approving ? t('approve-report/content') : t('back-for-review/content')}
        confirmText={approving ? t('viper::sign') : t('send-for-review')}
        cancelText={t('viper::cancel')}
        onConfirm={handleConfirmReview}
        loading={saving}
      />
      <SaveError errorState={approveReportFormError}>{t('approve-report/error')}</SaveError>
      <Dialog
        open={showDelete}
        title={t('delete-report/title')}
        content={t('delete-report/content')}
        onClose={() => {
          setShowDelete(false);
        }}
        confirmText={t('viper::delete')}
        cancelText={t('viper::cancel')}
        confirmColor='error'
        onConfirm={handleConfirmDelete}
        loading={loading}
      />
      <SaveError errorState={deleteError}>{t('delete-report/error')}</SaveError>
      {/* TODO can we display a toast on another page? */}
    </>
  );
};
