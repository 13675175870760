import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '~/components/Table/Table';
import { useGetAuditChanges } from '~/queries-asset/audit';
import { formatDateTime } from '~/utils/date-utils';

export const AuditLog: React.FC<{ entity: string; id: string }> = ({ entity, id }) => {
  const { t } = useTranslation('audit-log');
  const { data } = useGetAuditChanges(entity, id);

  const tableData =
    data?.auditChanges.map((auditChange, index) => ({
      _id: String(index),
      [t('column/timestamp')]: formatDateTime(auditChange.timestamp),
      [t('column/field-name')]: t(
        `entity-${entity.replace('.', '-')}::${auditChange.fieldName}`
      ),
      [t('column/from')]: auditChange.from === '<unknown>' ? <i>unknown</i> : auditChange.from,
      [t('column/to')]: auditChange.to === '<unknown>' ? <i>unknown</i> : auditChange.to,
      [t('column/person')]: auditChange.personName,
    })) || [];

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
        <Typography variant='h3'>{t('audit-log')}</Typography>
      </Box>
      <Table data={tableData} omittedKeys={['_id']} translationKey={'viper-asset'} />
    </>
  );
};
