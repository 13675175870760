import { DateRange } from '~/common/types';

export const getStartOfDay = (inputDate: Date): Date => {
  const date = new Date(inputDate);
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  return date;
};

export const getEndOfDay = (inputDate: Date): Date => {
  const date = new Date(inputDate);
  date.setUTCHours(23);
  date.setUTCMinutes(59);
  date.setUTCSeconds(59);
  date.setUTCMilliseconds(999);
  return date;
};

export const getMonthStartAndEnd = (dateInMonth: Date): DateRange => {
  const startDate = getStartOfDay(new Date(dateInMonth));
  startDate.setUTCDate(1);

  const endDate = getEndOfDay(new Date(startDate));
  endDate.setUTCMonth(endDate.getUTCMonth() + 1);
  endDate.setUTCDate(endDate.getUTCDate() - 1);

  return {
    startDate,
    endDate,
  };
};

export const getCurrentFinancialYearDateRange = (): DateRange => {
  const currentDate = new Date();
  // Find previous April 6th
  const startDate = new Date(`${currentDate.getUTCFullYear()}-04-01`);
  startDate.setUTCFullYear(currentDate.getUTCFullYear());

  // Decrease the start date by 1 year if it is currently in the future
  if (startDate > currentDate) {
    startDate.setUTCFullYear(startDate.getUTCFullYear() - 1);
  }

  // Create the end date
  const endDate = new Date(startDate);
  endDate.setUTCFullYear(startDate.getUTCFullYear() + 1);
  endDate.setUTCSeconds(endDate.getUTCSeconds() - 1);

  return {
    startDate,
    endDate,
  };
};
