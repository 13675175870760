import React from 'react';
import { barTheme, defaultColors } from './defaults';

import {
  ResponsiveBar,
  BarDatum,
  LegendLabelDatum,
  ComputedDatum,
  BarLayer,
  BarTooltipProps,
  BarLegendProps,
} from '@nivo/bar';
import { PropertyAccessor } from '@nivo/core';

export const NivoBar: React.FC<{
  data?: BarDatum[];
  keys: string[];
  indexBy: string;
  colors?: string[];
  colorBy?: 'id' | 'indexValue';
  topLabels?: BarLayer<BarDatum>;
  tooltip?:
    | React.FC<BarTooltipProps<BarDatum>>
    | ((props: BarTooltipProps<BarDatum>) => React.ReactElement);
  showLegends?: boolean;
  showBottomAxis?: boolean;
  legendLabel?: PropertyAccessor<LegendLabelDatum<BarDatum>, string>;
  barAriaLabel?: (data: ComputedDatum<BarDatum>) => string | undefined;
  onClick?: (
    data: ComputedDatum<BarDatum> & {
      color: string;
    }
  ) => void;
}> = ({
  data,
  keys,
  indexBy,
  colors = defaultColors,
  onClick,
  topLabels,
  tooltip,
  colorBy,
  legendLabel,
  barAriaLabel,
  showLegends = true,
  showBottomAxis = true,
}) => {
  const legends: BarLegendProps[] | undefined = showLegends
    ? [
        {
          dataFrom: 'keys',
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 70,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'top-to-bottom',
          itemOpacity: 0.85,
          symbolSize: 16,
          symbolShape: 'circle',
        },
      ]
    : undefined;
  const tickRotation = data && data.length > 5 ? 45 : 0;

  return (
    <ResponsiveBar
      data={data ?? []}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: topLabels ? 60 : 20, right: 0, bottom: showLegends ? 95 : 20, left: 0 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      theme={barTheme}
      colors={colors}
      colorBy={colorBy}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={
        showBottomAxis
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation,
              legendPosition: 'middle',
              legendOffset: 32,
            }
          : null
      }
      enableLabel={false}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      legends={legends}
      legendLabel={legendLabel}
      role='application'
      barAriaLabel={barAriaLabel}
      tooltip={tooltip}
      layers={
        topLabels
          ? ['grid', topLabels, 'axes', 'bars', 'markers', 'legends']
          : ['grid', 'axes', 'bars', 'markers', 'legends']
      }
      onClick={onClick}
    />
  );
};
