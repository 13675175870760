import { Schema } from '@data-driven-forms/react-form-renderer';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useRouteId } from '~/common/useRouteId';
import { DataCardStack } from '~/components/DataCardStack';
import {
  getConfigDateFormValidator,
  getEndDateAfterStartFormValidator,
  getStartDateFormValidator,
} from '~/components/Form/date-validators';
import { mapValuesToVariables } from '~/components/Form/map-variables';
import { SaveError, withErrorHandling } from '~/components/Form/SaveError';
import { useGetChannel } from '~/queries-asset/channel';
import { useCreateVLifeConfig } from '~/queries-asset/viper-service';
import { buildPath, Path } from '~/routes/paths';
import { GetChannelQuery } from '~/__generated-asset__/graphql';
import { useVLifeCreateSchema } from './useVLifeCreateSchema';

export function useExistingEndDate(channel: GetChannelQuery | undefined) {
  return useMemo(() => {
    const endDate =
      channel?.channel?.upcomingVLifeConfig?.expiryDateCommToCustomer ||
      channel?.channel?.mostRecentVLifeConfig?.expiryDateCommToCustomer ||
      channel?.channel?.historicalVLifeConfigs?.[0]?.expiryDateCommToCustomer;
    return endDate ? DateTime.fromISO(endDate) : undefined;
  }, [
    channel?.channel?.mostRecentVLifeConfig?.expiryDateCommToCustomer,
    channel?.channel?.historicalVLifeConfigs,
    channel?.channel?.upcomingVLifeConfig?.expiryDateCommToCustomer,
  ]);
}

export const CreateLicence: React.FC = () => {
  const id = useRouteId();
  const navigate = useNavigate();
  const { t } = useTranslation('v-life-setup');

  const { data: channel, loading: channelLoading } = useGetChannel(id);
  const { vlifeCreateSchema, loading: schemaLoading } = useVLifeCreateSchema();

  const [saving, setSaving] = useState(false);
  const [createLicence, errorState] = withErrorHandling(useCreateVLifeConfig());
  const handleSave = useCallback(
    async (section: Schema, values: Record<string, unknown>) => {
      const variables = mapValuesToVariables(section.fields, values, true);
      setSaving(true);
      createLicence({
        variables: {
          vlifeInput: {
            channelId: id,
            expiryDateCommToCustomer: variables?.expiryDateCommToCustomer as string,
            expiryDateInConfig: variables?.expiryDateInConfig as string,
            startDate: variables?.startDate as string,
            vlifeVersionId: variables?.vlifeVersionId as string,
            notes: variables?.notes as string,
            vlifeLicence: {
              purchaseOrderNumber: variables?.purchaseOrderNumber as string,
              salesOrderNumber: variables?.salesOrderNumber as string,
              starjarProjectNumber: variables?.starjarProjectNumber as string,
              vlifeLicenceTypeId: variables?.vlifeLicenceTypeId as string,
            },
          },
        },
      })
        .then((result) => {
          if (!result.errors) {
            navigate(buildPath(Path.CUSTOMER_ASSET, { id }));
          }
        })
        .catch(() => setSaving(false));
    },
    [createLicence, id, navigate]
  );

  const existingEndDate = useMemo(() => {
    const endDate =
      channel?.channel?.upcomingVLifeConfig?.expiryDateCommToCustomer ||
      channel?.channel?.mostRecentVLifeConfig?.expiryDateCommToCustomer ||
      channel?.channel?.historicalVLifeConfigs?.[0]?.expiryDateCommToCustomer;
    return endDate ? DateTime.fromISO(endDate) : undefined;
  }, [
    channel?.channel?.mostRecentVLifeConfig?.expiryDateCommToCustomer,
    channel?.channel?.historicalVLifeConfigs,
    channel?.channel?.upcomingVLifeConfig?.expiryDateCommToCustomer,
  ]);

  const validate = useMemo(() => {
    const startDateValidator = getStartDateFormValidator(
      existingEndDate,
      t('date-validation/start-date-before-previous')
    );
    const endDateValidator = getEndDateAfterStartFormValidator(
      t('date-validation/end-date-before-start')
    );
    const configDateValidator = getConfigDateFormValidator(
      t('date-validation/config-date-before-end')
    );
    return (values: Record<string, unknown>) => {
      return {
        ...startDateValidator(values),
        ...endDateValidator(values),
        ...configDateValidator(values),
      };
    };
  }, [existingEndDate, t]);

  if (channel?.channel?.upcomingVLifeConfig) {
    navigate(buildPath(Path.CUSTOMER_ASSET, { id }));
  }

  return (
    <>
      <SaveError errorState={errorState}>{t('error-creating-v-life-config')}</SaveError>

      <DataCardStack
        data={vlifeCreateSchema}
        loading={channelLoading || schemaLoading}
        saving={saving}
        translationKey='customer-asset-forms'
        editing={'v-life-licence'}
        onCancel={() => {
          navigate(buildPath(Path.CUSTOMER_ASSET, { id }));
        }}
        onSave={handleSave}
        saveButtonLabel={t('viper::create')}
        validate={validate}
      />
    </>
  );
};
