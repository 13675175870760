import { Schema, componentTypes } from '@data-driven-forms/react-form-renderer';
import { extraValidatorTypes } from '~/components/Form/validators';
import {
  GetCreateViperAssetOptionsQuery,
  GetViperAssetQuery,
} from '~/__generated-asset__/graphql';
import { useTranslation } from 'react-i18next';

interface GetManufacturingCreateSchemaProps {
  manufacturers?: GetCreateViperAssetOptionsQuery['manufacturers'];
  parts?: GetCreateViperAssetOptionsQuery['parts'];
  viperAsset?: GetViperAssetQuery['viperAsset'];
  editing?: boolean;
}

export const useManufacturingCreateSchema = ({
  manufacturers,
  parts,
}: GetManufacturingCreateSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  return {
    fields: [
      {
        component: componentTypes.SELECT,
        isClearable: true,
        name: 'manufacturerId',
        label: t('manufacturer'),
        options: manufacturers,
        sortAlphabetical: true,
        isSearchable: true,
        sx: { gridColumnEnd: 'span 2' },
      },
      {
        component: componentTypes.DATE_PICKER,
        name: 'dateOfManufacture',
        label: t('dateOfManufacture'),
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        ],
      },
      {
        component: componentTypes.SELECT,
        isClearable: true,
        name: 'bomRevisionId',
        label: t('bomRevision'),
        sortAlphabetical: true,
        isSearchable: true,
        resolveProps: (_props, _field, formOptions) => {
          const selectedFieldId = formOptions.getState().values.partId;
          const selectedField = parts?.find((part) => part.id === selectedFieldId);
          return {
            options: selectedField?.bomRevisions,
          };
        },
      },
    ],
  };
};
