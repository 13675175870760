import {
  Schema,
  componentTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { GetFirmwareLogOptionsQuery, GetViperAssetQuery } from '~/__generated-asset__/graphql';
import { extraValidatorTypes } from '~/components/Form/validators';
import { formatPerson } from '~/utils/format-person';

interface GetFirmwareLogEditSchemaProps {
  t: TFunction;
  options: GetFirmwareLogOptionsQuery | undefined;
  firmwareRevision?: NonNullable<GetViperAssetQuery['viperAsset']>['firmwareUpdates'][0];
  previousFirmwareRevision?: NonNullable<
    GetViperAssetQuery['viperAsset']
  >['firmwareUpdates'][0];
}

/**
 * This schema also serves as the create schema returned by getFirmwareEditCreateSchema
 */
export function getFirmwareLogEditSchema({
  t,
  options,
  firmwareRevision,
  previousFirmwareRevision,
}: GetFirmwareLogEditSchemaProps): Schema {
  const editSchema = {
    fields: [
      {
        name: 'dateOfUpdate',
        component: componentTypes.DATE_PICKER,
        label: t('viper-asset-forms::firmware/date'),
        helperText: t('viper-asset-forms::firmware/date-helper'),
        initialValue: firmwareRevision?.dateOfUpdate,
        isRequired: true,
        validate: [
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        ],
      },
      {
        name: 'personId',
        label: t('viper-asset-forms::firmware/person'),
        component: componentTypes.SELECT,
        initialValue: firmwareRevision?.person,
        isClearable: true,
        isSearchable: true,
        sortAlphabetical: true,
        clearedValue: null,
        options: options?.people?.map((person) => ({
          id: person.id,
          name: formatPerson(person),
        })),
        helperText: t('viper-asset-forms::firmware/person-helper'),
      },
      {
        name: 'previousRevision',
        label: t('viper-asset-forms::firmware/update-from'),
        component: componentTypes.PLAIN_TEXT,
        initialValue: previousFirmwareRevision?.firmwareRevision.name ?? '–',
      },
      {
        name: 'firmwareRevisionId',
        label: t('viper-asset-forms::firmware/update-to'),
        component: componentTypes.SELECT,
        isSearchable: true,
        sortAlphabetical: true,
        initialValue: firmwareRevision?.firmwareRevision,
        options: options?.firmwareRevisions,
        isRequired: true,
        validate: [
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
          (value: string) => {
            if (value === previousFirmwareRevision?.firmwareRevision.id) {
              return t('viper-asset-forms::firmware/same-revision');
            }
          },
        ],
      },
      {
        name: 'comment',
        label: t('viper-asset-forms::firmware/comments'),
        initialValue: firmwareRevision?.comment,
        component: componentTypes.TEXTAREA,
        placeholder: t('viper-asset-forms::firmware/comments-placeholder'),
        sx: { gridColumnEnd: 'span 2' },
      },
    ],
  };
  return editSchema;
}
