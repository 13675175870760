import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { PerfMarkMeasureManager } from '@microsoft/applicationinsights-perfmarkmeasure-js';
import { getCookiePreferences } from '~/common/useCookiePreferences';
import { FunctionComponent } from 'react';
import { env } from '~/config/environmentVariables';

export const AI_CONNECTION_WARNING = `No Application Insights connection string provided. Telemetry is disabled.`;

let reactPlugin: ReactPlugin | undefined = undefined;

export function createAppInsights() {
  // Environment variables
  const APPLICATIONINSIGHTS_CONNECTION_STRING =
    env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING;
  const APPLICATIONINSIGHTS_SAMPLING_PERCENTAGE = Number(
    env.REACT_APP_APPLICATIONINSIGHTS_SAMPLING_PERCENTAGE
  );
  const APPLICATIONINSIGHTS_CORRELATION_ID = env.REACT_APP_APPLICATIONINSIGHTS_CORRELATION_ID;

  // If no connection string is available, don't initiate (useful for tests)
  if (!APPLICATIONINSIGHTS_CONNECTION_STRING) {
    console.warn(AI_CONNECTION_WARNING);
  } else {
    // Application insight plugins and dependencies
    const browserHistory = createBrowserHistory();
    reactPlugin = new ReactPlugin();
    const clickPluginInstance = new ClickAnalyticsPlugin();
    const clickPluginConfig = {
      autoCapture: true,
      useDefaultContentNameOrId: true,
      urlCollectQuery: true,
    };
    const performanceManager = new PerfMarkMeasureManager();

    const cookiePreferences = getCookiePreferences();

    const appInsights = new ApplicationInsights({
      config: {
        // Application configuration
        connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING,
        samplingPercentage: APPLICATIONINSIGHTS_SAMPLING_PERCENTAGE,
        appId: APPLICATIONINSIGHTS_CORRELATION_ID, // Used to correlate events between client and server
        // Data batching
        maxBatchInterval: 10000, // Send batched telemetry data every 10 seconds
        // maxBatchSizeInBytes: 1000000,
        // Tracking of fetch requests to API endpoints etc
        disableFetchTracking: false,
        enableAjaxErrorStatusText: true,
        enableAjaxPerfTracking: true,
        // Route tracking
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
        // Exception logging
        loggingLevelTelemetry: 2,
        // isBeaconApiDisabled: false,
        // enableCorsCorrelation: true,
        // Extensions
        extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
          [clickPluginInstance.identifier]: clickPluginConfig,
        },
        cookieCfg: {
          // The initial setting has to be here, rather than using getCookieMgr,
          // otherwise it seems you're unable to change it afterwards.
          enabled: cookiePreferences?.performance ?? false,
        },
      },
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      envelope.tags = envelope.tags ?? [];
      envelope.tags.push({
        'ai.cloud.role': 'Client',
      });
    });

    appInsights.core.setPerfMgr(performanceManager);
    return reactPlugin;
  }
}

export const applyAppInsights = (component: FunctionComponent, componentName: string) => {
  if (reactPlugin) {
    return withAITracking(reactPlugin, component, componentName);
  }
  return component;
};

export const trackAIEvent = (eventName: string) => {
  if (reactPlugin) {
    reactPlugin.trackEvent({
      name: eventName,
    });
  }
};

/**
 * Get the read-only react plugin.
 */
export function getReactPlugin() {
  return reactPlugin;
}

/**
 * DO NOT USE THIS OUTSIDE OF TESTS
 */
export function _resetForTest() {
  reactPlugin = undefined;
}
