import { GetViperAssetQuery, LifecycleEventType } from '~/__generated-asset__/graphql';
import { useCommercialEditSchema } from './edit/useCommercialEditSchema';
import { useConfigurationEditSchema } from './edit/useConfigurationEditSchema';
import { useIdentificationEditSchema } from './edit/useIdentificationEditSchema';
import { useManufacturingEditSchema } from './edit/useManufacturingEditSchema';
import { useGeneralEditSchema } from './edit/useGeneralEditSchema';
import { componentTypes } from '@data-driven-forms/react-form-renderer';
import colors from '~/themes/colors';
import { useTranslation } from 'react-i18next';
import { useLifecycleEditSchema } from '~/pages/ViperAsset/Overview/schemas/edit/lifecycle/useLifecycleEditSchema';
import { useAutomaticPollingEditSchema } from '~/pages/ViperAsset/Overview/schemas/edit/useAutomaticPollingEditSchema';

// t('viper-asset::create-lifecycle-event/INSTALLATION')
// t('viper-asset::create-lifecycle-event/COMMISSIONING')
// t('viper-asset::create-lifecycle-event/DECOMMISSIONING')
// t('viper-asset::create-lifecycle-event/UNINSTALLATION')

interface GetViperAssetEditSchemaProps {
  viperAsset?: GetViperAssetQuery['viperAsset'];
  editing?: string;
  creating?: string;
  getNewLifecycleEvent: (creating?: string) => void;
  handleCreateFirmwareRevisionClick: () => void;
}

export const useViperAssetEditSchema = ({
  handleCreateFirmwareRevisionClick,
  viperAsset,
  editing,
  creating,
  getNewLifecycleEvent,
}: GetViperAssetEditSchemaProps) => {
  const { t } = useTranslation('viper-asset');

  const identificationEditSchema = useIdentificationEditSchema({
    viperAsset,
    editing: editing === 'identification',
  });

  const manufacturingEditSchema = useManufacturingEditSchema({
    viperAsset,
    editing: editing === 'manufacturing',
  });

  const configurationEditSchema = useConfigurationEditSchema({
    viperAsset,
    handleCreateFirmwareRevisionClick,
    editing: editing === 'configuration',
  });

  const automaticPollingEditSchema = useAutomaticPollingEditSchema({
    viperAsset,
  });

  const commercialEditSchema = useCommercialEditSchema({
    viperAsset,
    editing: editing === 'commercial',
  });

  const generalEditSchema = useGeneralEditSchema({
    viperAsset,
  });

  const { hasHistory, ...lifecycleEditSchema } = useLifecycleEditSchema({
    viperAsset,
    editing,
    creating,
    getNewLifecycleEvent,
  });

  return {
    hasHistory,
    schema: {
      fields: [
        {
          name: 'create-viper-asset-wizard',
          component: componentTypes.WIZARD,
          fields: [
            {
              name: 'identification',
              title: t('identification'),
              nextStep: LifecycleEventType.Installation,
              fields: identificationEditSchema.fields,
            },
            {
              name: 'lifecycle',
              title: t('lifecycle'),
              nextStep: 'manufacturing',
              component: 'group',
              highlightColor: colors.lightblue[100],
              fields: lifecycleEditSchema.fields,
            },
            {
              name: 'manufacturing',
              title: t('manufacturing'),
              nextStep: 'configuration',
              fields: manufacturingEditSchema.fields,
            },
            {
              name: 'configuration',
              title: t('configuration'),
              nextStep: 'automatic-polling',
              fields: configurationEditSchema.fields,
            },
            {
              name: 'automatic-polling',
              title: t('automatic-polling'),
              nextStep: 'commercial',
              fields: automaticPollingEditSchema.fields,
            },
            {
              name: 'commercial',
              title: t('commercial'),
              fields: commercialEditSchema.fields,
            },
            {
              name: 'general',
              title: t('general'),
              fields: generalEditSchema.fields,
            },
          ],
        },
      ],
    },
  };
};
