import { useCallback, useMemo, useState } from 'react';
import { EventType, GetVLogFilesDocument } from '~/__generated-measurement__/graphql';
import { useUpload } from '~/components/upload/useUpload';
import { useGetVLogFiles } from '~/queries-measurement/measurement';
import { VLogsRowData } from './VLogs';
import { formatFileSize } from './FormatFileSizes';
import { env } from '~/config/environmentVariables';
import { measurementApolloClient } from '~/common/graphql-measurement';

function refetchVLogFiles() {
  return measurementApolloClient.refetchQueries({ include: [GetVLogFilesDocument] });
}

export function useVLogs() {
  const { data: vlogs, loading, error } = useGetVLogFiles();
  const [closeError, setCloseError] = useState(false);
  const { uploadFile } = useUpload();

  const data: VLogsRowData[] = useMemo(() => {
    return (vlogs?.vlogFiles ?? []).map((vlog) => {
      const lastVLogEvent = vlog.events[vlog.events.length - 1];
      const dateUploaded = vlog.events.find(
        (event) => event.eventType === EventType.Pending
      )?.timestamp;
      return {
        id: vlog.id,
        name: vlog.name,
        size: formatFileSize(vlog.sizeBytes),
        dateUploaded: dateUploaded,
        status: lastVLogEvent?.eventType,
        events: vlog.events,
      };
    });
  }, [vlogs?.vlogFiles]);

  const uploadFiles = useCallback(
    async (files: File[]) => {
      if (files) {
        await Promise.all(
          files.map((file) =>
            uploadFile(
              `${env.REACT_APP_MEASUREMENT_API_ENDPOINT}/vlogfile`,
              file,
              refetchVLogFiles,
              refetchVLogFiles
            )
          )
        );
      }
    },
    [uploadFile]
  );

  return {
    loading,
    error,
    data,
    closeError,
    setCloseError,
    uploadFiles,
  };
}
