import { uniqueId } from 'lodash';
import { atom, useRecoilState } from 'recoil';
import { getToken, protectedResources } from '~/common/msal';

export enum FileUploadState {
  NOT_STARTED,
  UPLOADING,
  UPLOADED,
  ERRORED,
}

export type FileUpload = {
  endpoint: string;
  name: string;
  state: FileUploadState;
  loaded: number;
  total: number;
  xhr: XMLHttpRequest;
  onDone: (id: number) => void;
  onStart: () => void;
  file: File;
};

const filesState = atom<Record<string, FileUpload>>({
  key: 'files',
  default: {},
});

export function isActiveUpload(file: FileUpload) {
  return file.state !== FileUploadState.ERRORED && file.state !== FileUploadState.UPLOADED;
}

export function useUpload() {
  const [files, setFiles] = useRecoilState(filesState);

  function updateFile(id: string, update: Partial<FileUpload>) {
    setFiles((files) => ({
      ...files,
      [id]: { ...files[id], ...update },
    }));
  }

  async function uploadFile(
    endpoint: string,
    file: File,
    onDone: (id: number) => void,
    onStart: () => void
  ) {
    const id = uniqueId();
    return uploadFileWithId(endpoint, file, id, onDone, onStart);
  }

  async function uploadFileWithId(
    endpoint: string,
    file: File,
    /**
     * id is the internal id used to refer to this upload operation
     */
    id: string,
    /**
     * If positive, this id is the id of the database entry that was created on the backend.
     * If the file was too big, this will be -1.
     * If there was some other error, this will be -2.
     *
     */
    onDone: (id: number) => void,
    onStart: () => void
  ) {
    const accessToken = await getToken([...protectedResources.apis.scopes.measurement]);

    const data = new FormData();
    data.append('file', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', endpoint);
    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === xhr.DONE) {
        try {
          const response = JSON.parse(xhr.responseText) as Record<string, number>;
          if (response[file.name] < 0) {
            updateFile(id, { state: FileUploadState.ERRORED });
            onDone(-2);
          } else {
            onDone(response[file.name]);
          }
        } catch (e) {
          onDone(-2);
        }
      }
    });
    const fileUpload: FileUpload = {
      endpoint,
      name: file.name,
      state: FileUploadState.NOT_STARTED,
      total: 0,
      loaded: 0,
      xhr,
      onStart,
      onDone,
      file,
    };
    setFiles((files) => ({ ...files, [id]: fileUpload }));

    xhr.upload.addEventListener('progress', (event) => {
      updateFile(id, { loaded: event.loaded });
    });
    xhr.upload.addEventListener('loadstart', (event) => {
      updateFile(id, {
        state: FileUploadState.UPLOADING,
        total: event.total,
      });
      onStart();
    });
    xhr.upload.addEventListener('abort', () => {
      setFiles((files) => {
        const newFiles = { ...files };
        delete newFiles[id];
        return newFiles;
      });
    });
    xhr.upload.addEventListener('load', () => {
      updateFile(id, { state: FileUploadState.UPLOADED });
    });
    xhr.upload.addEventListener('error', () => {
      updateFile(id, { state: FileUploadState.ERRORED });
    });
    xhr.send(data);
  }

  function clearList() {
    setFiles((files) => {
      const newFiles: Record<string, FileUpload> = {};
      Object.entries(files).forEach(([key, file]) => {
        if (isActiveUpload(file)) {
          newFiles[key] = file;
        }
      });
      return newFiles;
    });
  }

  function retry(id: string) {
    const file = files[id];
    if (file) {
      uploadFileWithId(file.endpoint, file.file, id, file.onDone, file.onStart);
    }
  }

  return {
    files,
    retry,
    uploadFile,
    clearList,
  };
}
