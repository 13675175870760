import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNegatableFilterOperators } from '~/components/DataGrid/filters/filter-operators';
import { formatInstalledViperAssetPeriods } from '~/pages/CustomerAssets/cell-formatters/formatInstalledViperAssetPeriods';
import { getArrayValue, getDate } from '~/components/DataGrid/value-getters';
import { EnrichedCustomerAssetRow } from '~/pages/CustomerAssets/types';
import { CustomerAssetsRowExpander } from '~/pages/CustomerAssets/CustomerAssetsRowExpander';
import { StatusPill } from '~/components/DataGrid/components/StatusPill';

import {
  formatBoolean,
  formatVLifeDateRange,
  formatVLifeTimelinePeriods,
  withSuffix,
} from '~/components/DataGrid/value-formatters';
import { useVLifeLicencesFilterOperators } from '~/components/DataGrid/filters/custom/v-life-licences/filter-operators';
import { formatPercentageIRChange } from '~/pages/CustomerAssets/cell-formatters/formatPercentageIRChange';
import { SetUpVLifePill } from '~/components/DataGrid/components/SetUpVLifePill';
import { VLifeStatus } from '~/__generated-asset__/graphql';

export function useCustomerAssetColumns() {
  const { t } = useTranslation('customer-assets');

  const {
    negatableNumberFilterOperators,
    negatableStringFilterOperators,
    negatableDateFilterOperators,
    negatableBooleanFilterOperators,
  } = useNegatableFilterOperators();

  const { vLifeLicenceFilterOperators, vLifeStatusFilterOperators } =
    useVLifeLicencesFilterOperators();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params: GridRenderCellParams<EnrichedCustomerAssetRow>) => (
          <CustomerAssetsRowExpander {...params} />
        ),
        filterable: false,
        sortable: false,
      },
      {
        field: 'id',
        headerName: t('id'),
        minWidth: 50,
        maxWidth: 100,
        flex: 1,
        filterOperators: negatableNumberFilterOperators,
      },
      {
        field: 'name',
        headerName: t('channel'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'field.name',
        headerName: t('field'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'field.country.name',
        headerName: t('country-name'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'field.country.continent.name',
        headerName: t('continent'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'customer.name',
        headerName: t('customer'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'field.defaultCustomer.name',
        headerName: t('field-default-customer'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'field.defaultOperator.name',
        headerName: t('field-default-operator'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'viperProjectNumber',
        headerName: t('viper-project-number'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'vLifeTimeline.currentStatus',
        headerName: t('v-life-licence-type'),
        renderCell: ({
          value,
          row,
        }: GridCellParams<EnrichedCustomerAssetRow, VLifeStatus>) => {
          if (value === undefined) {
            return <></>;
          }
          if (value === VLifeStatus.NotSetUp) {
            return <SetUpVLifePill id={row.id} />;
          }
          return <StatusPill status={value} />;
        },
        minWidth: 200,
        flex: 1,
        filterOperators: vLifeStatusFilterOperators,
      },
      {
        field: 'upcomingVLifeConfig',
        headerName: t('upcoming-v-life-config'),
        filterable: false,
        valueFormatter: formatVLifeDateRange,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'mostRecentVLifeConfig',
        headerName: t('most-recent-v-life-config'),
        filterable: false,
        valueFormatter: formatVLifeDateRange,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'vLifeTimeline.timeline',
        headerName: t('v-life-licences'),
        filterOperators: vLifeLicenceFilterOperators,
        valueFormatter: formatVLifeTimelinePeriods,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'mostRecentVLifeConfig.expiryDateCommToCustomer',
        headerName: t('v-life-expiry-date'),
        type: 'date',
        valueGetter: getDate,
        filterOperators: negatableDateFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'platform.name',
        headerName: t('platform-name'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'platform.waterDepth',
        headerName: t('water-depth'),
        filterOperators: negatableNumberFilterOperators,
        minWidth: 200,
        flex: 1,
        valueFormatter: withSuffix('m'),
      },
      {
        field: 'productionControlSystem.pcsManufacturer.name',
        headerName: t('pcs-manufacturer'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'productionControlSystem.pcsApproxInstallationDate',
        headerName: t('pcs-approx-installation-date'),
        minWidth: 200,
        flex: 1,
        type: 'date',
        valueGetter: getDate,
        filterOperators: negatableDateFilterOperators,
      },
      {
        field: 'productionControlSystem.epuManufacturer.name',
        headerName: t('epu-manufacturer'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'productionControlSystem.epuApproxInstallationDate',
        headerName: t('epu-approx-installation-date'),
        minWidth: 200,
        flex: 1,
        type: 'date',
        valueGetter: getDate,
        filterOperators: negatableDateFilterOperators,
      },
      {
        field: 'productionControlSystem.frequency',
        headerName: t('asset-frequency'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableNumberFilterOperators,
        valueFormatter: withSuffix('Hz'),
      },
      {
        field: 'productionControlSystem.maximumOperatingVoltage',
        headerName: t('epu-max-voltage'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableNumberFilterOperators,
        valueFormatter: withSuffix('V'),
      },
      {
        field: 'productionControlSystem.electricalDistributionType.name',
        headerName: t('asset-electrical-distribution-type'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'productionControlSystem.electricalTopology.name',
        headerName: t('electrical-topology'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'umbilical.manufacturer.name',
        headerName: t('umbilical-manufacturer'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'umbilical.materialType.name',
        headerName: t('umbilical-material-type'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'umbilical.approxInstallationDate',
        headerName: t('approx-umbilical-installation-date'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableNumberFilterOperators,
      },
      {
        field: 'umbilical.length',
        headerName: t('umbilical-length'),
        minWidth: 200,
        flex: 1,
        // type: 'number',
        filterOperators: negatableNumberFilterOperators,
        valueFormatter: withSuffix('km'),
      },
      {
        field: 'operatingVoltage',
        headerName: t('operating-voltage'),
        minWidth: 200,
        flex: 1,
        // type: 'number',
        filterOperators: negatableNumberFilterOperators,
        valueFormatter: withSuffix('V'),
      },
      {
        field: 'referenceIR',
        headerName: t('reference-ir'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableNumberFilterOperators,
        valueFormatter: withSuffix('Ω'),
      },
      {
        field: 'lifetimeStartDate',
        headerName: t('lifetime-start-date'),
        type: 'date',
        valueGetter: getDate,
        filterOperators: negatableDateFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'couplingType.name',
        headerName: t('coupling-type'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'alarm1Value',
        headerName: t('alarm-1-value'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableNumberFilterOperators,
        valueFormatter: withSuffix('Ω'),
      },
      {
        field: 'alarm2Value',
        headerName: t('alarm-2-value'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableNumberFilterOperators,
        valueFormatter: withSuffix('Ω'),
      },
      {
        field: 'tripAlarmRelay.name',
        headerName: t('tripAlarmRelay'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'relayConfiguration.name',
        headerName: t('relay-configuration'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'couplerUsed',
        headerName: t('coupler-used'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'interfaceCard.name',
        headerName: t('interface-card'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'poweringDevice.name',
        headerName: t('powering-device'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'negativeImpactOnRebootNotes',
        headerName: t('negative-impact-on-reboot-notes'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'latestFileReceived',
        headerName: t('latest-file-received'),
        minWidth: 200,
        flex: 1,
        type: 'date',
        valueGetter: getDate,
        filterOperators: negatableDateFilterOperators,
      },
      {
        field: 'dataDue',
        headerName: t('data-due'),
        minWidth: 200,
        flex: 1,
        type: 'date',
        valueGetter: getDate,
        filterOperators: negatableDateFilterOperators,
      },
      {
        field: 'notes',
        headerName: t('notes'),
        minWidth: 200,
        flex: 1,
        filterOperators: negatableStringFilterOperators,
      },
      {
        field: 'upcomingVLifeConfig.startDate',
        headerName: t('upcoming-v-life-start-date'),
        type: 'date',
        valueGetter: getDate,
        filterOperators: negatableDateFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'mostRecentVLifeConfig.startDate',
        headerName: t('recent-v-life-start-date'),
        type: 'date',
        valueGetter: getDate,
        filterOperators: negatableDateFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'mostRecentVLifeConfig.isExpired',
        headerName: t('recent-v-life-expired'),
        type: 'boolean',
        width: 50,
        flex: 1,
        valueFormatter: formatBoolean.bind({}, t('yes'), t('no')),
        filterOperators: negatableBooleanFilterOperators,
      },
      {
        field: 'mostRecentVLifeConfig.isDisabled',
        headerName: t('recent-v-life-disabled'),
        type: 'boolean',
        width: 50,
        flex: 1,
        valueFormatter: formatBoolean.bind({}, t('csv::yes'), t('csv::no')),
      },
      {
        field: 'mostRecentVLifeConfig.deactivationReason.name',
        headerName: t('recent-v-life-deactivation-reason'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'installedViperAssetPeriods',
        headerName: 'Installed Viper Asset Periods',
        filterOperators: negatableNumberFilterOperators,
        filterable: false,
        sortable: false,
        valueGetter: getArrayValue,
        valueFormatter: formatInstalledViperAssetPeriods,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'latestIRValue',
        headerName: t('latest-ir-reading'),
        filterOperators: negatableNumberFilterOperators,
        minWidth: 200,
        flex: 1,
        valueFormatter: withSuffix('Ω'),
      },
      {
        field: 'percentageIRChange',
        headerName: t('ir-percentage-change'),
        filterOperators: negatableNumberFilterOperators,
        minWidth: 200,
        flex: 1,
        valueFormatter: formatPercentageIRChange,
      },
      {
        field: 'mostRecentVLifeConfig.flaggedForDecommission',
        headerName: t('flagged-for-decommission'),
        filterOperators: negatableBooleanFilterOperators,
        valueFormatter: formatBoolean.bind({}, t('yes'), t('no')),
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'mostRecentVLifeConfig.flaggedForDecommissionDate',
        headerName: t('flagged-for-decommission-date'),
        filterOperators: negatableDateFilterOperators,
        minWidth: 200,
        flex: 1,
        type: 'date',
        valueGetter: getDate,
      },
    ],
    [
      t,
      negatableNumberFilterOperators,
      negatableStringFilterOperators,
      vLifeStatusFilterOperators,
      vLifeLicenceFilterOperators,
      negatableDateFilterOperators,
      negatableBooleanFilterOperators,
    ]
  );
  return { columns };
}
