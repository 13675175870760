import { LicenceInsightsRow } from '~/pages/LicenceInsights/types';
import { DateRange } from '~/common/types';

export const gainFallsWithinEvaluationPeriod = (
  row: LicenceInsightsRow,
  evaluationPeriod: DateRange
): boolean => {
  const parsedStartDate = new Date(row.startDate ?? '');

  return !!(
    row.gain &&
    row.startDate &&
    parsedStartDate >= evaluationPeriod.startDate &&
    parsedStartDate <= evaluationPeriod.endDate
  );
};

export const attritionFallsWithinEvaluationPeriod = (
  row: LicenceInsightsRow,
  evaluationPeriod: DateRange
): boolean => {
  const parsedEndDate = new Date(row.endDate ?? '');

  return !!(
    row.attrition &&
    row.endDate &&
    parsedEndDate >= evaluationPeriod.startDate &&
    parsedEndDate <= evaluationPeriod.endDate
  );
};

export const flaggedForDecommissionFallsWithinEvaluationPeriod = (
  row: LicenceInsightsRow
): boolean => {
  return Boolean(row.flaggedForDecommission);
};
