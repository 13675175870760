import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Actions } from '~/components/Actions/Actions';
import { Toast } from '~/components/Toast';
import { Path } from '~/routes/paths';
import { useViperAssets } from './useViperAssets';
import { DataGrid } from '~/components/DataGrid';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';

export const ViperAssets: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('viper-assets');

  const [closeError, setCloseError] = useState(false);

  const { rows, columns, loading, error } = useViperAssets();

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Actions
        actions={[
          {
            label: t('create-asset'),
            onClick: () => {
              navigate(Path.CREATE_VIPER_ASSET);
            },
          },
        ]}
      />
      <Toast
        severity='error'
        open={Boolean(error) && !closeError}
        onClose={() => setCloseError(true)}
      >
        {t('unable-to-load')}
      </Toast>
      <DataGrid
        statePersistencyId='viper-assets'
        initialColumnVisibilityModel={{
          id: false,
          serialNumber: true,
          'part.name': true,
          'manufacturer.name': false,
          dateOfManufacture: false,
          'bomRevision.name': false,
          'primaryComms.name': false,
          'currentFirmwareRevision.name': true,
          ipAddress: false,
          'ipAssignmentMethod.name': false,
          macAddress: false,
          adminPassword: false,
          'rootRevisionPassword.name': false,
          maxIrCap: false,
          remoteAccess: false,
          dateOfPurchase: false,
          'endClient.name': false,
          'customer.name': false,
          generalNotes: false,
          'latestLifecycleEvent.eventType': true,
          'latestInstallationEvent.dateOfLifecycleEvent': true,
        }}
        rows={rows ?? []}
        columns={columns}
        rowClickParamName='id'
        rowClickUrlBase={Path.VIPER_ASSET}
      />
    </>
  );
};
