import React from 'react';
import { useTranslation } from 'react-i18next';

import { Actions } from '~/components/Actions/Actions';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';
import { Toast } from '~/components/Toast';
import { Path } from '~/routes/paths';
import { useCustomerAssets } from './useCustomerAssets';
import { DataGrid } from '~/components/DataGrid';
import { CustomerAssetsExpandableRow } from '~/pages/CustomerAssets/CustomerAssetsExpandableRow';

export const CustomerAssets: React.FC = () => {
  const { t } = useTranslation('customer-assets');

  const { loading, columns, rows, error, closeError, setCloseError, navigate } =
    useCustomerAssets();

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Actions
        actions={[
          {
            label: t('create-asset'),
            onClick: () => {
              navigate(Path.CREATE_CUSTOMER_ASSET);
            },
          },
        ]}
      />
      <Toast
        severity='error'
        open={Boolean(error) && !closeError}
        onClose={() => setCloseError(true)}
      >
        {t('unable-to-load')}
      </Toast>
      <DataGrid
        statePersistencyId='customer-assets'
        columns={columns}
        rows={rows}
        rowClickParamName='id'
        rowClickUrlBase={Path.CUSTOMER_ASSET}
        initialColumnVisibilityModel={{
          id: false,
          'field.defaultOperator.name': false,
          'field.defaultCustomer.name': false,
          'field.country.name': false,
          viperProjectNumber: false,
          'platform.name': false,
          'platform.waterDepth': false,
          'productionControlSystem.pcsManufacturer.name': false,
          'productionControlSystem.pcsApproxInstallationDate': false,
          'productionControlSystem.epuManufacturer.name': false,
          'productionControlSystem.epuApproxInstallationDate': false,
          'productionControlSystem.frequency': false,
          'productionControlSystem.maximumOperatingVoltage': false,
          'productionControlSystem.electricalDistributionType.name': false,
          'productionControlSystem.electricalTopology.name': false,
          'umbilical.manufacturer.name': false,
          'umbilical.materialType.name': false,
          'umbilical.approxInstallationDate': false,
          'umbilical.length': false,
          operatingVoltage: false,
          lifetimeStartDate: false,
          referenceIR: false,
          'couplingType.name': false,
          alarm1Value: false,
          alarm2Value: false,
          'tripAlarmRelay.name': false,
          'relayConfiguration.name': false,
          couplerUsed: false,
          'interfaceCard.name': false,
          'poweringDevice.name': false,
          negativeImpactOnRebootNotes: false,
          latestFileReceived: false,
          dataDue: false,
          notes: false,
          'upcomingVLifeConfig.startDate': false,
          'mostRecentVLifeConfig.startDate': false,
          'mostRecentVLifeConfig.isExpired': false,
          'mostRecentVLifeConfig.isDisabled': false,
          'mostRecentVLifeConfig.deactivationReason.name': false,
          'mostRecentVLifeConfig.vlifeLicence.vlifeLicenceType.id': true,
          installedViperAssetPeriods: false,
          latestIRValue: false,
          percentageIRChange: false,
          mostRecentVLifeConfig: false,
          upcomingVLifeConfig: false,
          historicalVLifeConfigs: false,
          'vLifeTimeline.timeline': false,
        }}
        localeText={{
          noRowsLabel: t('no-assets'),
          toolbarQuickFilterPlaceholder: t('search-assets'),
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        getDetailPanelContent={({ row }) => <CustomerAssetsExpandableRow row={row} />}
        getDetailPanelHeight={() => 'auto'}
      />
    </>
  );
};
