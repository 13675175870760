import React, { createContext, useContext, useState } from 'react';
import { Action } from './Actions';

export const ActionContext = createContext<{
  actions: Action[];
  setActions: React.Dispatch<React.SetStateAction<Action[]>>;
}>({
  actions: [],
  setActions: () => {
    // do nothing
  },
});

export const ActionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [actions, setActions] = useState<Action[]>([]);
  return (
    <ActionContext.Provider value={{ actions, setActions }}>{children}</ActionContext.Provider>
  );
};

export function useActions() {
  return useContext(ActionContext);
}
