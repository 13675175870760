import { useGetViperAssetWarnings } from '~/pages/warnings/useGetViperAssetWarnings';
import { useGetCustomerAssetWarnings } from '~/pages/warnings/useGetCustomerAssetWarnings';
import { WarningLink } from '~/components/WarningLink';
import { useMemo } from 'react';
import { useGetMissingVLifeLicenseWarnings } from '~/pages/warnings/useGetMissingVLifeLicenseWarnings';
import { useGetMissingReadingsWarnings } from '~/pages/warnings/useGetMissingReadingsWarnings';

interface UseGetReportWarningsResponse {
  warnings: WarningLink[];
  loading: boolean;
}

export const useGetReportWarnings = (
  channelId: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined
): UseGetReportWarningsResponse => {
  const { warnings: missingViperAssetWarnings, loading: loadingViperAssetWarnings } =
    useGetViperAssetWarnings(channelId, startDate, endDate);

  const { warnings: missingCustomerAssetWarnings, loading: loadingCustomerAssetWarnings } =
    useGetCustomerAssetWarnings(channelId);

  const { warnings: missingReadingsWarnings, loading: loadingMissingReadingsWarnings } =
    useGetMissingReadingsWarnings(channelId, startDate, endDate);

  const {
    warnings: missingVLifeLicenseWarnings,
    loading: loadingMissingVLifeLicenceWarnings,
  } = useGetMissingVLifeLicenseWarnings(channelId, startDate, endDate);

  const loading =
    loadingViperAssetWarnings ||
    loadingCustomerAssetWarnings ||
    loadingMissingReadingsWarnings ||
    loadingMissingVLifeLicenceWarnings;

  const warnings: WarningLink[] = useMemo(
    () => [
      ...missingCustomerAssetWarnings,
      ...missingViperAssetWarnings,
      ...missingReadingsWarnings,
      ...missingVLifeLicenseWarnings,
    ],
    [
      missingCustomerAssetWarnings,
      missingViperAssetWarnings,
      missingReadingsWarnings,
      missingVLifeLicenseWarnings,
    ]
  );

  return {
    loading,
    warnings,
  };
};
