import React, { Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog } from '~/components/Dialog';
import { Accept, FileError, useDropzone } from 'react-dropzone';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { EnableBlocker } from '~/components/Blocker';
import { UploadIcon } from '~/components/upload/UploadIcon';

export interface UploadDialogProps {
  open: boolean;
  onUpload: (files: File[], formValues?: Record<string, unknown> | undefined) => void;
  onCancel: () => void;
  accept?: Accept;
  extension?: string;
  validationError?: FileError | FileError[] | null;
}

export const UploadDialog: React.FC<UploadDialogProps> = ({ open, onUpload, onCancel }) => {
  const { t } = useTranslation('v-log-upload-dialog');
  const [fileList, setFileList] = useState<File[]>([]);
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      setFileList([]);
    }
  }, [open]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/octet-stream': ['.vlog'],
    },
    validator: (file) => {
      if (!file.name) {
        console.log('unknown');
        return null;
      }
      if (!file.name?.endsWith('.vlog')) {
        return {
          code: 'only-vlog',
          message: 'only vlogs allowed',
        };
      }

      return null;
    },
    onDropAccepted(files) {
      const newFileList = fileList.slice();
      files.forEach((file) => {
        if (!fileList.find((existingFile) => existingFile.name === file.name)) {
          newFileList.push(file);
        }
      });
      setFileList(newFileList);
    },
  });

  return (
    <>
      <EnableBlocker enabled={open && fileList.length > 0} />
      <Dialog
        open={open}
        onConfirm={() => {
          onUpload(fileList);
        }}
        onClose={(confirm) => {
          if (!confirm) {
            onCancel();
          }
        }}
        title={t('title')}
        confirmText={t('confirm-button')}
        cancelText={t('cancel-button')}
        content={
          <section>
            <Box
              {...getRootProps()}
              sx={{
                margin: '2px',
                backgroundImage: isDragActive
                  ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='1' y='1' width='calc(100%25 - 3px)' height='98%25' fill='${encodeURIComponent(
                      theme.palette.highlight.light
                    )}' rx='4' ry='4' stroke='${encodeURIComponent(
                      theme.palette.highlight.dark
                    )}' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
                  : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='1' y='1' width='calc(100%25 - 3px)' height='98%25' fill='none' rx='4' ry='4' stroke='${encodeURIComponent(
                      theme.palette.grey[500]
                    )}' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                padding: '1.5rem 9rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                marginBottom: '1rem',
              }}
            >
              <input {...getInputProps()} />
              <UploadIcon />
              <Typography fontSize='16px' padding={1}>
                <Trans
                  i18nKey='click-to-upload'
                  t={t}
                  components={{
                    u: <u />,
                  }}
                />
              </Typography>
              <Typography fontSize='14px' color={theme.palette.grey[700]}>
                {t('file-spec')}
              </Typography>
            </Box>
            <List>
              {fileList.map((file, index) => (
                <Fragment key={file.name}>
                  {index > 0 ? <Divider /> : null}
                  <ListItem key={file.name} sx={{ minHeight: '2.5rem' }}>
                    <ListItemAvatar>
                      <UploadIcon />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography fontSize='14px' color={theme.palette.grey[700]}>
                        {file.name}
                      </Typography>
                    </ListItemText>
                    <IconButton
                      aria-label={t('remove-file')}
                      onClick={() => {
                        setFileList([
                          ...fileList.slice(0, index),
                          ...fileList.slice(index + 1),
                        ]);
                      }}
                    >
                      <Close
                        sx={{
                          color: (theme) => theme.palette.neutral.contrastText,
                          fontSize: 18,
                        }}
                      />
                    </IconButton>
                  </ListItem>
                </Fragment>
              ))}
            </List>
          </section>
        }
      />
    </>
  );
};
