import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EnrichedCustomerAssetRow } from '~/pages/CustomerAssets/types';
import { DataGridCustomExpander } from '~/components/DataGridCustomExpander';

export const CustomerAssetsRowExpander = (
  props: GridRenderCellParams<EnrichedCustomerAssetRow>
) => {
  const { row } = props;
  const vLifeTimeline = row['vLifeTimeline.timeline'];

  if (!vLifeTimeline || !vLifeTimeline.length) {
    return <></>;
  }

  return <DataGridCustomExpander {...props} />;
};
