import {
  Schema,
  componentTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { extraValidatorTypes } from '~/components/Form/validators';
import { GetCreateViperAssetOptionsQuery } from '~/__generated-asset__/graphql';
import { useTranslation } from 'react-i18next';

interface GetCommercialCreateSchemaProps {
  customers?: GetCreateViperAssetOptionsQuery['customers'];
}

export const useCommercialCreateSchema = ({
  customers,
}: GetCommercialCreateSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  return {
    fields: [
      {
        component: componentTypes.DATE_PICKER,
        name: 'dateOfPurchase',
        label: t('dateOfPurchase'),
        sx: { gridColumnEnd: 'span 2' },
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        ],
      },
      {
        component: componentTypes.SELECT,
        name: 'endClientId',
        label: t('endClient'),
        helperText: t('end-user-helper'),
        options: customers,
        isClearable: true,
        sortAlphabetical: true,
        isSearchable: true,
      },
      {
        component: componentTypes.SELECT,
        name: 'customerId',
        label: t('customer'),
        helperText: t('customer-helper'),
        options: customers,
        isRequired: true,
        sortAlphabetical: true,
        isSearchable: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
    ],
  };
};
