import { formatNumber } from '~/utils/format-number';
import { withSuffix } from '~/components/DataGrid/value-formatters';

interface FormatPercentageIRChangeProps {
  value?: number;
}

export const formatPercentageIRChange = ({ value }: FormatPercentageIRChangeProps) => {
  if (!value) {
    return '';
  }
  return `${value > 0 ? '+' : ''}${withSuffix('%')({ value: formatNumber(value) })}`;
};
