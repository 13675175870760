import { useGetVLifeReports } from '~/queries-asset/channel';

export function useVLifeReportsHistory(channelId: string | undefined) {
  const { data, loading: reportsHistoryLoading } = useGetVLifeReports(channelId);

  const vLifeReports = data?.vlifeReports;

  const sortedReports = vLifeReports
    ? [...vLifeReports]?.sort((a, b) => {
        return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
      })
    : [];

  const previousStartDate = sortedReports?.[0]?.startDate;
  const previousEndDate = sortedReports?.[0]?.endDate;

  return {
    reportsHistoryLoading,
    previousStartDate,
    previousEndDate,
  };
}
