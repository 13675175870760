import { GridFilterOperator } from '@mui/x-data-grid-pro';
import { NegatableNumberRangeInputFilter } from '~/components/DataGrid/filters/NegatableNumberRangeInputFilter';
import { getNumberRangeFilter } from '~/components/DataGrid/filters/filter-functions/get-number-range.filter';
import { getNumberLessThanFilter } from '~/components/DataGrid/filters/filter-functions/get-number-less-than.filter';
import { NegatableNumberInputFilter } from '~/components/DataGrid/filters/NegatableNumberInputFilter';
import { getNumberLessThanOrEqualFilter } from '~/components/DataGrid/filters/filter-functions/get-number-less-than-or-equal.filter';
import { getNumberGreaterThanFilter } from '~/components/DataGrid/filters/filter-functions/get-number-greater-than.filter';
import { getNumberGreaterThanOrEqualFilter } from '~/components/DataGrid/filters/filter-functions/get-number-greater-than-or-equal.filter';
import { getNumberEqualsFilter } from '~/components/DataGrid/filters/filter-functions/get-number-equals.filter';
import { getStringContainsFilter } from '~/components/DataGrid/filters/filter-functions/get-string-contains.filter';
import { NegatableTextInputFilter } from '~/components/DataGrid/filters/NegatableTextInputFilter';
import { getStringStartsWithFilter } from '~/components/DataGrid/filters/filter-functions/get-string-starts-with.filter';
import { getStringEqualsFilter } from '~/components/DataGrid/filters/filter-functions/get-string-equals.filter';
import { getDateEqualsFilter } from '~/components/DataGrid/filters/filter-functions/get-date-equals.filter';
import { NegatableDateInputFilter } from '~/components/DataGrid/filters/NegatableDateInputFilter';
import { getDateLessThanFilter } from '~/components/DataGrid/filters/filter-functions/get-date-less-than.filter';
import { getDateLessThanEqualsFilter } from '~/components/DataGrid/filters/filter-functions/get-date-less-than-equals.filter';
import { getDateGreaterThanFilter } from '~/components/DataGrid/filters/filter-functions/get-date-greater-than.filter';
import { getDateGreaterThanEqualsFilter } from '~/components/DataGrid/filters/filter-functions/get-date-greater-than-equals.filter';
import { getDateRangeFilter } from '~/components/DataGrid/filters/filter-functions/get-date-range.filter';
import { NegatableDateRangeInputFilter } from '~/components/DataGrid/filters/NegatableDateRangeInputFilter';
import { useTranslation } from 'react-i18next';
import { NegatableBooleanInputFilter } from '~/components/DataGrid/filters/NegatableBooleanInputFilter';
import { getBooleanEqualsFilter } from '~/components/DataGrid/filters/filter-functions/get-boolean-equals.filter';
import { useFilterTooltips } from '~/components/DataGrid/filters/filter-tooltip-mappers/useFilterTooltips';

type FilterOperatorMap = {
  // Number
  NegatableNumberRange: GridFilterOperator;
  NegatableNumberLessThan: GridFilterOperator;
  NegatableNumberLessThanOrEqual: GridFilterOperator;
  NegatableNumberGreaterThan: GridFilterOperator;
  NegatableNumberGreaterThanOrEqual: GridFilterOperator;
  NegatableNumberEqual: GridFilterOperator;
  // String
  NegatableStringContains: GridFilterOperator;
  NegatableStringStartsWith: GridFilterOperator;
  NegatableStringEquals: GridFilterOperator;
  // Date
  NegatableDateEquals: GridFilterOperator;
  NegatableDateLessThan: GridFilterOperator;
  NegatableDateLessThanEquals: GridFilterOperator;
  NegatableDateGreaterThan: GridFilterOperator;
  NegatableDateGreaterThanEquals: GridFilterOperator;
  NegatableDateRange: GridFilterOperator;
  // Boolean
  NegatableBooleanEquals: GridFilterOperator;
};

export function useNegatableFilterOperators() {
  const { t } = useTranslation('filter-operators');
  const {
    getNumberFilterTooltip,
    getNumberRangeFilterTooltip,
    getStringFilterTooltip,
    getDateFilterTooltip,
    getDateRangeFilterTooltip,
    getBooleanFilterTooltip,
  } = useFilterTooltips();

  const NegatableFilterOperators: FilterOperatorMap = {
    NegatableNumberRange: {
      label: t('between'),
      value: 'number-between',
      getApplyFilterFn: getNumberRangeFilter,
      getValueAsString: getNumberRangeFilterTooltip,
      InputComponent: NegatableNumberRangeInputFilter,
    },
    NegatableNumberLessThan: {
      label: '<',
      value: 'number-less-than',
      getApplyFilterFn: getNumberLessThanFilter,
      InputComponent: NegatableNumberInputFilter,
      getValueAsString: getNumberFilterTooltip,
    },
    NegatableNumberLessThanOrEqual: {
      label: '<=',
      value: 'number-less-than-equals',
      getApplyFilterFn: getNumberLessThanOrEqualFilter,
      InputComponent: NegatableNumberInputFilter,
      getValueAsString: getNumberFilterTooltip,
    },
    NegatableNumberGreaterThan: {
      label: '>',
      value: 'number-greater-than',
      getApplyFilterFn: getNumberGreaterThanFilter,
      InputComponent: NegatableNumberInputFilter,
      getValueAsString: getNumberFilterTooltip,
    },
    NegatableNumberGreaterThanOrEqual: {
      label: '>=',
      value: 'number-greater-than-equals',
      getApplyFilterFn: getNumberGreaterThanOrEqualFilter,
      InputComponent: NegatableNumberInputFilter,
      getValueAsString: getNumberFilterTooltip,
    },
    NegatableNumberEqual: {
      label: '=',
      value: 'number-equals',
      getApplyFilterFn: getNumberEqualsFilter,
      InputComponent: NegatableNumberInputFilter,
      getValueAsString: getNumberFilterTooltip,
    },
    NegatableStringContains: {
      label: t('contains'),
      value: 'contains',
      getApplyFilterFn: getStringContainsFilter,
      InputComponent: NegatableTextInputFilter,
      getValueAsString: getStringFilterTooltip,
    },
    NegatableStringStartsWith: {
      label: t('starts-with'),
      value: 'string-starts-with',
      getApplyFilterFn: getStringStartsWithFilter,
      InputComponent: NegatableTextInputFilter,
      getValueAsString: getStringFilterTooltip,
    },
    NegatableStringEquals: {
      label: t('equals'),
      value: 'string-equals',
      getApplyFilterFn: getStringEqualsFilter,
      InputComponent: NegatableTextInputFilter,
      getValueAsString: getStringFilterTooltip,
    },
    NegatableDateEquals: {
      label: t('equals'),
      value: 'date-equals',
      getApplyFilterFn: getDateEqualsFilter,
      InputComponent: NegatableDateInputFilter,
      getValueAsString: getDateFilterTooltip,
    },
    NegatableDateLessThan: {
      label: '<',
      value: 'date-less-than',
      getApplyFilterFn: getDateLessThanFilter,
      InputComponent: NegatableDateInputFilter,
      getValueAsString: getDateFilterTooltip,
    },
    NegatableDateLessThanEquals: {
      label: '<=',
      value: 'date-less-than-equals',
      getApplyFilterFn: getDateLessThanEqualsFilter,
      InputComponent: NegatableDateInputFilter,
      getValueAsString: getDateFilterTooltip,
    },
    NegatableDateGreaterThan: {
      label: '>',
      value: 'date-greater-than',
      getApplyFilterFn: getDateGreaterThanFilter,
      InputComponent: NegatableDateInputFilter,
      getValueAsString: getDateFilterTooltip,
    },
    NegatableDateGreaterThanEquals: {
      label: '>=',
      value: 'date-greater-than-equals',
      getApplyFilterFn: getDateGreaterThanEqualsFilter,
      InputComponent: NegatableDateInputFilter,
      getValueAsString: getDateFilterTooltip,
    },
    NegatableDateRange: {
      label: t('between'),
      value: 'date-between',
      getApplyFilterFn: getDateRangeFilter,
      InputComponent: NegatableDateRangeInputFilter,
      getValueAsString: getDateRangeFilterTooltip,
    },
    NegatableBooleanEquals: {
      label: '=',
      value: 'boolean-equals',
      getApplyFilterFn: getBooleanEqualsFilter,
      InputComponent: NegatableBooleanInputFilter,
      getValueAsString: getBooleanFilterTooltip,
    },
  };

  return {
    negatableNumberFilterOperators: [
      NegatableFilterOperators.NegatableNumberEqual,
      NegatableFilterOperators.NegatableNumberGreaterThan,
      NegatableFilterOperators.NegatableNumberGreaterThanOrEqual,
      NegatableFilterOperators.NegatableNumberLessThan,
      NegatableFilterOperators.NegatableNumberLessThanOrEqual,
      NegatableFilterOperators.NegatableNumberRange,
    ],
    negatableStringFilterOperators: [
      NegatableFilterOperators.NegatableStringContains,
      NegatableFilterOperators.NegatableStringStartsWith,
      NegatableFilterOperators.NegatableStringEquals,
    ],
    negatableDateFilterOperators: [
      NegatableFilterOperators.NegatableDateEquals,
      NegatableFilterOperators.NegatableDateGreaterThan,
      NegatableFilterOperators.NegatableDateGreaterThanEquals,
      NegatableFilterOperators.NegatableDateLessThan,
      NegatableFilterOperators.NegatableDateLessThanEquals,
      NegatableFilterOperators.NegatableDateRange,
    ],
    negatableBooleanFilterOperators: [NegatableFilterOperators.NegatableBooleanEquals],
  };
}
