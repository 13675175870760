import React from 'react';
import { CreateMaintenanceLogDialog } from '../../create-edit-dialogs/MaintenanceLog/CreateMaintenanceLogDialog';
import { UpdateMaintenanceLogDialog } from '../../create-edit-dialogs/MaintenanceLog/UpdateMaintenanceLogDialog';
import { Box, Button, Typography } from '@mui/material';
import { Table } from '~/components/Table/Table';
import { useMaintenanceLog } from './useMaintenanceLog';
import { ACTION_EDIT } from '~/components/ActionMenu';
import { GetViperAssetQuery } from '~/__generated-asset__/graphql';
import { useTranslation } from 'react-i18next';

const actionMenu = {
  label: 'actions',
  actionMenu: [ACTION_EDIT],
};

export const MaintenanceLog: React.FC<{ viperAsset: GetViperAssetQuery['viperAsset'] }> = ({
  viperAsset,
}) => {
  const { t } = useTranslation('viper-asset');

  const {
    handleMaintenanceLogActionClick,
    setShowCreateMaintenanceLogDialog,
    setShowMaintenanceLogDialog,
    showCreateMaintenanceLogDialog,
    showMaintenanceLogDialog,
    maintenanceLogs,
    maintenanceLogsDetails,
    id,
  } = useMaintenanceLog(viperAsset);

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
        <Typography variant='h3'>{t('maintenance-logs')}</Typography>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => setShowCreateMaintenanceLogDialog(true)}
        >
          {t('create-maintenance-log')}
        </Button>
      </Box>
      <Table
        data={maintenanceLogs}
        omittedKeys={['_id']}
        translationKey={'viper-asset'}
        nestedData={maintenanceLogsDetails}
        actionMenu={actionMenu}
        onActionClick={handleMaintenanceLogActionClick}
      />
      <CreateMaintenanceLogDialog
        open={showCreateMaintenanceLogDialog}
        onClose={() => {
          setShowCreateMaintenanceLogDialog(false);
        }}
        id={id}
      />
      <UpdateMaintenanceLogDialog
        open={!!showMaintenanceLogDialog}
        onClose={() => {
          setShowMaintenanceLogDialog(undefined);
        }}
        id={id}
        maintenanceLogs={viperAsset?.maintenanceLogs}
        editId={showMaintenanceLogDialog}
      />
    </>
  );
};
