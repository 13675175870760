import { useMutation, useQuery } from '@apollo/client';
import {
  CreateVLifeConfigDocument,
  GetCreateViperServiceOptionsDocument,
} from '~/__generated-asset__/graphql';

export function useGetCreateViperServiceOptions(options?: { skip?: boolean }) {
  return useQuery(GetCreateViperServiceOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useCreateVLifeConfig() {
  return useMutation(CreateVLifeConfigDocument);
}
