import React from 'react';
import { CircularLoadingSkeleton } from '~/components/LoadingSkeleton';
import { Typography } from '@mui/material';
import { ScatterDygraphChartCard } from '~/pages/Dashboard/charts/ScatterDygraphChartCard';
import { useTranslation } from 'react-i18next';
import { dygraphs } from 'dygraphs';

export const ChartWithLoadingAndErrorMessages: React.FC<{
  loading: boolean;
  maxIrCapError: boolean;
  error: boolean;
  csv: string | undefined;
  yAxisScale: 'logarithmic' | 'linear';
  chartHeight?: number;
  graphOptions?: dygraphs.Options;
}> = ({ loading, maxIrCapError, error, csv, yAxisScale, chartHeight, graphOptions = {} }) => {
  const { t } = useTranslation('ir-graph');

  if (loading) {
    return <CircularLoadingSkeleton />;
  }

  if (maxIrCapError) {
    return <Typography color='error'>{t('charts::max-ir-cap-missing')}</Typography>;
  }

  if (error) {
    return <Typography color='error'>{t('viper::unexpected-error')}</Typography>;
  }

  if (!loading && csv?.length) {
    return (
      <ScatterDygraphChartCard
        csv={csv}
        yAxisScale={yAxisScale}
        chartHeight={chartHeight}
        graphOptions={graphOptions}
      />
    );
  }

  return <Typography>{t('viper::no-data')}</Typography>;
};
