import React from 'react';
import { UploadFile } from '@mui/icons-material';
import { Avatar } from '@mui/material';

export const UploadIcon: React.FC = () => {
  return (
    <Avatar
      sx={{
        color: (theme) => theme.palette.primary.main,
        bgcolor: (theme) => theme.palette.highlight.main,
      }}
    >
      <UploadFile />
    </Avatar>
  );
};
