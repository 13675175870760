export default {
  darkblue: {
    '300': '#063b68',
    '500': '#002244',
    '700': '#00001e',
  },
  blue: {
    '100': '#C0E0FF',
    '300': '#84C1FF',
    '500': '#47A3FF',
    '700': '#3172B2',
  },
  yellow: {
    '300': '#feefb7',
    '500': '#FFDB5B',
    '700': '#ffca27',
  },
  red: {
    '300': '#ED4656',
    '500': '#DC3545',
    '700': '#CB2434',
  },
  purple: {
    '500': '#A435DC',
  },
  grey: {
    '200': '#FEFEFE',
    '300': '#F9F9F9',
    '400': '#E3E3E3',
    '500': '#D1D5DB',
    '600': '#a0a0a0',
    '700': '#707070',
  },
  green: {
    '300': '#53B86A',
    '500': '#28A745',
    '700': '#1C7430',
  },
  orange: {
    '300': '#FFAD33',
    '500': '#FF9900',
    '700': '#B26B00',
  },
  lightblue: {
    '50': '#EDF4FC',
    '100': '#E4EEFF',
    '600': '#1976D2',
  },
  white: '#ffffff',
  black: '#000000',
};
