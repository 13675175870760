import { useMemo } from 'react';
import { LicenceInsightsRow } from '~/pages/LicenceInsights/types';
import { flattenVLifeTimelineRows } from '~/pages/LicenceInsights/flattenVLifeTimelineRows';
import { useGetChannelsWithVLifeTimelines } from '~/queries-asset/channel';

interface UseGetLicenceInsightsRowsResponse {
  rows: LicenceInsightsRow[];
  loading: boolean;
}

export const useLicenceInsightsRows = (): UseGetLicenceInsightsRowsResponse => {
  // Fetch channels with timelines attached
  const { data, loading } = useGetChannelsWithVLifeTimelines();

  // Flatten out
  const rows: LicenceInsightsRow[] = useMemo(
    () => data?.channels.reduce(flattenVLifeTimelineRows, []) ?? [],
    [data]
  );

  return {
    rows,
    loading,
  };
};
