export const VIPER_COMPANY_ID = '0';

export const measureLists = {
  LIMRESISTANCE: 'LIMRESISTANCE',
  SOFTWAREVERSION: 'SOFTWAREVERSION',
  MESSAGE: 'MESSAGE',
};

export const statusColors: Record<
  string,
  'error' | 'success' | 'info' | 'warning' | 'primary' | 'secondary' | 'disabled' | undefined
> = {
  '0': 'secondary',
  '1': 'info',
  '2': 'success',
  '3': 'warning',
  '4': 'disabled',
  '5': 'error',
};
