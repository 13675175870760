import { ChannelWithVLifeTimeline, LicenceInsightsRow } from '~/pages/LicenceInsights/types';
import { VLifeConfig, VLifeTimelinePeriod } from '~/__generated-asset__/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';

const getConfig = (
  period: VLifeTimelinePeriod,
  channel: ChannelWithVLifeTimeline
):
  | Maybe<
      Pick<
        VLifeConfig,
        'startDate' | 'id' | 'flaggedForDecommission' | 'flaggedForDecommissionDate'
      >
    >
  | undefined =>
  (channel.historicalVLifeConfigs ?? []).find(
    (config) => config?.startDate === period.startDate
  );

export const flattenVLifeTimelineRows = (
  allRows: LicenceInsightsRow[],
  channelWithTimeline: ChannelWithVLifeTimeline
) => {
  const { vLifeTimeline, ...channelDetails } = channelWithTimeline;
  const channelInsightsRows: LicenceInsightsRow[] =
    vLifeTimeline?.timeline.map(
      (vLifeTimelinePeriod: VLifeTimelinePeriod): LicenceInsightsRow => {
        const configDetails = getConfig(vLifeTimelinePeriod, channelDetails);
        return {
          id: `${channelDetails.name} ${vLifeTimelinePeriod.startDate} ${vLifeTimelinePeriod.endDate}`,
          status: vLifeTimelinePeriod.status,
          startDate: vLifeTimelinePeriod.startDate,
          endDate: vLifeTimelinePeriod.endDate,
          channelId: channelDetails.id,
          channelName: channelDetails.name,
          customerName: channelDetails.customer.name,
          platformName: channelDetails.platform?.name,
          fieldName: channelDetails.field.name,
          gain: vLifeTimelinePeriod.gain,
          attrition: vLifeTimelinePeriod.attrition,
          flaggedForDecommission: configDetails?.flaggedForDecommission ?? false,
          flaggedForDecommissionDate: configDetails?.flaggedForDecommissionDate ?? undefined,
        };
      }
    ) ?? [];
  return [...allRows, ...channelInsightsRows];
};
