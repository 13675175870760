import { Box, SxProps, Link, CircularProgress } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { WarningWithTooltip } from '~/components/WarningWithTooltip';
import { PathValue, buildPath } from '~/routes/paths';

export interface CellLinkProps {
  path: PathValue;
  param?: Record<string, string>;
  content?: React.ReactNode;
  warning?: React.ReactNode;
  loading?: boolean;
  sx?: SxProps;
}

export const CellLink: React.FC<CellLinkProps> = ({
  path,
  param,
  content,
  sx,
  warning,
  loading = false,
  ...rest
}) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'inline', ...sx }} alignItems='center'>
      <Link
        type='link'
        component='button'
        onClick={() => navigate(buildPath(path, param))}
        {...rest}
      >
        {content}
      </Link>
      {loading && <CircularProgress size={14} sx={{ ml: 0.5 }} />}
      {!!warning && !loading && <WarningWithTooltip title={warning} sx={{ ml: 0.5 }} />}
    </Box>
  );
};
