import { useGetMonthlyDateRanges } from '~/pages/Dashboard/useGetMonthlyDateRanges';
import { useGetGainAttritionValues } from '~/pages/Dashboard/useGetGainAttritionValues';
import { BoxPlotDatum } from '@nivo/boxplot';
import { useMemo } from 'react';
import { DateRange } from '~/common/types';

interface UseGainAttritionGraphResponse {
  loading: boolean;
  data: BoxPlotDatum[];
  xAxis: {
    minimum: number;
    maximum: number;
  };
  gainCount: number;
  attritionCount: number;
}

interface GainAttritionReducerInput {
  data: BoxPlotDatum[];
  runningTotal: number;
  minimum: number;
  maximum: number;
}

export const getMonthYearName = (date: Date) => {
  const dateParts = date.toDateString().split(' ');
  return `${dateParts[1]} ${dateParts[3].slice(2, 4)}`;
};

export const getRunningTotalBySubgroup = (data: BoxPlotDatum[], subgroup: string) => {
  // filter for subgroup
  const subgroupData = data.filter((d) => d.subgroup === subgroup);
  // get the difference between every two values
  const differences = subgroupData.reduce(
    (acc: number[], datum: BoxPlotDatum, index: number, array: BoxPlotDatum[]) => {
      // if the index is even return acc
      if (index % 2 === 0) {
        return acc;
      }
      // if the index is odd return the difference between the next datum and the current one
      return [...acc, Number(datum.value) - Number(array[index - 1].value)];
    },
    []
  );
  // return the the absolute values of the sum of the differences
  return Math.abs(differences.reduce((acc, val) => acc + val, 0));
};

export const useGainAttritionGraph = (dateRange: DateRange): UseGainAttritionGraphResponse => {
  // Generate month date ranges for current financial year
  const { dateRanges } = useGetMonthlyDateRanges(dateRange);

  // Get the gains / attrition data
  const { loading, previousTotal, gainAttritionWindows } = useGetGainAttritionValues({
    dateRanges,
  });

  const { data, minimum, maximum } = useMemo(
    () =>
      gainAttritionWindows.reduce(
        (
          { data, runningTotal, minimum, maximum }: GainAttritionReducerInput,
          { dateRange, gains, attrition }
        ): GainAttritionReducerInput => {
          const monthYearName = getMonthYearName(dateRange.startDate);

          data.push({
            group: monthYearName,
            subgroup: 'Gain',
            value: runningTotal,
          });

          data.push({
            group: monthYearName,
            subgroup: 'Gain',
            value: runningTotal + gains,
          });

          data.push({
            group: monthYearName,
            subgroup: 'Attrition',
            value: runningTotal + gains,
          });

          data.push({
            group: monthYearName,
            subgroup: 'Attrition',
            value: runningTotal + gains - attrition,
          });

          minimum = Math.min(minimum, runningTotal);
          maximum = Math.max(maximum, runningTotal + gains);
          runningTotal += gains - attrition;

          return { data, runningTotal, minimum, maximum };
        },
        {
          data: [],
          runningTotal: previousTotal,
          minimum: previousTotal,
          maximum: previousTotal,
        }
      ),
    [gainAttritionWindows, previousTotal]
  );

  // Add previous total bar to start of chart
  const dataWithPreviousValuesBar = [
    {
      group: 'Prev',
      subgroup: 'Gain',
      value: minimum - 1,
    },
    {
      group: 'Prev',
      subgroup: 'Gain',
      value: previousTotal,
    },
    ...data,
  ];

  const gainCount = getRunningTotalBySubgroup(data, 'Gain');
  const attritionCount = getRunningTotalBySubgroup(data, 'Attrition');

  return {
    loading,
    data: dataWithPreviousValuesBar,
    xAxis: {
      minimum: minimum - 1,
      maximum: maximum + 1,
    },
    gainCount,
    attritionCount,
  };
};
