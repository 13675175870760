import { InstalledViperAssetPeriod } from '~/__generated-asset__/graphql';

interface FormatInstalledViperAssetPeriodsProps {
  value?: InstalledViperAssetPeriod[];
}

export const formatInstalledViperAssetPeriods = ({
  value,
}: FormatInstalledViperAssetPeriodsProps) => {
  return value && value.length ? value.length : '';
};
