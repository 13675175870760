import { useMutation, useQuery } from '@apollo/client';
import {
  GetCreateCompanyOptionsDocument,
  CreateCompanyDocument,
  GetCustomersDocument,
  GetCustomerDocument,
  UpdateCompanyDocument,
} from '~/__generated-asset__/graphql';

export function useGetCreateCompanyOptions(options?: { skip?: boolean }) {
  return useQuery(GetCreateCompanyOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useGetCompany(id: string, options?: { skip?: boolean }) {
  return useQuery(GetCustomerDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
    variables: { id },
  });
}

export function useGetCustomers() {
  return useQuery(GetCustomersDocument);
}

export function useCreateCompany() {
  return useMutation(CreateCompanyDocument);
}

export function useUpdateCompany() {
  return useMutation(UpdateCompanyDocument);
}
