import {
  NegatableBooleanFilters,
  NegatableDateFilters,
  NegatableDateRangeFilters,
  NegatableNumberFilters,
  NegatableNumberRangeFilters,
  NegatableStringFilters,
} from '~/components/DataGrid/filters/types';
import { useTranslation } from 'react-i18next';
import { formatDate } from '~/utils/date-utils';

interface FilterTooltips {
  getNumberFilterTooltip: (filterValues: NegatableNumberFilters) => string;
  getNumberRangeFilterTooltip: (filterValues: NegatableNumberRangeFilters) => string;
  getStringFilterTooltip: (filterValues: NegatableStringFilters) => string;
  getDateFilterTooltip: (filterValues: NegatableDateFilters) => string;
  getDateRangeFilterTooltip: (filterValues: NegatableDateRangeFilters) => string;
  getBooleanFilterTooltip: (filterValues: NegatableBooleanFilters) => string;
}

export const useFilterTooltips = (): FilterTooltips => {
  const { t } = useTranslation('filter-operators');
  const notSet = `(${t('not-set')})`;
  const and = t('and');
  const min = t('min');
  const max = t('max');

  const getNegatedSuffix = (isNegated: boolean) => {
    const negated = t('negated');
    return isNegated ? ` (${negated})` : '';
  };

  /*
  Examples:

  "125"
  "125 (negated)"
  "(not set)"
  "(not set) (negated)"
 */
  const getNumberFilterTooltip = ({
    comparatorValue,
    isNegated,
  }: NegatableNumberFilters): string => {
    const valueString = comparatorValue ? `${comparatorValue}` : notSet;
    const suffix = getNegatedSuffix(isNegated);
    return `${valueString}${suffix}`;
  };

  /*
  Examples:

  "min and max"
  "min and 125"
  "123 and max"
  "min and max (negated)"
  "min and 125 (negated)"
  "123 and max (negated)"
 */
  const getNumberRangeFilterTooltip = ({
    lowerBound,
    upperBound,
    isNegated,
  }: NegatableNumberRangeFilters): string => {
    const lowerBoundString = lowerBound ? `${lowerBound}` : min;
    const upperBoundString = upperBound ? `${upperBound}` : max;
    const suffix = getNegatedSuffix(isNegated);
    return `${lowerBoundString} ${and} ${upperBoundString}${suffix}`;
  };

  /*
  Examples:

  "(not set)"
  "testing"
  "(not set) (negated)"
  "testing (negated)"
 */
  const getStringFilterTooltip = ({
    comparatorValue,
    isNegated,
  }: NegatableStringFilters): string => {
    const filterValueString = comparatorValue ? comparatorValue : notSet;
    const suffix = getNegatedSuffix(isNegated);
    return `${filterValueString}${suffix}`;
  };

  /*
  Examples:

  "(not set)"
  "25/12/2023"
  "(not set) (negated)"
  "25/12/2023 (negated)"
 */
  const getDateFilterTooltip = ({
    comparatorDate,
    isNegated,
  }: NegatableDateFilters): string => {
    const dateString = comparatorDate ? formatDate(comparatorDate.toISOString()) : notSet;
    const suffix = getNegatedSuffix(isNegated);
    return `${dateString}${suffix}`;
  };

  /*
  Examples:

  "min and max"
  "min and 25/12/2023"
  "25/12/2023 and max"
  "min and max (negated)"
  "min and 25/12/2023 (negated)"
  "25/12/2023 and max (negated)"
 */
  const getDateRangeFilterTooltip = ({
    startDate,
    endDate,
    isNegated,
  }: NegatableDateRangeFilters): string => {
    const startDateString = startDate ? formatDate(startDate.toISOString()) : min;
    const endDateString = endDate ? formatDate(endDate.toISOString()) : max;
    const suffix = getNegatedSuffix(isNegated);
    return `${startDateString} ${and} ${endDateString}${suffix}`;
  };

  /*
  Examples:

  "(not set)"
  "True"
  "False"
  "True (negated)"
  "False (negated)"
  "(not set) (negated)"
 */
  const getBooleanFilterTooltip = ({
    comparatorValue,
    isNegated,
  }: NegatableBooleanFilters): string => {
    let valueString;
    if (comparatorValue === undefined) {
      valueString = notSet;
    } else {
      valueString = comparatorValue ? 'True' : 'False';
    }
    const suffix = getNegatedSuffix(isNegated);
    return `${valueString}${suffix}`;
  };

  return {
    getNumberFilterTooltip,
    getNumberRangeFilterTooltip,
    getStringFilterTooltip,
    getDateFilterTooltip,
    getDateRangeFilterTooltip,
    getBooleanFilterTooltip,
  };
};
