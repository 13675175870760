import { ValidatorMapper } from '@data-driven-forms/react-form-renderer';
import {
  createFutureDateFieldValidator,
  createNotFutureDateFieldValidator,
  createValidDateFieldValidator,
} from './date-validators';

export const extraValidatorTypes = {
  VALID_DATE: 'valid-date',
  FUTURE_DATE: 'future-date',
  NOT_FUTURE_DATE: 'not-future-date-date',
};

export const validatorMapper: ValidatorMapper = {
  [extraValidatorTypes.VALID_DATE]: createValidDateFieldValidator,
  [extraValidatorTypes.FUTURE_DATE]: createFutureDateFieldValidator,
  [extraValidatorTypes.NOT_FUTURE_DATE]: createNotFutureDateFieldValidator,
};
