import { VLifeConfigWithStatus } from '~/pages/CustomerAssets/types';
import { VLifeStatus } from '~/__generated-asset__/graphql';

export interface NegatableVLifeLicenceChangeRangeFilters {
  isNegated: boolean;
  fromStatuses: VLifeStatus[];
  toStatuses: VLifeStatus[];
  startDate?: Date;
  endDate?: Date;
}

export interface VLifeStatusWindow {
  startDate?: Date;
  endDate?: Date;
  status: VLifeStatus;
  config?: VLifeConfigWithStatus;
  gain?: boolean;
  attrition?: boolean;
}

export const nonPaidVLifeStatuses = [
  VLifeStatus.ActiveFoc,
  VLifeStatus.ActiveTrial,
  VLifeStatus.ActiveNonRefundable,
  VLifeStatus.Expired,
  VLifeStatus.Disabled,
  VLifeStatus.NotSetUp,
  VLifeStatus.Suspended,
];
