import React from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { TextFieldProps } from '@mui/material/TextField';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { NegatableStringFilters } from '~/components/DataGrid/filters/types';
import { CustomInput } from '~/components/Table/components/CustomInput';
import { useFilterDebounceAndApply } from '~/components/DataGrid/filters/useFilterDebounceAndApply';
import { useTranslation } from 'react-i18next';

const defaultValues: NegatableStringFilters = {
  isNegated: false,
  comparatorValue: undefined,
};

export const NegatableTextInputFilter = ({ item, applyValue }: GridFilterInputValueProps) => {
  const { t } = useTranslation();
  const { values, updateFilterValue } = useFilterDebounceAndApply<NegatableStringFilters>(
    item,
    applyValue,
    defaultValues
  );

  const handleValueFilterChange: TextFieldProps['onChange'] = (event) => {
    const newValue = `${event.target.value}`;
    updateFilterValue({ comparatorValue: newValue });
  };

  const handleNegationFilterChange = (): void => {
    updateFilterValue({ isNegated: !values.isNegated });
  };

  return (
    <Box>
      <CustomInput
        name='text'
        placeholder={t('filter-inputs::text')}
        value={values.comparatorValue}
        onChange={handleValueFilterChange}
        type='text'
        style={{ flex: 1 }}
      />
      <FormControlLabel
        sx={{ ml: 2 }}
        label={t('filter-inputs::negate')}
        control={
          <Switch
            checked={values.isNegated}
            onChange={handleNegationFilterChange}
            size='small'
          />
        }
      />
    </Box>
  );
};
