import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNegatableFilterOperators } from '~/components/DataGrid/filters/filter-operators';
import { formatDateTimeUTC } from '~/utils/date-utils';

export function useMessageListColumns() {
  const { t } = useTranslation('select-messages');

  const {
    negatableNumberFilterOperators,
    negatableStringFilterOperators,
    negatableDateFilterOperators,
  } = useNegatableFilterOperators();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('id'),
        minWidth: 50,
        maxWidth: 100,
        flex: 1,
        filterOperators: negatableNumberFilterOperators,
      },
      {
        field: 'message',
        headerName: t('message'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 3,
      },
      {
        field: 'date',
        headerName: t('date'),
        valueFormatter: ({ value }) => formatDateTimeUTC(value),
        filterOperators: negatableDateFilterOperators,
        minWidth: 140,
        flex: 1,
      },
    ],
    [
      t,
      negatableNumberFilterOperators,
      negatableStringFilterOperators,
      negatableDateFilterOperators,
    ]
  );

  return { columns };
}
