import {
  NegatableDateRangeFilters,
  TypedGridFilterItem,
} from '~/components/DataGrid/filters/types';
import {
  GetApplyFilterFnLegacy,
  GridCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { formatDateYYYYMMDD } from '~/components/DataGrid/filters/utils';

export const getDateRangeFilter: GetApplyFilterFnLegacy = ({
  value: filterValues,
}: TypedGridFilterItem<NegatableDateRangeFilters>) => {
  if (filterValues === undefined) {
    return null;
  }

  const { startDate, endDate, isNegated } = filterValues;

  if (startDate === undefined && endDate === undefined) {
    // No lowerbound or upperbound set - don't filter out anything
    return null;
  }

  return ({ value }: GridCellParams<GridValidRowModel, Date | string>) => {
    const dateValue = new Date(formatDateYYYYMMDD(value));
    const formattedStartDate = new Date(formatDateYYYYMMDD(startDate));
    const formattedEndDate = new Date(formatDateYYYYMMDD(endDate));
    let keepItemIn = true;

    // Apply filtering logic based on filter fields that are set
    if (startDate !== undefined && endDate === undefined) {
      // Only lowerbound set, filter out anything smaller
      keepItemIn = dateValue >= formattedStartDate;
    } else if (endDate !== undefined && startDate === undefined) {
      // Only upperbound set, filter out anything higher
      keepItemIn = dateValue <= formattedEndDate;
    } else if (endDate !== undefined && startDate !== undefined) {
      // Both bounds set, filter out anything that doesn't fall between
      keepItemIn = dateValue >= formattedStartDate && dateValue <= formattedEndDate;
    }

    // Apply negation if present
    return isNegated ? !keepItemIn : keepItemIn;
  };
};
