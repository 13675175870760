import { NegatableBooleanFilters } from '~/components/DataGrid/filters/types';
import { useTranslation } from 'react-i18next';
import { useFilterDebounceAndApply } from '~/components/DataGrid/filters/useFilterDebounceAndApply';
import { Box, FormControlLabel, SelectChangeEvent, Switch } from '@mui/material';
import React from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { FilterSelect } from '~/components/DataGrid/filters/form-controls/FilterSelect';

const defaultValues: NegatableBooleanFilters = {
  isNegated: false,
  comparatorValue: undefined,
};

const mapBooleanToString = (value: boolean | undefined): string | undefined => {
  if (value === undefined) {
    return undefined;
  }
  return value ? 'true' : 'false';
};

export const NegatableBooleanInputFilter = ({
  item,
  applyValue,
}: GridFilterInputValueProps) => {
  const { t } = useTranslation();
  const { values, updateFilterValue } = useFilterDebounceAndApply<NegatableBooleanFilters>(
    item,
    applyValue,
    defaultValues
  );

  const handleValueFilterChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    let newValue: boolean | undefined;
    if (value === '') {
      newValue = undefined;
    } else {
      newValue = `${value}`.toLowerCase() === 'true';
    }
    updateFilterValue({ comparatorValue: newValue });
  };

  const handleNegationFilterChange = (): void => {
    updateFilterValue({ isNegated: !values.isNegated });
  };

  return (
    <Box>
      <FilterSelect
        value={mapBooleanToString(values.comparatorValue)}
        onChange={handleValueFilterChange}
        options={[
          { label: 'True', value: 'true' },
          { label: 'False', value: 'false' },
        ]}
        placeholder={t('filter-inputs::boolean')}
      />
      <FormControlLabel
        sx={{ ml: 2 }}
        label={t('filter-inputs::negate')}
        control={
          <Switch
            checked={values.isNegated}
            onChange={handleNegationFilterChange}
            size='small'
          />
        }
      />
    </Box>
  );
};
