import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDeleteVLifeReportForm, useReviewVLifeReportForm } from '~/queries-asset/channel';
import { withErrorHandling } from '~/components/Form/SaveError';
import { useReviewVLifeReportFormSchema } from './useReviewVLifeReportFormSchema';
import { VLifeReportLogMessageCreateInput } from '~/__generated-asset__/graphql';
import { formDefaults } from './VLifeReports/panes/formDefaults';

// t('date')
// t('message')

export function useReviewVLifeReportFormHook() {
  const { reportForm, schema, loading } = useReviewVLifeReportFormSchema();

  const { reportFormId } = useParams();
  const [deleteReportForm, deleteError] = withErrorHandling(useDeleteVLifeReportForm());
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();

  // save

  const [showConfirmReview, setShowConfirmReview] = useState<'approve' | 'review'>();
  const [saving, setSaving] = useState(false);
  const [values, setValues] = useState<Record<string, unknown>>({});

  const [reviewReportForm, reviewReportFormError] = withErrorHandling(
    useReviewVLifeReportForm()
  );

  const onCancel = useCallback(() => {
    // set saving to true so the blocker is disabled
    setSaving(true);
    // allow page to rerender before navigating, so the blocker is actually disabled
    setTimeout(() => navigate(formDefaults.review.path), 0);
  }, [navigate]);

  const onSendForApproval = () => {
    setShowConfirmReview('approve');
  };

  const onSendForReview = () => {
    setShowConfirmReview('review');
  };

  const handleConfirmReview = useCallback(async () => {
    setSaving(true);
    try {
      if (reportFormId) {
        const result = await reviewReportForm({
          variables: {
            id: reportFormId,
            vlifeReportFormReviewInput: {
              approverId: values.approver as string,
              logs: values.logs as VLifeReportLogMessageCreateInput[],
              overallIRIncrease: values.overallIRIncrease as number,
              suggestedReviewerId: values.suggestedReviewer as string,
              comment: values.comment as string,
              noChangeRequired: values.noChangeRequired as boolean,
              changeAlarmSettings: values.changeAlarmSettings as boolean,
              changeCommunicationSettings: values.changeCommunicationSettings as boolean,
              decreaseSamplingFrequency: values.decreaseSamplingFrequency as boolean,
              furtherInvestigationRequired: values.furtherInvestigationRequired as boolean,
              increaseInFrequencyOfDataDownloads:
                values.increaseInFrequencyOfDataDownload as boolean,
              increaseSamplingFrequency: values.increaseSamplingFrequency as boolean,
              optimiseTheVLifeProcess: values.optimiseVLifeProcess as boolean,
              updateFirmwareToId: values.recommendedFirmwareRevision as string,
              externalSummaryForClient: values.externalSummaryForClient as string,
              sendForApproval: showConfirmReview === 'approve',
            },
          },
        });
        if (!result.errors) {
          navigate(formDefaults.review.path);
        }
      }
    } finally {
      setSaving(false);
      setShowConfirmReview(undefined);
    }
  }, [navigate, reportFormId, reviewReportForm, showConfirmReview, values]);

  // delete

  const deleteForm = useCallback(() => {
    deleteError.reset();
    setShowDelete(true);
  }, [deleteError]);

  const handleConfirmDelete = useCallback(async () => {
    setShowDelete(false);
    if (reportFormId) {
      setSaving(true);
      const result = await deleteReportForm({ variables: { id: reportFormId } });
      if (result.data?.deleteVLifeReportForm === reportFormId) {
        navigate(formDefaults.review.path);
      } else {
        setSaving(false);
      }
    }
  }, [deleteReportForm, reportFormId, navigate]);

  return {
    reportForm,
    schema,
    loading,
    deleteForm,
    showDelete,
    setShowDelete,
    handleConfirmDelete,
    deleteError,

    onCancel,
    onSendForApproval,
    onSendForReview,
    setValues,
    saving,
    setShowConfirmReview,
    showConfirmReview,
    handleConfirmReview,
    reviewReportFormError,
    channelId: reportForm?.vlifeReportForm?.channel.id,
    startDate: reportForm?.vlifeReportForm?.reportPeriodStartDate,
    endDate: reportForm?.vlifeReportForm?.reportPeriodEndDate,
  };
}
