import { FunctionsMap } from 'apollo-link-scalars';

export const typesMap: FunctionsMap = {
  Date: {
    serialize: (parsed) => parsed,
    parseValue: (raw) => raw,
  },
  DateTime: {
    serialize: (parsed) => parsed,
    parseValue: (raw) => raw,
  },
  Long: {
    serialize: (parsed) => parsed,
    parseValue: (raw) => raw,
  },
};
