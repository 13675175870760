import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, styled } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { mainNavigation } from '~/routes/mainNavigation';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const Icon = styled(ListItemIcon)`
  color: #fff;
`;

const noUnderline = { textDecoration: 'none' };

const activeStyle = {
  backgroundColor: '#FFDB5B',
  borderRadius: '5px',
  ...noUnderline,
};

export const MainMenu: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const items = mainNavigation;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <React.Fragment>
      {items.map(({ key, path, icon, children }) => {
        if (!children && path !== null && key !== null) {
          return renderNavLinks(t, key, icon, path);
        } else if (key !== null) {
          const expandedByDefault = children?.some((child) =>
            location.pathname.includes(child?.path || '')
          );
          return (
            <Accordion
              expanded={expanded === key || expandedByDefault}
              key={key}
              sx={{ backgroundColor: 'transparent', boxShadow: 0 }}
              disableGutters
              onChange={
                !expandedByDefault
                  ? handleChange(key)
                  : () => {
                      /*noOp*/
                    }
              }
            >
              <AccordionSummary
                data-testid='accordion-summary'
                expandIcon={<ExpandMore sx={{ color: '#fff' }} />}
                aria-controls='navbar-assets-content'
                id='navbar-assets-header'
                sx={[
                  {
                    display: 'flex',
                    alignItems: 'center',
                    cursor: expandedByDefault ? 'default!important' : 'pointer',
                  },
                  {
                    '&:focus-visible': {
                      outline: '1px white solid ',
                      outlineOffset: 1,
                      border: '1px solid rgb(0,95,204)',
                      borderRadius: 1,
                    },
                  },
                ]}
              >
                <Icon sx={{ color: '#fff', display: 'flex', alignItems: 'center' }}>
                  {icon}
                </Icon>
                <ListItemText
                  primary={t(key)}
                  primaryTypographyProps={{
                    color: '#fff',
                    fontWeight: '500',
                  }}
                />
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, m: 0 }}>
                {children
                  ?.filter(({ key }) => key !== null)
                  .map(({ key, path, icon }) => renderNavLinks(t, key, icon, path))}
              </AccordionDetails>
            </Accordion>
          );
        }
      })}
    </React.Fragment>
  );
};

function renderNavLinks(
  t: TFunction,
  key: string | null,
  icon: JSX.Element | null,
  path?: string
): JSX.Element | undefined {
  return path ? (
    <NavLink
      key={path}
      to={path}
      style={({ isActive }) => (isActive ? activeStyle : noUnderline)}
      role='navigation'
      data-testid='NavLink'
    >
      {({ isActive }) => (
        <ListItemButton
          data-testid={`list-item-button-${key}`}
          sx={[
            isActive && {
              '&:hover': activeStyle,
            },
            isActive && activeStyle,
          ]}
          aria-current={isActive}
        >
          <Icon sx={{ color: isActive ? '#002244' : '#fff' }}>{icon}</Icon>
          <ListItemText
            primary={t(key ?? '')}
            primaryTypographyProps={{
              color: isActive ? '#002244' : '#fff',
              fontWeight: '500',
            }}
            data-testid='ListitemText'
          />
        </ListItemButton>
      )}
    </NavLink>
  ) : undefined;
}
