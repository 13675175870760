import React from 'react';
import { FormLabelProps, FormLabel as MuiFormLabel } from '@mui/material';
import { WarningWithTooltip } from '~/components/WarningWithTooltip';
import { useTranslation } from 'react-i18next';

export const FormLabel: React.FC<FormLabelProps & { isRequiredForReporting?: boolean }> = ({
  isRequiredForReporting,
  sx,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <span>
      <MuiFormLabel sx={{ ...sx }} {...rest} />
      {isRequiredForReporting && <WarningWithTooltip title={t('mandatory-for-report')} />}
    </span>
  );
};
