import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A scalar that represents a date with no time component.
   *
   * It is serialised as yyyy-mm-dd
   */
  Date: string;
  /**
   * A scalar that represents a date with a time component.
   *
   * It is serialised as yyyy-mm-ddThh:mm:ssZ
   */
  DateTime: string;
  /** A scalar which represents java.lang.Long */
  Long: number;
};

export type Asset = {
  ipAddress: Scalars['String'];
  serialNumber: Scalars['String'];
};

export type JobResult = {
  __typename?: 'JobResult';
  started: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  importFile: JobResult;
};


export type MutationImportFileArgs = {
  viperAsset: Asset;
};

export type PollingDates = {
  __typename?: 'PollingDates';
  lastPollingDate?: Maybe<Scalars['DateTime']>;
  nextPollingDate?: Maybe<Scalars['DateTime']>;
};

export type Query = {
  __typename?: 'Query';
  getPollingDates: PollingDates;
};


export type QueryGetPollingDatesArgs = {
  viperAsset: Asset;
};

export type GetPollingDatesQueryVariables = Exact<{
  viperAsset: Asset;
}>;


export type GetPollingDatesQuery = { __typename?: 'Query', getPollingDates: { __typename?: 'PollingDates', lastPollingDate?: string | null, nextPollingDate?: string | null } };


export const GetPollingDatesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPollingDates"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"viperAsset"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Asset"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPollingDates"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"viperAsset"},"value":{"kind":"Variable","name":{"kind":"Name","value":"viperAsset"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lastPollingDate"}},{"kind":"Field","name":{"kind":"Name","value":"nextPollingDate"}}]}}]}}]} as unknown as DocumentNode<GetPollingDatesQuery, GetPollingDatesQueryVariables>;