import React from 'react';
import { CustomerAssetMaintenanceLog } from './CustomerAssetMaintenanceLog';
import { Box } from '@mui/material';
import { AuditLog } from '../AuditLog/AuditLog';

export const CustomerAssetLogs: React.FC<{ channelId: string }> = ({ channelId }) => {
  return (
    <>
      <CustomerAssetMaintenanceLog id={channelId} />
      <Box sx={{ mb: 4 }} />
      <AuditLog entity='customerasset.Channel' id={channelId} />
    </>
  );
};
