export const Path = {
  HOME: '/',
  VIPER_ASSETS: '/viper-assets',
  CUSTOMER_ASSETS: '/customer-assets',
  CREATE_CUSTOMER_ASSET: '/customer-assets/create',
  CREATE_VIPER_ASSET: '/viper-assets/create',
  CUSTOMER_ASSET: '/customer-assets/:id',
  COMMON_CUSTOMER_ASSET: '/customer-assets/:id/:tab',
  CREATE_LICENCE: '/customer-assets/:id/create-licence',
  COMPANIES: '/companies',
  CREATE_COMPANY: '/companies/create',
  CONTACTS: '/contacts',
  CREATE_CONTACT: '/contacts/create',
  VIPER_ASSET: '/viper-assets/:id',
  V_LOGS: '/v-logs',
  V_LIFE_REPORTS: '/v-life-reports',
  COMMON_V_LIFE_REPORTS: '/v-life-reports/:formStatus',
  CREATE_V_LIFE_REPORT: '/v-life-reports/create/:channelId',
  REVIEW_V_LIFE_REPORT: '/v-life-reports/review/:reportFormId',
  APPROVE_V_LIFE_REPORT: '/v-life-reports/approve/:reportFormId',
  PUBLISH_V_LIFE_REPORT: '/v-life-reports/publish/:reportFormId',
  LICENCE_INSIGHTS: '/licence-insights',
  COOKIE_POLICY: '/cookie-policy',
  USER_PROFILE: '/user-profile',
} as const;

type PathKey = keyof typeof Path;
export type PathValue = typeof Path[PathKey];

/**
 * Builds a path by replacing and parameter placeholders with the
 * parameters passed to this function.
 *
 * e.g.
 * ```
 * buildPath('/project/:projectId/:subProject/details', {
 *   projectId: '123',
 *   subProject: 'stuff'
 * })
 * ```
 * would produce `/project/123/stuff/details`
 */
export function buildPath(
  path: PathValue,
  params?: Record<string, string | undefined>
): string {
  if (!params) {
    return path;
  }
  return Object.keys(params).reduce((prev, current) => {
    return prev.replaceAll(
      new RegExp(`(^|\\/):${current}(/|$)`, 'g'),
      `$1${params[current]}$2`
    );
  }, path);
}
