import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Card,
  List,
  ListItemText,
  ListItemIcon,
  Typography,
  ListItemButton,
  Box,
  IconButton,
  Theme,
  useTheme,
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonTwoTone from '@mui/icons-material/PersonTwoTone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Collapse from '@mui/material/Collapse';

import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

export interface UserMenuProps {
  user: string | undefined;
  role: string;
}

const UserMenu = ({ user, role }: UserMenuProps) => {
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const [helpMenuOpen, setHelpMenuOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { t } = useTranslation('user-menu');
  const theme = useTheme();

  const helpMenuItems = [
    {
      key: 'report',
      label: t('links/report'),
      url: t('urls/report'),
    },
    {
      key: 'faqs',
      label: t('links/faqs'),
      url: t('urls/faqs'),
    },
    {
      key: 'videos',
      label: t('links/videos'),
      url: t('urls/videos'),
    },
  ];

  useEffect(() => {
    // Ensure menu is toggled when user clicks outside of menu
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setToggleMenu((prev) => !prev);
      }
    }
    if (toggleMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      //clean up of event
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleMenu]);

  const handleHelpClick = () => {
    setHelpMenuOpen(!helpMenuOpen);
  };

  const goToCookiePolicy = () => {
    navigate('/cookie-policy');
    setToggleMenu(!toggleMenu);
  };

  const goToUserProfile = () => {
    navigate('/user-profile');
    setToggleMenu(!toggleMenu);
  };

  const handleLogout = () => {
    instance.logout();
  };

  return (
    <div ref={menuRef} data-testid='user-menu'>
      <IconButton
        aria-label='User settings'
        onClick={() => {
          setToggleMenu(!toggleMenu);
        }}
      >
        <PersonTwoTone color='primary' />
      </IconButton>
      <Card
        sx={{
          width: '300px',
          position: 'absolute',
          top: '60px',
          right: '25px',
          backgroundColor: 'white',
          boxShadow: 1,
          ...(toggleMenu
            ? {
                display: 'block',
              }
            : {
                display: 'none',
              }),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            borderBottom: `1px solid ${theme.palette.divider}`,
            p: '10px',
          }}
        >
          <Avatar sx={{ color: (theme: Theme) => theme.palette.text.primary, m: 1 }} />
          <Box sx={{ m: '10px' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: 'inherit' }}>
              {user}
            </Typography>
            <Typography sx={{ fontSize: '16px', color: 'inherit' }}>{role} </Typography>
          </Box>
        </Box>
        <List
          sx={{
            padding: 0,
            marginBottom: '5px',
          }}
        >
          <ListItemButton id='user-profile' onClick={goToUserProfile}>
            <ListItemIcon sx={{ minWidth: '30px' }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={t('links/userProfile')} />
          </ListItemButton>
          <ListItemButton
            sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
            id='cookie-policy'
            onClick={goToCookiePolicy}
          >
            <ListItemIcon sx={{ minWidth: '30px' }}>
              <CookieOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('links/cookies')} />
          </ListItemButton>
          <ListItemButton
            sx={{ borderBottom: !helpMenuOpen ? `1px solid ${theme.palette.divider}` : '' }}
            id='help-centre'
            onClick={handleHelpClick}
          >
            <ListItemIcon sx={{ minWidth: '30px' }}>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={t('links/help')} />
            <ExpandMoreIcon
              sx={{
                color: theme.palette.grey['700'],
                transform: helpMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.1s',
              }}
            />
          </ListItemButton>
          <Collapse
            sx={{ borderBottom: helpMenuOpen ? `1px solid ${theme.palette.divider}` : '' }}
            in={helpMenuOpen}
            timeout={0}
            unmountOnExit
          >
            <List component='div' disablePadding>
              {helpMenuItems.map(({ key, label, url }) => (
                <ListItemButton
                  key={key}
                  component='a'
                  href={url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <ListItemText
                    sx={{ pl: 4 }}
                    primary={
                      <>
                        {label}
                        <OpenInNewIcon
                          sx={{
                            fontSize: 'large',
                            verticalAlign: 'middle',
                            ml: '5px',
                            color: theme.palette.grey['700'],
                          }}
                        />
                      </>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon sx={{ minWidth: '30px' }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t('button/logout')} />
          </ListItemButton>
        </List>
      </Card>
    </div>
  );
};

export default UserMenu;
