import React from 'react';
import { Typography, Stack, Divider, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export interface Highlight {
  title: string;
  description: string;
  to?: string;
  icon?:
    | 'disabled'
    | 'action'
    | 'inherit'
    | 'secondary'
    | 'primary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
}

export interface Highlights {
  highlights: Highlight[];
  onClickCta?: () => void;
  ctaCopy?: string;
  buttonDisabled?: boolean;
}

const HighlightItem: React.FC<Highlight> = ({ title, description, to, icon }) => {
  const navigate = useNavigate();
  return to ? (
    <Box
      role='link'
      onClick={() => {
        navigate(to);
      }}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      <Stack direction='row' spacing={2} alignItems='center'>
        <div>
          <Typography variant='body2'>{title}</Typography>
          <Typography sx={{ lineHeight: 1.1, mt: 0.5 }} variant='h6'>
            {description}
          </Typography>
        </div>
        <ArrowForwardIcon />
      </Stack>
    </Box>
  ) : (
    <Box>
      <Typography variant='body2'>{title}</Typography>
      <Stack direction='row' spacing={0.5} alignItems='flex-end'>
        <Typography sx={{ lineHeight: 1.1, mt: 0.5 }} variant='h6'>
          {description}
        </Typography>
        {icon && (
          <FiberManualRecordIcon color={icon} fontSize='small' data-testid='indicator' />
        )}
      </Stack>
    </Box>
  );
};

export const Highlights: React.FC<Highlights> = ({
  highlights,
  onClickCta,
  ctaCopy,
  buttonDisabled,
}) => {
  return (
    <Stack
      direction={{ md: 'column', lg: 'row' }}
      spacing={{ md: 3, lg: 4 }}
      justifyContent='space-between'
      data-testid='highlights'
    >
      <Stack
        direction={{ md: 'column', lg: 'row' }}
        divider={<Divider orientation={'vertical'} flexItem />}
        spacing={{ md: 1, lg: 4 }}
      >
        {highlights.map((highlight) => (
          <HighlightItem key={highlight.title} {...highlight} />
        ))}
      </Stack>
      <Button
        variant='contained'
        color='secondary'
        onClick={onClickCta}
        size='medium'
        disabled={buttonDisabled}
        sx={{ justifySelf: 'flex-end' }}
      >
        {ctaCopy}
      </Button>
    </Stack>
  );
};
